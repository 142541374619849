import React, { Component } from "react";
// import { Formik, Field, Form } from "formik";
// import ReactTable from "react-table";
// import "react-table/react-table.css";

// import * as Yup from "yup";
import { LoginService } from "../../services/login.service";
import { StudentAssessmentService } from "../../services/studentAssessment.service";
import moment from "moment";
import { OrderService } from "../../services/order.service";
import { error, errorNotification } from "../notification/notifications";

class MyPrequisite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkerlist: [],
            isMakePaymentAdded: false,
            checkout: [],
            selectedProdDet: []
        }
    }



    componentDidMount() {
        LoginService.TokenUser(res => {
            if (res.status === 'success') {
                localStorage.setItem("jwt-token", res.responseObject);
                this.state = {
                    selectedProdDet: this.props.history.location.state.subscribedProduct.partnerID !== undefined ? this.props.history.location.state.subscribedProduct : this.props.history.location.state.subscribedProduct.productEntity
                } // setState({...})

                let studentParty = "studentPartyID=" + this.props.history.location.state.personnelProfileData.studentPartyID;
                let selectedProd = "&productID=" + this.state.selectedProdDet.productID;
                let queryprameters = studentParty + selectedProd;
                StudentAssessmentService.fetchStudentPrequisiteByProductID(queryprameters, res1 => {
                    if (res1.status === 'success') {
                        let prerequisiteList = res1.responseListObject;
                        //console.log("prerequisiteList", prerequisiteList);
                        this.setState({ checkerlist: prerequisiteList });
                        //console.log("prerequisiteListState", this.state.checkerlist);
                    } else {
                        this.setState({ loading: false });
                    }

                }, err => {
                    error(err, errorNotification);
                    this.setState({ loading: false });
                }

                )
            }
        });

    }


    productSubscribe = (ele) => {

        let checkoutd = '';

        var personaldetails = {
            partyId: this.props.history.location.state.personnelProfileData.studentPartyID,
            mobileNo: this.props.history.location.state.personnelProfileData.mobileNo,
            firstName: this.props.history.location.state.personnelProfileData.firstName,
            lastName: this.props.history.location.state.personnelProfileData.lastName,
            emailID: this.props.history.location.state.personnelProfileData.emailID,
            entryMode: 1,
            dateOfBirth: this.props.history.location.state.personnelProfileData.dob,
        }
        var productdetails = {
            productd: ele.productEntity.productID,
            productName: ele.productEntity.productName,
            productPrice: ele.productEntity.productPrice,
            partnerID: ele.productEntity.partnerID,
        }

        this.state.checkout.push({ personaldetails, productdetails });


        let orderItemListArray = [];
        let orderItemList = {
            itemID: 1,
            productID: ele.productEntity.productID,
            couponApplied: 'ZERO',
            prodctPrice: ele.productEntity.productPrice
        }
        orderItemListArray.push(orderItemList);
        checkoutd = {
            studentPartyID: localStorage.getItem('userid-Token'),
            orderPlacedOn: moment(new Date()).format("DD/MM/YYYY"),//'15/11/2018 15:18:33',
            totalItemPrice: ele.productEntity.productPrice,
            totalOrderPrice: ele.productEntity.productPrice,
            paymentStatus: "Initiated",
            orderItemList: orderItemListArray
        }
        LoginService.TokenUser(res => {
            if (res.status === 'success') {
                localStorage.setItem('jwt-token', res.responseObject);
                OrderService.InsertOrder(checkoutd, (res1) => {
                    if (res1.status === 'success') {
                        localStorage.setItem('reload', 'true');
                        this.setState({ loading: false });
                        this.props.history.push('/checkout', { props: this.state.checkout, orderDetails: res1.responseObject });
                    } else {
                        error(res1.responseText, errorNotification);
                    }

                }, (error) => {
                    //console.log("Order Service ", error);
                });
            }
        });

    };

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="checkout">
                                <div className="modal-header"></div>
                                <div className="modal-body">
                                    <div className="bg-box">
                                        <div className="payment-detail">
                                            <h2 className="sub-header">Product Pre Requisite </h2>
                                            <div className="table-responsive">
                                                <table className="paydetail-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th>Product Name</th>
                                                            <th>Completed</th>
                                                            <th>status</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.checkerlist !== '' ?
                                                            this.state.checkerlist.map(element => (
                                                                <tr>
                                                                    <td> {element.sequenceCount}</td>
                                                                    <td> {element.productEntity.productName}</td>
                                                                    <td> {element.isScoreUpdated === null ? 'In-Complete' : 'Completed'}  </td>
                                                                    <td> {element.status === 'Record found' ? element.isScoreUpdated !== null ? "Score Available" : "Score Awaited"
                                                                        :
                                                                        <button className="btn-7 pull-left" onClick={() => this.productSubscribe(element)} >
                                                                            <a href="#" data-keyboard="true" >
                                                                                Make Payment</a></button>
                                                                    }</td>
                                                                    <td>&nbsp; </td>

                                                                </tr>)) : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div
                    className="modal fade"
                    id="new-subscription"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="modalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-md" role="document">
                        <div className="modal-content">
                            <div className="checkout">
                                <div className="modal-header"></div>
                                <div className="modal-body">
                                    <div className="bg-box">
                                        <div className="payment-detail">
                                            <h2 className="sub-header">Product Pre Requisite </h2>
                                            <div className="table-responsive">
                                                <table className="paydetail-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.No</th>
                                                            <th>Product Name</th>
                                                            <th>Completed</th>
                                                            <th>status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.checkerlist != '' ?
                                                            this.state.checkerlist.map(element => (
                                                                <tr>
                                                                    <td> {element.sequenceCount}</td>
                                                                    <td> {element.productEntity.productName}</td>
                                                                    <td> {element.isScoreUpdated === null ? 'In-Complete' : 'Completed'}  </td>
                                                                    <td> {element.status === 'Record found' ? element.isScoreUpdated !== null ? "Score Available" : "Score Awaited"
                                                                        :
                                                                        <button className="btn-7"  >
                                                                            <a href="" data-keyboard="true" >
                                                                                Make Payment</a></button>
                                                                    }</td>

                                                                </tr>)) : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>

        );
    }
}

export default MyPrequisite;
