// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import "react-table/react-table.css";


class Ops extends Component {
  constructor(props) {
    super(props);

   
    this.state = {
   
    };
  }
  componentDidMount() {

  }

  render() {
    return (
      <React.Fragment>
        <section
          className="dashboard-tab admin-dashboard mb-50"
          id="admin-dashboard"
        >
          <div className="container">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-50 mb-10 pl pr">
              <div className="row">
              
                <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                
                  </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10 mb-50 pl pr">
              <div className="row">
               
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Ops;
