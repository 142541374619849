import React, { Component } from "react";
import ReactExport from "react-data-export";
import XLSX from "xlsx";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import AdminHeader from "../../components/dashboardheader/adminheader";
import ReactTable from "react-table";
// import { RegisterUserService } from "../../services/registration.service";
import { ProductService } from "../../services/product.service";
import { LoginService } from "../../services/login.service";
import { success, successNotification } from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const dataSet1 = [
//   {
//     srNo: "1",
//     firstName: "Amol",
//     middleName: "A",
//     lastName: "Mehtre",
//     emailID: "nowhere@gmail.com",
//     mobileNo: "9211420540",
//     gender: "M",
//     panNumber: "ABCPU1234P",
//     aadharNumber: "111111111111",
//     dateofbirth: "'14/01/2010"
//   },
//   {
//     srNo: "2",
//     firstName: "Pankaja",
//     middleName: "B",
//     lastName: "Patil",
//     emailID: "everywhere@gmail.com",
//     mobileNo: "9211420540",
//     gender: "F",
//     panNumber: "ABCPU1234P",
//     aadharNumber: "222222222222",
//     dateofbirth: "'26/12/2010"
//   }
// ];
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;

  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class AdminBulkUploadgdpi extends Component {
  constructor(props) {
    super(props);

    //console.log("props", this.props)

    // //console.log("bulk",this.props.alertNotificationEvents)
    ////console.log("bul",this.props.location.state.alerts)
    // if (localStorage.getItem('login') !== null) {
    this.state = {
      file: {},
      data: [],
      cols: [],
      isFileUploaded: false,
      checkFileSize: false,
      totalRecords: "",
      successfullyUploaded: "",
      faildRecords: "",
      invalidData: [],
      checkDocumentType: false,
      hideUI: false,
      showUploadButton: false,
      templateData: []
    };
    console.log("DATA : ", this.state);
    // }
    // else {
    //   window.location.href = '/';
    // }
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = () => {
    this.props.history.goBack("-1");
    //window.$("#gdpi-score").modal('hide');
    // window.location.reload();
  };

  componentDidMount() {
    var statusparameter = "gdpiStatus=" + 10303;
    ProductService.filterstudents(statusparameter, cb => {
      var approvedGDPIData = [];
      if (cb.status === "success") {
        cb.responseListObject.forEach(element => {
          var data1 = {
            GDPI_ID: element[0].gDPIID,
            studentPartyID: element[0].studentPartyID,
            First_Name:
              element[1] === null
                ? ""
                : element[1].firstName !== undefined
                  ? element[1].firstName
                  : "",
            Email_ID:
              element[1] === null
                ? ""
                : element[1].contactEmail !== undefined
                  ? element[1].contactEmail
                  : "",
            GD_Status:
              element[0].gDStatus === 10306
                ? "Completed"
                : element[0].gDStatus === 10303
                  ? "Approved"
                  : "Requested",
            PI_Status:
              element[0].pIStatus === 10306
                ? "Completed"
                : element[0].pIStatus === 10303
                  ? "Approved"
                  : "Requested",
            GD_PI_Status:
              element[0].gDPIStatus === 10306
                ? "Completed"
                : element[0].gDPIStatus === 10303
                  ? "Approved"
                  : "Requested",
            Is_GDPI_Completed: element[0].isGDPICompleted === 1 ? "YES" : "NO",
            GD_Score: element[0].gDScore,
            PI_Score: element[0].pIScore,
            videoResumeScore: element[0].videoResumeScore,
            GD_PI_Score: element[0].gDPIScore
          };
          approvedGDPIData.push(data1);
          this.setState({ templateData: approvedGDPIData });
        });
      }
    });
  }
  handleChange(e) {
    const files = e.target.files;
    if (files.length > 0) {
      const checkType = files[0].name.split(".");
      if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
        this.setState({
          checkDocumentType: true,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false
        });
      } else if (files && files[0]) {
        this.setState({
          checkDocumentType: false,
          file: files[0],
          isFileUploaded: true,
          showUploadButton: true
        });
      }
    }
    e.target.value = null;
  }
  handleFile() {
    //
    /* Boilerplate to set up FileReader */
    const reader = new FileReader(this.state.file);
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      //
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      //console.log("data xlsx :" + JSON.stringify(data))
      /* Update state */
      this.setState({ loading: true });
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        LoginService.TokenUser(res => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);

            for (var i = 0; i < data.length; i++) {
              data[i].gDPIID = data[i]["GDPI_ID"];
              delete data[i].GDPI_ID;

              data[i].firstName = data[i]["First_Name"];
              delete data[i].First_Name;

              data[i].contactEmail = data[i]["Email_ID"];
              delete data[i].Email_ID;

              data[i].gDStatus = data[i]["GD_Status"];
              delete data[i].GD_Status;
              // if (data[i].gDStatus === "Completed") {
              //   data[i].gDStatus = 10306;
              // }
              // if (data[i].gDStatus === "Approved") {
              //   data[i].gDStatus = 10302;
              // }
              // if (data[i].gDStatus === "Requested") {
              //   data[i].gDStatus = 10303;
              // }
              data[i].gDStatus =
                data[i].gDStatus.toUpperCase() === "COMPLETED"
                  ? (data[i].gDStatus = 10306)
                  : data[i].gDStatus.toUpperCase() === "APPROVED"
                    ? (data[i].gDStatus = 10303)
                    : data[i].gDStatus.toUpperCase() === "REQUESTED"
                      ? (data[i].gDStatus = 10302)
                      : 1;

              data[i].pIStatus = data[i]["PI_Status"];
              delete data[i].PI_Status;
              // if (data[i].pIStatus === "Completed") {
              //   data[i].pIStatus = 10306;
              // }
              // if (data[i].pIStatus === "Approved") {
              //   data[i].pIStatus = 10302;
              // }
              // if (data[i].pIStatus === "Requested") {
              //   data[i].pIStatus = 10303;
              // }
              data[i].pIStatus =
                data[i].pIStatus.toUpperCase() === "COMPLETED"
                  ? (data[i].pIStatus = 10306)
                  : data[i].pIStatus.toUpperCase() === "APPROVED"
                    ? (data[i].pIStatus = 10303)
                    : data[i].pIStatus.toUpperCase() === "REQUESTED"
                      ? (data[i].pIStatus = 10302)
                      : 1;

              data[i].gDPIStatus = data[i]["GD_PI_Status"];
              delete data[i].GD_PI_Status;
              // if (data[i].gDPIStatus === "Completed") {
              //   data[i].gDPIStatus = 10306;
              // }
              // if (data[i].gDPIStatus === "Approved") {
              //   data[i].gDPIStatus = 10302;
              // }
              // if (data[i].gDPIStatus === "Requested") {
              //   data[i].gDPIStatus = 10303;
              // }

              data[i].gDPIStatus =
                data[i].gDPIStatus.toUpperCase() === "COMPLETED"
                  ? (data[i].gDPIStatus = 10306)
                  : data[i].gDPIStatus.toUpperCase() === "APPROVED"
                    ? (data[i].gDPIStatus = 10303)
                    : data[i].gDPIStatus.toUpperCase() === "REQUESTED"
                      ? (data[i].gDPIStatus = 10302)
                      : 1;

              data[i].isGDPICompleted = data[i]["Is_GDPI_Completed"];
              delete data[i].Is_GDPI_Completed;
              if (
                data[i].isGDPICompleted === "YES" ||
                data[i].isGDPICompleted === "Yes" ||
                data[i].isGDPICompleted === "yes"
              ) {
                data[i].isGDPICompleted = 1;
              }
              else {
                data[i].isGDPICompleted = null;
              }

              data[i].gDScore = data[i]["GD_Score"];
              delete data[i].GD_Score;

              data[i].pIScore = data[i]["PI_Score"];
              delete data[i].PI_Score;

              data[i].videoResumeScore = data[i]["videoResumeScore"];
              //delete data[i].videoResumeScore;

              data[i].gDPIScore = data[i]["GD_PI_Score"];
              delete data[i].GD_PI_Score;
            }
            console.log("Data modified :" + JSON.stringify(data));

            ProductService.updategdpiStatus(data, res1 => {
              //console.log("Upload Response :" + JSON.stringify(res1))
              success("Uploaded students successfully", successNotification);
              this.setState({ loading: false });
              this.setState({
                hideUI: true,
                file: {},
                data: [],
                cols: [],
                faildRecords: res1.responseObject.invalidRecords || "",
                successfullyUploaded: res1.responseObject.successfullRecords,
                invalidData: res1.responseObject.invalidData || []
              });
              console.log("Service data : ", res1.responseObject.invalidData);
            });
          }
        });
      });
      this.setState({ totalRecords: this.state.data.length });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  render() {
    // var alertdata = JSON.parse(localStorage.getItem("alertd"));
    // var notealertdata = JSON.parse(localStorage.getItem("notealert"));
    // var alertdat = JSON.parse(localStorage.getItem("alertheader"));
    console.log("this.state.templateData", this.state.templateData);
    return (
      <React.Fragment>
        <AdminHeader
          //alertNotificationEvents={this.props.location.state.alerts}
          //notificationEvents={this.props.location.state.notification}
          {...this.props}
        />
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section className="dashboard-tab bulk-summary pt-50">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <section className="create-user">
                  <div className="bg-box bulk-box">
                    <div className="row">
                      <div className="col-md-5 col-sm-5 col-xs-12">
                        <div className="scr-board scr_bulk">
                          <ExcelFile
                            element={
                              <h4 className="bulk_head">
                                Download records whose GDPI status is approved{" "}
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </h4>
                            }
                            filename="Download Template"
                          >
                            <ExcelSheet
                              data={this.state.templateData}
                              name="Employees"
                            >
                              <ExcelColumn label="GDPI_ID" value="GDPI_ID" />
                              <ExcelColumn
                                label=" Student_ID"
                                value="studentPartyID"
                              />
                              <ExcelColumn
                                label="First_Name"
                                value="First_Name"
                              />
                              <ExcelColumn label="Email_ID" value="Email_ID" />
                              <ExcelColumn
                                label="GD_Status"
                                value="GD_Status"
                              />
                              <ExcelColumn
                                label="PI_Status"
                                value="PI_Status"
                              />
                              <ExcelColumn
                                label="GD_PI_Status"
                                value="GD_PI_Status"
                              />
                              <ExcelColumn
                                label="Is_GDPI_Completed"
                                value="Is_GDPI_Completed"
                              />
                              <ExcelColumn label="GD_Score" value="GD_Score" />
                              <ExcelColumn label="PI_Score" value="PI_Score" />
                              <ExcelColumn
                                label="videoResumeScore"
                                value="videoResumeScore"
                              />
                              <ExcelColumn
                                label="GD_PI_Score"
                                value="GD_PI_Score"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                          <div className="lock-img">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                          </div>
                          <div className="bottom-line"></div>
                          <h2 className="color_magento">BULK UPLOAD</h2>
                          <div className="scr-form">
                            {this.state.isFileUploaded ? (
                              <div>
                                <p className="text-center color_gray text-wrap-bulk-upload">
                                  File Selected: {this.state.file.name}
                                  <br />
                                  {/* Please Upload. */}
                                </p>
                                <span className="icon btn-file btn-file3">
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div>
                                <span className="icon btn-file btn-file1">
                                  <p className="color_red">
                                    <button className="browse-text">
                                      Select File To Upload
                                    </button>

                                    <input
                                      type="file"
                                      name="file"
                                      accept={SheetJSFT}
                                      onChange={this.handleChange}
                                    />
                                  </p>
                                </span>
                                <span className="icon btn-file btn-file2">
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                              </div>
                            )}

                            {this.state.checkDocumentType ? (
                              <p className="text-center validation1">
                                Invalid File Format
                              </p>
                            ) : (
                              ""
                            )}
                            {this.state.checkFileSize ? (
                              <p className="text-center color_gray">
                                File Size is too large(Maximum File Size:5MB).
                              </p>
                            ) : (
                              ""
                            )}
                            {/* <span className="icon btn-file">
                              <i
                                className="fa fa-paperclip"
                                aria-hidden="true"
                              ></i>
                              <input
                                type="file"
                                name="file"
                                accept={SheetJSFT}
                                onChange={this.handleChange}
                              />
                            </span> */}
                            <div className="">
                              <span className="file-size-limit-bulkupload">
                                Supported Formats: .xls, .xlsx only. <br />
                              </span>
                            </div>
                          </div>
                          {this.state.showUploadButton ? (
                            <button
                              type="submit"
                              onClick={this.handleFile}
                              className="btn-1"
                              disabled={!this.state.isFileUploaded}
                            >
                              Upload
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-7 col-xs-12">
                        <div className="table-header">
                          <h3>
                            <div className="row">
                              <div className="col-md-6 col-sm-6 col-xs-9">
                                <span className="left-icon">
                                  <i className="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                                Bulk Upload Summary
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-3">
                                <span className="right-icon">
                                  <a title="" href="#">
                                    <i
                                      className="fa fa-info"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </h3>
                          {/* <h3 className="text-center">
                            <span className="left-icon">
                              <i
                                className="fa fa-list"
                                aria-hidden="true"
                              ></i>
                            </span>
                            Bulk Upload Summary
                              <span className="right-icon">
                              <a href="">
                                <i
                                  className="fa fa-info"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h3> */}
                        </div>
                        <div className="mr-20 ml-20">
                          <div className="table-responsive">
                            <table className="user-table bulkupload-tab">
                              <tr>
                                <td>
                                  <p>Total Records</p>
                                </td>
                                <td>
                                  {" "}
                                  <span className="records">
                                    {this.state.totalRecords || 0}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Successfully Uploaded </p>
                                </td>
                                <td>
                                  <span className="success">
                                    {this.state.successfullyUploaded || 0}
                                  </span>{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Fail to Upload </p>
                                </td>
                                <td>
                                  <span className="fail">
                                    {this.state.faildRecords || 0}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <button
                            className="btn-5 mr-20 ml-20 mt-10 pull-right"
                            onClick={this.handleCancel}
                          >
                            <span aria-hidden="true">Back</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {this.state.hideUI ? (
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="table-header">
                    <h3>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <span className="left-icon">
                            <i
                              className="fa fa-question-circle-o fa-lg"
                              aria-hidden="true"
                            ></i>
                          </span>{" "}
                          Failed Records Details
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div className="bg-box">
                    <div className="table-responsive">
                      <ReactTable
                        data={this.state.invalidData}
                        className="-striped -highlight"
                        filterable
                        defaultFilterMethod={this.filterCaseInsensitive}
                        columns={[
                          {
                            columns: [
                              {
                                Header: "GDPIID",
                                id: "gDPIID",
                                accessor: d => d.gDPIID
                              },
                              {
                                Header: "Failure Reason",
                                id: "studentPartyID",
                                accessor: d => d.errorCode
                              },
                              {
                                Header: "GD Score",
                                id: "gDScore",
                                accessor: d => d.gDScore
                              },
                              {
                                Header: "PI Score",
                                id: "gender",
                                accessor: d => d.pIScore
                              },
                              {
                                Header: "Video Resume Score",
                                id: "videoResumeScore",
                                accessor: d => d.videoResumeScore
                              },
                              {
                                Header: "GDPI score",
                                id: "gDPIScore",
                                accessor: d => d.gDPIScore
                              }
                              /*   {
                                   Header: "Aadhar Number",
                                   id: "aadharNumber",
                                   accessor: d => d.aadharNumber + " " + d.aadharNumber
                                 }, {
                                   Header: "Failure Reason",
                                   id: "reason",
                                   accessor: d => d.errorCode,
                                   style: { 'white-space': 'unset' }
                                 } */
                            ]
                          }
                        ]}
                        defaultPageSize={5}
                        style={{
                          width: "100%",
                          height: "500px"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <Finxfooter />
      </React.Fragment>
    );
  }
}

export default AdminBulkUploadgdpi;
