import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import OpsHeader from "../dashboardheader/opsheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import { LoginService } from "../../services/login.service";
import funnelChart from "../../assets/images/FINX-funnel.png";
import { StudentAssessmentService } from "../../services/studentAssessment.service";

class jobDetails extends Component {
  constructor(props) {
    super(props);
    console.log("props::", props);
    if (localStorage.getItem("login") !== null) {
      this.state = {
        questionnaireData: [],
        data:[],
        jobId: this.props.location.state !== undefined ? this.props.location.state.sendJobDetails.jobId : "",
        jobStatus:  this.props.location.state.sendJobDetails.isActive === 1 ? "Active" : 
                    this.props.location.state.sendJobDetails.isActive === 2 ? "Closed" : 
                    this.props.location.state.sendJobDetails.isActive === 3 ? "Draft" : 
                    this.props.location.state.sendJobDetails.isActive === 4 ? "Pause" : ""

      };
    } else {
      window.location.href = "/";
    }
  }

  componentWillMount() {
    LoginService.TokenUser((res) => {
      localStorage.setItem("jwt-token", res.responseObject);
    });

    StudentAssessmentService.getJobStatusCount(
      "jobId="+this.state.jobId,
      (res) => {
        if (res.status === "success") {
          this.setState({ data: res.responseListObject });
        }
      }
    );
  }

  handleCancel = () => {
    this.props.history.goBack("-1");
    this.props.history.push("/job");
  };

  render() {

    let Call = 0
    let OnlineInterview = 0
    let F2FInterview = 0
    let GroupDiscussion = 0
    let Test = 0
    let Assessment = 0
    let DocumentSubmission = 0
    let DocumentVerification = 0
    let OfferLetter = 0
    let Joined = 0
    let NoOfApplicants = 0
    let PercentageQualifying = 0
    {

        this.state.data !== null && this.state.data.length > 0 && this.state.data.map(a => {

            if (a.stage == "Call") {
              Call = a.count
            }
            if (a.stage == "Online Interview") {
              OnlineInterview = a.count
            }
            if (a.stage == "F2F Interview") {
              F2FInterview = a.count
            }
            if (a.stage == "Group Discussion") {
              GroupDiscussion = a.count
            }
            if (a.stage == "Test") {
              Test = a.count
            }
            if (a.stage == "Assessment") {
              Assessment = a.count
            }
            if (a.stage == "Document Submission") {
              DocumentSubmission = a.count
            }
            if (a.stage == "Document Verification") {
              DocumentVerification = a.count
            }
            if (a.stage == "Offer Letter") {
              OfferLetter = a.count
            }
            if (a.stage == "Joined") {
              Joined = a.count
            }
            if(a.stage === "NoOfApplicants"){
              NoOfApplicants = a.count
            }
            if(a.stage === "PercentageQualifying"){
              PercentageQualifying = a.count
            }
            
        })
    }

    return (
      <React.Fragment>
        <OpsHeader {...this.props} />
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <div>
          <section className="dashboard-tab user-tab pt-50 mb-50">
            <div className="padding-rl-50">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="table-header">
                      <h3>
                        <div className="row">
                          <div className="col-md-8 col-sm-6 col-xs-8">
                            <span className="left-icon">
                              <i className="fa fa-info" aria-hidden="true"></i>
                            </span>{" "}
                            Job Detailed View
                              </div>

                        </div>
                      </h3>
                    </div>
                    <div className="bg-box" id="funnelChart">

                      <div className="row">

                        <div className="col-md-10 col-sm-10 col-xs-12">
                          <label>No. Of Applicants</label>  {" "}
                          <input
                            type="text"
                            name="studentId"
                            id="studentId"
                            value={NoOfApplicants}
                            autoComplete="studentId"
                            placeholder="Candidate ID"
                            className="textboxDesign"
                          />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                          <label>Percentage Qualifying (at the 5Qs stage) </label>  {" "}
                          <input
                            type="text"
                            name="studentId"
                            id="studentId"
                            value={PercentageQualifying}
                            autoComplete="studentId"
                            placeholder="Candidate ID"
                            className="textboxDesign"
                          />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                          <label>Offer Letters Issued </label>  {" "}
                          <input
                            type="text"
                            name="studentId"
                            id="studentId"
                            autoComplete="studentId"
                            value={OfferLetter}
                            placeholder="Candidate ID"
                            className="textboxDesign"
                          />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                          <label>Joinings to date</label>  {" "}
                          <input
                            type="text"
                            name="studentId"
                            id="studentId"
                            value={Joined}
                            autoComplete="studentId"
                            placeholder="Candidate ID"
                            className="textboxDesign"
                          />
                        </div>
                        <div className="col-md-10 col-sm-10 col-xs-12">
                          <label>Status</label>  {" "}
                          <input
                            type="text"
                            name="studentId"
                            id="studentId"
                           value={this.state.jobStatus}
                            autoComplete="studentId"
                            placeholder="Status"
                            className="textboxDesign"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="dashboard-tab user-tab pt-50 mb-50">
            <div className="padding-rl-50">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="table-header">
                      <h3>
                        <div className="row">
                          <div className="col-md-8 col-sm-6 col-xs-8">
                            <span className="left-icon">
                              <i className="fa fa-filter" aria-hidden="true"></i>
                            </span>{" "}
                            Interview Stage Status
                              </div>

                        </div>
                      </h3>
                    </div>

                    <div id="funnelChart">
                      <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 pl pr">
                          <img src={funnelChart} className="img-responsive ml-35" />
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-6 col-xs-12 pl pr">
                            <ul>
                                <li className="F-mt1">
                                    <span className="F-Call"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                       
                                        {Call} </strong> <span className="lable  "> - Call</span>
                                </li>
                                <li className="F-mt3">
                                    <span className="F-Online"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>                                       

                                        {OnlineInterview}</strong> <span className="lable  "> - Online Interview </span>
                                </li>                               
                                {/* <li className="F-mt2">
                                    <span className="F-Interview"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>                                       
                                        {"0"} </strong>  <span className="lable  "> - Interview </span>
                                </li> */}
                               
                                <li className="F-mt3">
                                    <span className="F-F2FInterview"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>                                       
                                        {F2FInterview}   </strong> <span className="lable  "> - F2F Interview</span>
                                </li>
                                <li className="F-mt2">
                                    <span className="F-GroupDiscussion"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>                                      

                                        {GroupDiscussion}   </strong> <span className="lable  "> - Group Discussion </span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-Test"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {Test}                                       
                                    </strong> <span className="lable  "> - Test </span>
                                </li>
                                <li className="F-mt3">
                                    <span className="F-Assessment"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {Assessment}                                       
                                    </strong> <span className="lable  "> - Assessment </span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-DocumentSubmission"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {DocumentSubmission}
                                       
                                    </strong> <span className="lable  "> - Document Submission </span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-DocumentVerification"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {DocumentVerification}
                                       
                                    </strong> <span className="lable  "> - Document Verification </span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-OfferLetter"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {OfferLetter}
                                       
                                    </strong> <span className="lable  "> - Offer Letter </span>
                                </li>
                                <li className="F-mt4">
                                    <span className="F-Joined"><i className="fa fa-stop" aria-hidden="true"></i></span>
                                    <strong>
                                        {Joined}
                                       
                                    </strong> <span className="lable  "> -  Joined </span>
                                </li>
                            </ul>
                        </div>
                       

                      </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12 col-sm-12 mt-40">
                      <a href="#">
                        <button
                          type="button"
                          name="cancelForm"
                          className="btn-4 mr-20 pull-right"
                          onClick={this.handleCancel}
                        >
                          Cancel
                        </button>
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
        <Finxfooter />
      </React.Fragment>
    );
  }
}

export default jobDetails;
