import React from "react";
import Finxfooter from "../../../containers/finxfooter/finxfooter";
// import DashboardHeader from "../../dashboardheader/dashboardheader";
// import axios from "axios";
// import { promises } from "dns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoginService } from "../../../services/login.service";
import PasswordStrengthMeter from "./passwordStrengthMeter";
import {
  success,
  successNotification,
  error,
  errorNotification,
  warning,
  warningNotification,
} from "../../notification/notifications";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
// import AdminHeader from "../../dashboardheader/adminheader";
// import $ from "jquery";

class changepassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userLoginName: "",
      emailId: "",
      userPassword: "",
      userId: "",
      token: "",
      isPasswordChanged: false,
      loading: false,
      currentPwdFlag:"",
      roleName: localStorage.getItem("roleName"),
      isAdminLogin: false,
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack("-1");
  };

  componentDidMount() {
    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
      window.location.reload();
    }
    let params="userId="+localStorage.getItem("userid");
    LoginService.GetUserRegistratonType(params, (res) => {
   
if (res.status === "success") {
       
         this.setState({ currentPwdFlag: res.responseObject.currentPwdFlag });
     
      } else {
    
        this.setState({
          loading: false,
        });
      }
    
    })
    

// if([2,"2"].includes(localStorage.getItem("roleId"))){
//     this.setState({ isAdminLogin: true });
// }
  
    if ((((this.props || "").location || "").state || "").fromAdminChangePassword === true) {
     
      this.setState({ isAdminLogin: true });
    }
  }

  render() {
    console.log("this.state",this.state);
    return (
      <Formik
        initialValues={{
          userId: "",
          userLoginName: "",
          emailId: "",
          userPassword: "",
          confirmPassword: "",
          userOldPassword: "",
          name: "",
          partyId: "",
          isAdminLogin: false,
        }}

        validationSchema={
          this.state.isAdminLogin ||this.state.currentPwdFlag==="N"?
            Yup.object().shape({
              userPassword: Yup.string()
                .required("You must specify a New Password")
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})(^\S*$)/,
                  "Please enter password as per password policy"
                ),
              confirmPassword: Yup.string()
                .required("You must specify a Confirm Password")
                .oneOf([Yup.ref("userPassword")], "Password does not match"),
            })
            :
            Yup.object().shape({
              isAdminLogin: Yup.boolean(),
              userOldPassword: Yup
                .string()
                .when("isAdminLogin", {
                  is: false,
                  then: Yup
                    .string()
                    .required("Current Password is required")
                }),
              userPassword: Yup.string()
                .required("You must specify a New Password")
                .matches(
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})(^\S*$)/,
                  "Please enter password as per password policy"
                ),
              confirmPassword: Yup.string()
                .required("You must specify a Confirm Password")
                .oneOf([Yup.ref("userPassword")], "Password does not match"),
            })}
        onSubmit={(fields) => {
          debugger
          var userPassword = fields["userPassword"];
          if ((((this.props || "").location || "").state || "").fromAdminChangePassword === true) {
            this.setState({ loading: true });
            var data = {
              userPassword: fields.userPassword,
              userLoginName: this.props.location.state.userLoginName,
              emailId: this.props.location.state.emailId,
              userId: this.props.location.state.userId,
            };
          } else {
            this.setState({ loading: true });
            var userOldPassword = fields["userOldPassword"];
            data = {
              userPassword: userPassword,
              userOldPassword: userOldPassword,
              name: JSON.parse(localStorage.getItem("login"))["name"],
              userLoginName: JSON.parse(localStorage.getItem("login"))["userName"],
              emailId: JSON.parse(localStorage.getItem("login"))["emailId"],
              userId: JSON.parse(localStorage.getItem("login"))["userId"],
              partyId: JSON.parse(localStorage.getItem("login"))["partyId"],
            };
          }

          if ((((this.props || "").location || "").state || "").fromAdminChangePassword === true) {
            LoginService.ChangeAdminPassword(data, (cb) => {
              if (cb.status === "success") {
                this.setState({ isPasswordChanged: true });
                if (this.state.isPasswordChanged) {
                  this.setState({ loading: false });
                  success(
                    "Password changed successfully",
                    successNotification
                  );
                  setTimeout(() => {
                    this.props.history.push("/admin-user-list");
                  }, 2000);
                }
              } else {
                this.setState({ loading: false });
                warning(cb.reasonText, warningNotification);
              }
            });
          }else if(this.state.currentPwdFlag==="N"){

         var  data1 = {
              userPassword: userPassword,
              userOldPassword: null,
              name: JSON.parse(localStorage.getItem("login"))["name"],
              userLoginName: JSON.parse(localStorage.getItem("login"))["userName"],
              emailId: JSON.parse(localStorage.getItem("login"))["emailId"],
              userId: JSON.parse(localStorage.getItem("login"))["userId"],
              partyId: JSON.parse(localStorage.getItem("login"))["partyId"],
            };
            LoginService.ChangeGPassword(data1, (cb) => {
              if (cb.status === "success") {
                this.setState({ isPasswordChanged: true });
                if (this.state.isPasswordChanged) {
                  var data = {
                    userName: JSON.parse(localStorage.getItem("login"))[
                      "userName"
                    ],
                    userId: JSON.parse(localStorage.getItem("login"))["userId"],
                  };
                  LoginService.LogoutUser(data, false, (res) => {
                    if (res.status === "success") {
                      this.setState({ loading: false });
                      localStorage.clear();
                      success(
                        "Password changed successfully",
                        successNotification
                      );
                      setTimeout(() => {
                        this.props.history.push("/");
                      }, 5000);
                    } else {
                      this.setState({ loading: false });
                      warning(res.reasonText, warningNotification);
                    }
                  });
                }
              } else {
                this.setState({ loading: false });
                warning(cb.reasonText, warningNotification);
              }
            });






          }
          
          
          
          else {
            let userOldPassword = fields["userOldPassword"];
            if (userOldPassword === "" || userOldPassword === undefined) {
              this.setState({ loading: false });
              error(
                "Please provide old password",
                errorNotification
              );
            } else {
              LoginService.ChangePassword(data, (cb) => {
                if (cb.status === "success") {
                  this.setState({ isPasswordChanged: true });
                  if (this.state.isPasswordChanged) {
                    var data = {
                      userName: JSON.parse(localStorage.getItem("login"))[
                        "userName"
                      ],
                      userId: JSON.parse(localStorage.getItem("login"))["userId"],
                    };
                    LoginService.LogoutUser(data, false, (res) => {
                      if (res.status === "success") {
                        this.setState({ loading: false });
                        localStorage.clear();
                        success(
                          "Password changed successfully",
                          successNotification
                        );
                        setTimeout(() => {
                          this.props.history.push("/");
                        }, 5000);
                      } else {
                        this.setState({ loading: false });
                        warning(res.reasonText, warningNotification);
                      }
                    });
                  }
                } else {
                  this.setState({ loading: false });
                  warning(cb.reasonText, warningNotification);
                }
              });

            }



          }


        }}
        render={({ errors, touched, setFieldValue, values, handleChange }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
{/* <pre>{JSON.stringify(errors,null,2)}</pre> */}
            <Form className="form_name">
              <section className="change-password pt-30 mb-120">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 mt-30"></div>
                    <div className="col-md-5 col-lg-5 col-sm-6 col-xs-12 mt-30">
                      <div className="change-pass">
                        <div className="lock-img">
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <h2>Change Password</h2>
                        <div className="bottom-line"></div>
                        <div className="pass-form">
                          {(!this.state.isAdminLogin&&this.state.currentPwdFlag==="Y")?

                            (<div>
                              <label>Current PASSWORD</label>
                              <Field
                                type="password"
                                name="userOldPassword"
                                autoComplete="off"
                                onChange={handleChange}
                                placeholder="Enter Current Password"
                                className="form-control"
                              />
                              <span className="icon">
                                {values.userOldPassword && !errors.userOldPassword ? (
                                  <span className="">
                                    {" "}
                                    <i
                                      className="fa fa-check-circle green"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>
                                ) : touched.userOldPassword ? (
                                  <span className="">
                                    {" "}
                                    <i
                                      className="fa fa-times-circle red"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                              <ErrorMessage
                                name="userOldPassword"
                                component="div"
                                className="validation"
                              />
                            </div>) : ("")}
                          <div>
                            <label>NEW PASSWORD</label>
                            <Field
                              type="password"
                              name="userPassword"
                              autoComplete="off"
                              onChange={handleChange}
                              placeholder="Enter New Password"
                              className="form-control"
                            />
                            <span className="icon">
                              {values.userPassword && !errors.userPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : touched.userPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                            <ErrorMessage
                              name="userPassword"
                              component="div"
                              className="validation"
                            />
                          </div>
                          <span className="file-size-limit">
                            Your password must contain at least 5 characters and must include 1
                            uppercase alphabet, 1 lowercase alphabet, 1 number and 1 special character
                            and no space </span>
                          <PasswordStrengthMeter
                            password={values.userPassword}
                          />
                          <div className="">
                            <label>CONFIRM PASSWORD</label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              autoComplete="off"
                              id="confirmPassword"
                              placeholder="Enter Confirm New Password"
                              className="form-control icon"
                            />
                            <span className="icon">
                              {values.confirmPassword &&
                                !errors.confirmPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : touched.confirmPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="validation"
                            />
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12 col-md-offset-2">

                              <button
                                type="button"
                                className="btn-5 mt-40 mr-20"
                                onClick={this.handleCancelClick}
                              >
                                Cancel
                              </button>

                              <button type="Submit" className="btn-5 mt-40">
                                Submit
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div></div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 mt-30">
                      <div className="alert alert-info">
                        <b>Password Policy:</b>
                        <p>
                          {" "}
                          Atleast 1 character should be Capital ['A-Z']
                          {values.userPassword.match(/^(?=.*[A-Z])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          Atleast 1 character should be Small ['a-z']
                          {values.userPassword.match(/^(?=.*[a-z])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}{" "}
                        </p>

                        <p>
                          Atleast 1 character should be Numeric ['0-9']
                          {values.userPassword.match(/(?=.*[0-9])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          Atleast 1 character should be Special [@#$%&*]
                          {values.userPassword.match(/(?=.*[!@#\$%\^&\*])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          Atleast 5 character should be there
                          {values.userPassword.match(/(?=.{5,})/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>
                        <p>
                          No Space is allowed [ ]
                          {values.userPassword.match(/(^\S*$)/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
            <Finxfooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default changepassword;
