import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik } from "formik";
// import { LoginService } from "../../services/login.service";
// import { AssessmentReport } from "../../services/fetchAssessmentReport";
import * as moment from "moment";
import { ExcelService } from "../Excel/excel";

export class unassignedUsersReport extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      today: moment(todaydate).format("YYYY-MM-DD"),
      unassignedUsers: this.props.unassignedUsersReport.courseNotAssignStudent
    };
    this.showAll = this.showAll.bind(this);
  }

  showAll() {
    this.setState({ unassignedUsers: this.props.unassignedUsersReport });
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };
  exportToCSV = () => {
    let modifiedData = [];
    let data = this.state.unassignedUsers;

    data.forEach(element => {
      var data1 = {
        Student_ID: element.studentID,
        Full_Name: element.firstName + " " + element.lastName,
        Email_ID: element.emailID,
        Mobile_No: element.mobileNo,
        Created_Date: (element.createdDate || "").split(" ")[0]
      };
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, "Unassigned_Users_Report");
  };
  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          startDate: "",
          endDate: ""
        }}
        render={({ handleSubmit, handleChange }) => (
          <React.Fragment>
            <section className="dashboard-tab user-tab pt-50 mb-50">
              <div className="padding-rl-50">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-header">
                        <h3>
                          <div className="row">
                            <div className="col-md-8 col-sm-6 col-xs-8">
                              <span className="left-icon">
                                <i className="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Unassigned Users Report
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-4">
                              <span className="right-icon">
                                <a href="#">
                                  <i
                                    onClick={() => this.exportToCSV()}
                                    className="fa fa-file-excel-o"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div className="bg-box">
                        {/* <Form onSubmit={handleSubmit} className="">
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-4 pl pr">
                                    <h5 className="filter-heading">
                                      Search Criteria
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>Start Date</label>
                                      <Field
                                        type="date"
                                        name="startDate"
                                        max={this.state.today}
                                        id="startDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>End Date</label>
                                      <Field
                                        type="date"
                                        name="endDate"
                                        max={this.state.today}
                                        id="endDate"
                                        className="form-control"
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="validation"
                                      />
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Search
                                        </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form> */}
                        <div className="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.unassignedUsers || []}
                            filterable
                            ref={refReactTable => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Student ID",
                                    id: "studentID",
                                    accessor: d =>
                                      (d.studentID || "").toString()
                                  },
                                  {
                                    Header: "Student Name",
                                    id: "firstName",
                                    accessor: d =>
                                      (d.firstName || "").toString()
                                  },
                                  {
                                    Header: "Email ID",
                                    id: "emailID",
                                    accessor: d => (d.emailID || "").toString()
                                  },
                                  {
                                    Header: "Mobile NO",
                                    id: "mobileNo",
                                    accessor: d => (d.mobileNo || "").toString()
                                  },
                                  {
                                    Header: "Created Date",
                                    id: "createdDate",
                                    accessor: d =>
                                      (d.createdDate || "").split(" ")[0]
                                  }
                                ]
                              }
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px"
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      />
    );
  }
}

export default unassignedUsersReport;
