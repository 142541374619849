// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";
import { Formik, Form } from "formik";

import * as Yup from "yup";

class ViewEnquiry extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    //console.log(this.props.enquiry);
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={this.props.enquiry}
          validationSchema={Yup.object().shape({})}
          render={() => (
            <React.Fragment>
              <Form
                className="modal fade"
                id="enquiry"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
                data-backdrop="static" data-keyboard="true"
              >
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <button
                      type="reset"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="checkout">
                      <div className="modal-header"></div>
                      <div className="modal-body">
                        <div className="bg-box">
                          <div className="payment-detail">
                            <h2 className="sub-header">Enquiry Details</h2>
                            <div className="table-responsive">
                              <table className="paydetail-table">
                                <tr>
                                  <td>Name</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.fullName}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mobile No</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.mobileNumber}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.emailID}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Message</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.enquiryMessage}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.isResolved}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Resolved On</td>
                                  <td>:</td>
                                  <td>
                                    <b>{this.props.enquiry.ResolvedDate}</b>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </React.Fragment>
          )}
        />
      </React.Fragment>
    );
  }
}

export default ViewEnquiry;
