// Library Imports
import React from 'react';
import ReactPlayer from 'react-player';
import loadable from '@loadable/component';
// Services Imports
import { RegisterUserService } from '../../services/registration.service';
import { LoginService } from '../../services/login.service';
// Component Imports
import LoadingFallback from "../loadingFallBack";
const DashboardHeader = loadable(() => import("../../components/dashboardheader/dashboardheader"), {
  fallback: <LoadingFallback/>
});
const Finxfooter = loadable(() => import("../../containers/finxfooter/finxfooter"), {
  fallback: <LoadingFallback/>
});
const Adminheader = loadable(() => import("../dashboardheader/adminheader"), {
  fallback: <LoadingFallback/>
});
// Asset Imports
const FinxTour = require('../../assets/video/Finx-Tour.mp4');
//! Unused Imports
// import DashboardHeader from '../../components/dashboardheader/dashboardheader';
// import Finxfooter from '../../containers/finxfooter/finxfooter';
// import Adminheader from '../dashboardheader/adminheader';
// import FinxTour from '../../assets/video/Finx-Tour.mp4';
//import { hashHistory } from 'react-router;'
// import userprofile from '../../components/userprofile/userProfileLandingPage';
// import { BrowserRouter, Route } from 'react-router-dom';
//import FinxPPS from '../../assets/video/Tour Video PPT.pps';

class MyTour extends React.Component {

    // constructor(props) {
    // super(props);
    //console.log("propsssss: "+ this.props.personnelData)
    //console.log(this.props)
    // }

    componentDidMount = () => {
        //alert(localStorage.getItem('userid-Token'))
    }

    gotologin = () => {

        window.location.href = '/'


    }
    onEnded = () => {

        var personaldata = localStorage.getItem("PersonnelData") !== null ? JSON.parse(localStorage.getItem("PersonnelData")) : this.props.personnelData;

        //console.log(personaldata)
        if (personaldata.responseObject["isTourCompleted"] === 0) {
            var data = {
                studentID: personaldata.responseObject["studentID"],
                isTourCompleted: 1
            }
            //console.log('my tour', data);
            LoginService.TokenUser(res => {
                if (res.status === "success") {
                    localStorage.setItem("jwt-token", res.responseObject);

                    RegisterUserService.updateMyTour(data, (res) => {
                        //console.log(res);
                        if (res.status === 'success') {
                            this.props.history.push('/userprofile')
                        }
                        else {
                            this.props.history.push('/studentDashboard')
                        }

                    }, (err) => { console.log(err); })
                }
            });
            // this.props.history.push("/");
        } else {
            if ([5,'5'].includes(localStorage.getItem('roleId'))) {
                localStorage.setItem('reload', 'true');
                window.location.href = '/studentDashboard'
            } else {
                localStorage.setItem('reload', 'true');
                window.location.href = '/admin-dashboard'
            }
        }

    }
    render() {
        return (
            <React.Fragment>
                {[5,'5'].includes(localStorage.getItem('roleId')) ?
                    <DashboardHeader {...this.props} />
                    :
                    <Adminheader {...this.props} />
                }

                <section className="">
                    <div style={{ background: '#000', }} className="mb-50">
                        <ReactPlayer url={FinxTour} onEnded={this.onEnded}
                            playing width="100%" position="absolute"
                            z-index="-1"
                            top="0"
                            left="0"
                            height="100vh" />
                    </div>
                    {/* <div className="welcome">
                        <button type="submit" className="btn-1 mt-30"><a href="#" onClick={this.tourHandler} >Take a Tour</a></button>
                    </div> */}
                </section>
                <div className="skip-button">
                    {(localStorage.getItem('userid-Token') === null) ? <button type="submit" className="btn-9 pull-left" onClick={this.gotologin}>Skip Tour</button> : <button type="submit" className="btn-9 pull-left" onClick={this.onEnded} >Skip Tour</button>}
                </div>
                <Finxfooter />
            </React.Fragment>
        )
    }
}
export default MyTour;


//<ReactPlayer url={FinxTour} onEnded={this.onEnded(this.props)}

/* <React.Fragment>
<DashboardHeader />
<div style={{ background: '#000' }}>
<ReactPlayer url={FinxTour} playing fluid={false} width="100%" height="550px" />
<button type="button" className="btn-5 pull-right mr-20"><a href="">Close</a></button>
<Finxfooter />
</div>
</React.Fragment> */
