import React, { Component } from "react";
import ReactTable from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { LoginService } from "../../services/login.service";
import { EnrollmentService } from "../../services/enrollmentservice";
import * as Yup from "yup";
import * as moment from "moment";
import { ExcelService } from "../Excel/excel";
import { warning, warningNotification } from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import AdminHeader from "../dashboardheader/adminheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";

export class studentSection extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      data: [],
      arrOfId: [],
      selected: {},
      documentUrl: "",
      isDataChecked: false,
      today: moment(todaydate).format("YYYY-MM-DD"),

      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
    };
    this.showAll = this.showAll.bind(this);
  }
  componentDidMount() {
    this.initailizedDateDetails();
    console.log(this.props);
    let params =
      "limit=&fromDate=&toDate=&studentID=&studentPartyID=&orderDir=&orderColumn=";
    EnrollmentService.fetchEnrollment(params, (res) => {
      if (res.status === "success") {
        this.setState({
          data: res.responseListObject,
        });
        console.log("enrollresponse", this.state.data);
      } else {
        this.setState({
          data: [],
        });
      }
    });
  }
  resumeDownload(original) {
    console.log("original", original);
    let DocId = "";
    let documentUrl = "";
    let data = original;
    RegisterUserService.downloadDocument(
      "documentId=" +
        original.resumeDocumentId +
        "&bucketTag=" +
        Global_var.CONST_BUCKET_NAME,
      (res) => {
        if (res.status == "success") {
          console.log(res.responseObject);
          this.setState({
            documentUrl: res.responseObject.documentUrl,
          });

          this.downloadURIDoc();
        }
      }
    );
  }

  downloadURIDoc() {
    let uri = "";
    let name = "";
    if (this.state.documentUrl === undefined) {
      uri = "#";
      warning("No document available", warningNotification);
    } else {
      uri = this.state.documentUrl === undefined ? "#" : this.state.documentUrl;
      window.location.href = uri;
    }
  }
  downloadZip() {
    if(this.state.arrOfId == []||this.state.arrOfId==""){
      warning("Please Select Candidate", warningNotification);
    }else{
      let rdata = {
        studentIds: this.state.arrOfId,
      };
      RegisterUserService.resumeDownload(rdata, (res) => {
        if (res.responseObject.documentId !== 0) {
          let DocId = res.responseObject.documentId;
          RegisterUserService.downloadDocument(
            "documentId=" + DocId + "&bucketTag=" + Global_var.CONST_BUCKET_NAME,
            (res) => {
              if (res.status == "success") {
                console.log(res.responseObject);
                this.setState({
                  documentUrl: res.responseObject.documentUrl,
                });
                this.downloadURIDoc();
              }
            }
          );
        }
      });
    }
  }
  toggleRow(original) {
    console.log(original);
    let newarr = [];
    this.state.arrOfId.push(original.studentID);
    console.log(this.state.arrOfId);
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];
    var year = new Date().getFullYear();

    for (var i = 0; i < 100; i++) {
      var current = year - i;
      listOfYear.push(current);
    }

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }

  showAll() {
    this.setState({ data: this.props.studentEnrollmentHistoryData });
  }

  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  render() {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          enrollmentdata: "",
          paymentStatus: "",
          startDate: "",
          endDate: "",
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday: "",
          frommonth: "",
          fromyear: "",
          today: "",
          tomonth: "",
          toyear: "",
        }}
        render={({
          handleSubmit,
          handleChange,
          errors,
          touched,
          values,
          setFieldValue,
        }) => (
          <React.Fragment>
            <AdminHeader {...this.props} />
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-tab pt-50 mb-50">
              <div className="padding-rl-50">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-header">
                        <h3>
                          <div className="row">
                            <div className="col-md-8 col-sm-6 col-xs-8">
                              <span className="left-icon">
                                <i className="fa fa-list" aria-hidden="true"></i>
                              </span>{" "}
                              Student Section
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-4">
                              <span className="right-icon">
                                <a>
                                  <i
                                    onClick={() => this.downloadZip()}
                                    className="fa fa-file-archive-o"
                                    title="Dwonload Resume"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>
                      <div className="bg-box">
                        <div className="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filterable
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            defaultSorted={[
                              {
                                id: "lastModifiedDate",
                                desc: false,
                              },
                            ]}
                            columns={[
                              {
                                columns: [
                                  {
                                    id: "checkbox",
                                    accessor: "",
                                    Cell: ({ original }) => {
                                      return (
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          onChange={() =>
                                            this.toggleRow(original)
                                          }
                                        />
                                      );
                                    },
                                    sortable: false,
                                    filterable: false,
                                    width: 45,
                                  },
                                  {
                                    Header: "Student ID",
                                    id: "studentID",
                                    accessor: (d) =>
                                      (d.studentID || "").toString(),
                                  },
                                  {
                                    Header: "Full Name",
                                    id: "firstName",
                                    accessor: (d) =>
                                      (
                                        d.firstName + " " + d.lastName || ""
                                      ).toString(),
                                  },
                                  {
                                    Header: "Email ID",
                                    id: "emailID",
                                    accessor: (d) => d.emailID,
                                  },
                                  {
                                    Header: "Mobile No",
                                    id: "mobileNo",
                                    accessor: (d) => d.mobileNo,
                                  },
                                  {
                                    Header: "Gender",
                                    id: "gender",

                                    accessor: (d) =>
                                      d.gender === 2
                                        ? "Female"
                                        : d.gender === 1
                                        ? "Male"
                                        : d.gender === 3
                                        ? "Other"
                                        : "",
                                  },
                                  {
                                    Header: "Source",
                                    id: "entryMode",
                                    accessor: (d) =>
                                      d.entryMode
                                        ? d.entryMode === 1
                                          ? "Finx Core"
                                          : d.entryMode === 2
                                          ? "Bulk Upload"
                                          : d.entryMode === 3
                                          ? "Finx Website"
                                          : "Self Registration"
                                        : " ",
                                  },
                                  {
                                    Header: "Created Date",
                                    id: "createdDate",

                                    accessor: (d) =>
                                      d.createdDate ? d.createdDate : " ",
                                  },
                                  {
                                    Header: "Download Resume",
                                    id: "download",
                                    width: 200,
                                    accessor: "",
                                    filterable: false,
                                    Cell: (props) => {
                                      return (
                                        <div>
                                          <a className="mar-left">
                                            {
                                              <i
                                                title="Download Resume"
                                                className="fa fa-download fa-2x "
                                                aria-hidden="true"
                                                onClick={() =>
                                                  this.resumeDownload(
                                                    props.original
                                                  )
                                                }
                                              ></i>
                                            }
                                          </a>
                                        </div>
                                      );
                                    },
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                            style={{
                              width: "100%",
                              maxHeight: "500px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Finxfooter />
          </React.Fragment>
        )}
      />
    );
  }
}

export default studentSection;
