import React from "react";
// import Finxheader from "../../../containers/finxheader/finxheader";
import Finxfooter from "../../../containers/finxfooter/finxfooter";
// import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoginService } from "../../../services/login.service";
import students2 from "../../../assets/images/students2.png";
import { success, successNotification, warning, warningNotification } from "../../notification/notifications";

class forgotpassword extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      userLoginName: "",
      loading: false,
      // isReloading: this.props.history.location.state.isloaded,
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    if (localStorage.getItem('reload') === 'true') {
      localStorage.setItem('reload', 'false');
      window.location.reload();
    }
  }

  render() {
    return (
      <Formik
        initialValues={{
          userLoginName: ""
        }}
        validationSchema={Yup.object().shape({
          userLoginName: Yup.string().required("You must specify a Email")
        })}
        onSubmit={fields => {
          //alert(JSON.stringify(fields));
          var userLoginName = fields["userLoginName"];

          var data = {
            userLoginName: userLoginName
          };

          localStorage.setItem("TokenName", "jti");
          LoginService.TokenUser(res => {
            if (res.status === "success") {
              localStorage.setItem("jti-token", res.responseObject);
              localStorage.setItem("jwt-token", res.responseObject);
              LoginService.ForgotPassword(
                data,
                response => {
                  localStorage.setItem('reload', 'true');
                  if (response.status === "success") {
                    success("A link to reset your password has been sent to your registered email ID.", successNotification);
                    setTimeout(() => {
                      this.props.history.push("/");
                    }, 3000);
                  } else {
                    warning("Your Email ID is not registered with us. Please register yourself by clicking the Register button below.", warningNotification);
                    setTimeout(() => {
                      this.props.history.push("/");
                    }, 3000);
                  }


                },
                error => console.log(error)
              );
            }
          });
        }}
        render={({ errors, touched }) => (
          <React.Fragment>
            <Form className="form_name">
              <section className="dashboard-tab forgot-password">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                      <div className="scr-board mtCenter">
                        <div className="lock-img">
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <h2>Forgot Password</h2>
                        <div className="bottom-line"></div>
                        <div className="scr-form">
                          <label>Enter the Registered Email ID</label>
                          <Field
                            type="text"
                            name="userLoginName"
                            id="userLoginName"
                            placeholder="Email Id"
                            className={
                              "form-control" +
                              (errors.userLoginName && touched.userLoginName
                                ? " is-invalid validation-error "
                                : "")
                            }
                          />

                          <ErrorMessage
                            name="userLoginName"
                            component="div"
                            className="validation"
                          />
                          <div className="row">
                            <div className="col-md-10 col-lg-10 col-sm-12 col-xs-12 col-md-offset-2">
                              <a href="/">
                                {" "}
                                <button type="button" className="btn-5 mt-40 mr-10">
                                  Cancel
                                </button>{" "}
                              </a>
                              <button
                                type="submit"
                                className="btn-5 mt-40"
                                onClick={this.handleClick}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div
                className="modal fade"
                id="resetpass"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="modalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-md" role="document">
                  <div className="modal-content">
                    <section className="registration resetpass-modal">
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-4 col-sm-6 col-xs-12">
                            <div className="modal-head"></div>
                            <img src={students2} className="img-responsive" alt="img" />
                          </div>
                          <div className="col-md-7 col-md-offset-1 col-sm-6 col-xs-12">
                            <div className="reset">
                              <h3>Reseted The Password</h3>
                              <p>Your password has been reseted successfully</p>
                            </div>
                            <div className="row">
                              <div className="col-md-10 col-sm-10 col-xs-12">
                                <button
                                  type="submit"
                                  className="btn-3 mt-30 pull-right"
                                  name="Submit"
                                  data-dismiss="modal"
                                  onClick={this.handleClick}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </Form>
            <Finxfooter />
          </React.Fragment>
        )}
      />
    );
  }
}
export default forgotpassword;
