import { Component } from 'react';

class GlobalClickComponent extends Component {
  constructor(props) {
    super(props);
    this.disabledButtons = new Set();
  }

  componentDidMount() {
    document.addEventListener('click', this.handleGlobalButtonClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleGlobalButtonClick, true);
  }

  handleGlobalButtonClick = (event) => {
    const delay = 2500;
    if (event.target.tagName.toUpperCase() === 'BUTTON'&& event.target.getAttribute('type') === 'submit') {
      const button = event.target;
      if (this.disabledButtons.has(button)) {
        event.stopPropagation();
        event.preventDefault();
        console.log('VamsiD: Double click of Button detected. Ignored.',button);
      } else {
        this.disableButton(button, delay);
      }
    }
  };

  disableButton = (button, delay) => {
    this.disabledButtons.add(button);
    button.style.opacity = '0.5';
    button.style.pointerEvents = 'none';
    console.log('VamsiD: Button',button, 'disabled for', delay, 'ms.');

    setTimeout(() => {
      button.style.opacity = '';
      button.style.pointerEvents = ''; 
      this.disabledButtons.delete(button);
      console.log('VamsiD: Button re-enabled.',button);
    }, delay);
  };

  render() {
    return null; 
  }
}

export default GlobalClickComponent;
