import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
// import $ from "jquery";
import { ProductService } from "../../services/product.service";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
// import { pascalCase, lowerCase } from "change-case";


import {
  success,
  warning,
  successNotification,
  // error,
  // errorNotification,
  warningNotification
} from "../notification/notifications";

// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { ExcelService } from "../Excel/excel";

class ModulesListLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedproductID: this.props.history.location.state?this.props.history.location.state?.product?.productID : localStorage.getItem('SelectedproductID') || '',
      selectedproductname: this.props.history.location.state? this.props.history.location.state.product.productName : localStorage.getItem('selectedproductname'),
      productID: "",

      parentProductID: null,
      partnerID: "",
      partner: "",
      isParentProduct: "",
      productDescription: " ",
      productType: "",

      productPrice: "",
      isActive: "",
      createdBy: "",
      createdDate: "",
      lastModifiedBy: "",
      lastModifiedDate: "",
      productLink: "",
      productAnchor: null,
      productSubtitle: null,
      productLanguage: null,
      productVideourl: null,
      hasModules: null,
      productThumbnail: "",
      moduleName: "",
      moduleDescription: "",
      providerProductID: "",
      isParameterized: "",
      moduleCode: "",
      productModuleMinPassingScore: "",
      productModuleScore: "",
      moduleScore: "",
      // isActive: 1,
      // lastModifiedBy: "",
      moduleActiveData: this.props.modules,
      modulegriddata: this.props.modules,
      filtered: [],
      loading: false,
      activeInactiveStatus: ''
    };

    this.addmodule = this.addmodule.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    let SelectedproductID=this.props.history.location.state?this.props.history.location.state?.product?.productID : localStorage.getItem('selectedgridproduct')?.productID;
    let selectedproductname=this.props.history.location.state? this.props.history.location.state?.product?.productName : localStorage.getItem('selectedgridproduct')?.productName;
    localStorage.setItem("SelectedproductID", SelectedproductID);
    localStorage.setItem("selectedproductname", selectedproductname);

    this.setState({
      modulegriddata: this.props.modules,
      loading: true
    })

    this.setState({ loading: false });

  }



  stateonpagechange = (props) => {
    this.setState({
      modulegriddata: this.props.modules
    })

  }

  stateonfilterchange = (props) => {
    this.setState({
      modulegriddata: this.props.modules,

    })

  }

  handleCancel = () => {
    this.setState({ loading: true });
    window.$("#modal-add-edit").modal('hide');
    window.$("#modal-edit-edit").modal('hide');
    this.props.fetchModulesData();
    this.setState({ loading: false });
  };

  filterCaseInsensitive = ({ id, value }, row) =>
    row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

  handleRole = event => {
    this.setState({ roleType: event.target.value });
  };
  handleClick = value => {
    this.props.history.push("/edit-course", { product: value });
  };

  handleModulesClick = value => {
    this.props.history.push("/modules-list");
  };


  addmodule() {
    console.log("props1111",this.props);
  
var productID= localStorage.getItem('selectedgridproduct')?.productID;



    let parentProductID = "productID=" + productID;
        ProductService.fetchLearningPathParentProductIDAssignUser(parentProductID,
      cb => {

        if (cb.responseListObject&&cb.responseListObject.length !== 0) {

          warning("Modules could not be added since the product is assigned to student(s).", {
            ...warningNotification,
            container: "top-center"
          })
        } else {

          // var self = this


          this.setState({ moduleNameadd: "" });
          this.setState({ moduleDescriptionadd: "" });
          this.setState({ providerProductIDadd: "" });
          this.setState({ isParameterizedadd: "" });
          this.setState({ moduleCodeadd: "" });
          this.setState({ productModuleScoreadd: "" });
          this.setState({ productModuleMinPassingScoreadd: "" });

          window.$("#modal-add-edit").modal('show');
          console.log("vkd");
        }
      });
  }

  editmodule = value => {


    let parentProductID = "productID=" + value.productID
    ProductService.fetchLearningPathParentProductIDAssignUser(parentProductID,
      cb => {

        if (cb.responseListObject.length !== 0) {

          warning("Modules could not be modified since the product is assigned to student(s).", {
            ...warningNotification,
            container: "top-center"
          })
        }
        else {

          // var self = this


          this.setState({ moduleName: value.moduleName });
          this.setState({ moduleDescription: value.moduleDescription });
          this.setState({ providerProductID: value.providerProductID });
          this.setState({ isParameterized: value.isParameterized });
          this.setState({ moduleCode: value.moduleCode });
          this.setState({ productModuleScore: value.productModuleScore });
          this.setState({ productModuleMinPassingScore: value.productModuleMinPassingScore });

          window.$("#modal-edit-edit").modal('show');

        }

      });



  }

  exportToCSV = () => {

    let modifiedData = [];
    let data = this.state.respnoseData;

    data.forEach(element => {
      var data1 = {

      }
      modifiedData.push(data1);
    });

    ExcelService.ExcelData(modifiedData, 'FC-ModuleList-');

  };

  handleStatus = (event, values) => {

    let data = {
      productID: values.productID,
      providerProductID: values.providerProductID,
      isActive: values.isActive === 1 ? 0 : values.isActive === 0 ? 1 : '',
      isActive: values.isActive === 1 ? 0 : values.isActive === 0 ? 1 : '',

    }


    ProductService.updateIsactiveInactiveModuleList(data, cb => {
      if (cb.status === "success") {

        success(
          "Status updated successfully!",
          successNotification
        );
        this.props.fetchModulesData();
      }

    });
  };

  render() {
    console.log("this.props.modules", this.props.modules)
    return (
      <React.Fragment>
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section className="dashboard-tab user-tab pt-50 mb-50">
          <div className="padding-rl-50">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="table-header">
                    <h3>
                      <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-8">
                          <span className="left-icon"><i className="fa fa-list" aria-hidden="true"></i></span> Modules list
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-4">

                          <span className="right-icon">
                            <a title="Add Module" onClick={this.addmodule} href="#" data-toggle="modal" data-backdrop="static" data-keyboard="true">
                              <i className="fa fa-plus" aria-hidden="true"></i></a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                  <div className="bg-box">
                    <div className="table-responsive">

                      <ReactTable
                        minRows={2}
                        //data={this.props.modules}
                        data={this.props.modules || []}
                        filtered={this.state.filtered}
                        onFilteredChange={filtered => {
                          this.setState({
                            filtered: filtered,
                            productgriddata: this.props.modulegriddata,
                          });
                        }}
                        onPageChange={this.stateonpagechange}
                        defaultFilterMethod={this.filterCaseInsensitive}
                        className="-striped -highlight"
                        filterable
                        columns={[
                          {
                            columns: [
                              {
                                Header: "Course ID",
                                id: "productID",
                                accessor: d => d.productID
                              },
                              {
                                Header: "Module Name",
                                id: "moduleName",
                                accessor: d => d.moduleName
                              },
                              {
                                Header: "Module Description",
                                id: "moduleDescription",
                                accessor: d => d.moduleDescription
                              },
                              {
                                Header: "Is Parameterized",
                                id: "isParameterized",
                                accessor: d =>
                                  d.isParameterized,

                                Cell: props => {
                                  return (
                                    props.original.isParameterized === 1 ? "Yes"
                                      : props.original.isParameterized === 0 ? "No" : ""

                                  );
                                }
                              },
                              {
                                Header: "Module Code",
                                id: "moduleCode",
                                filterable: true,
                                accessor: d =>
                                  d.moduleCode
                              },
                              {
                                Header: "Provider Course ID",
                                id: "providerProductID",
                                filterable: false,
                                accessor: d =>
                                  d.providerProductID
                              },

                              {
                                Header: "Is Active",
                                id: "isActive",

                                filterable: false,
                                sortable: false,
                                Cell: props => {

                                  return (
                                    <div>

                                      <Toggle
                                        icons={false}
                                        defaultChecked={
                                          props.original.isActive === 1
                                            ? true
                                            : false
                                        }


                                        onChange={e =>
                                          this.handleStatus(e, props.original)
                                        }
                                      />

                                    </div>
                                  );
                                }
                              },
                              {
                                Header: "Course Min Passing Score",
                                id: "productModuleMinPassingScore",
                                filterable: false,
                                accessor: d =>
                                  d.productModuleMinPassingScore
                              },
                              {
                                Header: "Actions",
                                id: "action",
                                filterable: false,
                                sortable: false,
                                Cell: props => {
                                  return (
                                    <a className="mar-left" href="#">
                                      <i
                                        className="fa fa-edit fa-2x"
                                        aria-hidden="true"

                                        onClick={() =>
                                          this.editmodule(props.original)
                                        }
                                        href="" data-toggle="modal" data-backdrop="static" data-keyboard="true"

                                      ></i>
                                    </a>
                                  );
                                }
                              },
                            ]
                          }
                        ]}
                        defaultPageSize={10}
                        style={{
                          width: "100%",
                          maxHeight: "500px"
                        }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Open add modules popup   */}
          <div className="modal fade" id="modal-add-edit" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-backdrop="static" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    productNameadd: this.state.selectedproductname,
                    moduleNameadd: "",
                    moduleDescriptionadd: "",
                    isParameterizedadd: "",
                    moduleCodeadd: "",
                    productModuleScoreadd: "",
                    productModuleMinPassingScoreadd: "",
                    providerProductIDadd: "",

                    statusadd: this.state.isActive,
                  }}

                  validationSchema={Yup.object().shape({
                    //static validations
                    moduleNameadd: Yup.string().required("You must specify a Module Name"),
                    moduleDescriptionadd: Yup.string(),
                    providerProductIDadd: Yup.string().matches(
                      /^[0-9]+\d$/,
                      "Please provide valid provider"
                    ).required("You must specify a provider"),
                    moduleCodeadd: Yup.string(),

                    productModuleScoreadd: Yup.number().integer().positive().typeError('Module score should be a number'),
                    productModuleMinPassingScoreadd: Yup.number().typeError('Module passing score should be a number').positive()
                      .lessThan(Yup.ref('productModuleScoreadd'),
                        "Minimum Passing Score should be less than Product Module Score"),
                  })}

                  onSubmit={(values, { resetForm }) => {
                    // window.$("#modal-add-edit").modal('hide');
                    this.setState({ loading: true });
                    let data = {

                      productID: this.state.selectedproductID,
                      productName: this.state.selectedproductname,
                      moduleName: values.moduleNameadd.trim(),
                      moduleDescription: values.moduleDescriptionadd,

                      providerProductID: values.providerProductIDadd ? values.providerProductIDadd : 0,
                      isParameterized: values.isParameterizedadd ? 1 : 0,
                      moduleCode: values.moduleCodeadd,
                      productModuleMinPassingScore: values.productModuleMinPassingScoreadd,
                      productModuleScore: values.productModuleScoreadd,
                      moduleScore: values.moduleScoreadd,
                      isActive: 1,
                      lastModifiedBy: 3,
                      createdBy: localStorage.getItem('userid-Token'),
                    }

                    ProductService.addModules(
                      data,
                      cb => {

                        if (cb.status === "success") {
                          success("Module added successfully!", successNotification);
                          this.setState({ loading: false });
                          window.$("#modal-add-edit").modal('hide');
                          this.props.fetchModulesData();
                          resetForm({});
                        } else {
                          this.setState({ loading: false });

                          warning(cb.reasonText, {
                            ...warningNotification,
                            container: "top-center"
                          })

                        }


                      },
                      err => console.log("Error", err)

                    );
                    this.setState({ loading: false });
                  }

                  }
                  render={({ errors, touched, handleSubmit, handleChange, values, ...formikProps }) => (
                    <React.Fragment>
                      {this.state.loading && (
                        <PopUpPortal
                          HidePopup={this.state.loading}
                          IsVisible={this.state.loading}
                        />
                      )}
                      <Form onSubmit={handleSubmit}
                        onKeyDown={onKeyDown => {
                          if (
                            (onKeyDown.charCode || onKeyDown.keyCode) ===
                            13 &&

                            onKeyDown.target.name !== "submitForm"
                          ) {
                            onKeyDown.preventDefault();
                          }
                        }}
                        className="form-group">
                        <section className="updateGDPI">
                          <div className="modal-body">
                            <button
                              type="reset"
                              className="close"
                              onClick={this.handleCancel}
                            > &times; </button>
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div className="product-add">

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Course Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="productNameadd"
                                          id="productName"
                                          onChange={handleChange}
                                          placeholder="Product Name"
                                          className="form-control"
                                          disabled={true}
                                        />
                                        <ErrorMessage
                                          name="productNameadd"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleNameadd"
                                          id="moduleName"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleNameadd &&
                                              touched.moduleNameadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Name"
                                        />
                                        <ErrorMessage
                                          name="moduleNameadd"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Description

                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleDescriptionadd"
                                          id="moduleDescription"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleDescriptionadd &&
                                              touched.moduleDescriptionadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Description"
                                        />
                                        <ErrorMessage
                                          name="moduleDescriptionadd"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Provider
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="providerProductIDadd"
                                          id="providerProductID"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.providerProductIDadd &&
                                              touched.providerProductIDadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Please enter module id of module from respected provider"
                                        />
                                        <ErrorMessage
                                          name="providerProductIDadd"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label className="form-field" htmlFor="hasModules">
                                          <span>Is Parameterized?</span>

                                          <Field
                                            type="checkbox"
                                            className="form-control1"
                                            name="isParameterizedadd"
                                            onChange={handleChange}
                                          />
                                        </label>
                                        <div className="form-field-error">{errors.isParameterizedadd}</div>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Code

                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleCodeadd"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleCodeadd &&
                                              touched.moduleCodeadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Code"
                                        />
                                        <ErrorMessage
                                          name="moduleCodeadd"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Score

                                        </label>
                                        <Field
                                          type="text"
                                          name="productModuleScoreadd"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.productModuleScoreadd &&
                                              touched.productModuleScoreadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Enter Module Score"
                                        />
                                        <ErrorMessage
                                          name="productModuleScoreadd"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Passing Score

                                        </label>
                                        <Field
                                          type="text"
                                          name="productModuleMinPassingScoreadd"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.productModuleMinPassingScoreadd &&
                                              touched.productModuleMinPassingScoreadd
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Minimum Passing Score"
                                        />
                                        <ErrorMessage
                                          name="productModuleMinPassingScoreadd"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">

                                    <button
                                      type="submit"
                                      name="submitForm"
                                      className="btn-4 pull-right"

                                      onClick={async () => {
                                        const curErrors = await formikProps.validateForm();
                                        const curErrorsKeys = Object.keys(
                                          curErrors
                                        );

                                        if (curErrorsKeys.length) {

                                          const el = document.getElementById(
                                            curErrorsKeys[0]
                                          );

                                          if (el) el.focus();
                                        }
                                      }

                                      }
                                    >Submit</button>
                                    <button

                                      type="reset"
                                      className="btn-4 mr-20 pull-right"
                                      onClick={this.handleCancel}
                                    >
                                      Cancel
                                    </button>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </Form>
                    </React.Fragment>
                  )}
                />

              </div>
            </div>
          </div>


          {/* Open edit modules popup   */}
          <div className="modal fade" id="modal-edit-edit" tabIndex="-1" role="dialog" aria-labelledby="modalLabel" data-backdrop="static" data-keyboard="true" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    productName: this.state.selectedproductname,
                    moduleName: this.state.moduleName,
                    moduleDescription: this.state.moduleDescription,
                    isParameterized: this.state.isParameterized,
                    moduleCode: this.state.moduleCode,
                    productModuleScore: this.state.productModuleScore,
                    productModuleMinPassingScore: this.state.productModuleMinPassingScore,
                    providerProductID: this.state.providerProductID,
                    lastModifiedBy: this.state.lastModifiedBy,
                    lastModifiedDate: "",

                    status: this.state.isActive,
                  }}
                  validationSchema={Yup.object().shape({
                    //static validations
                    moduleName: Yup.string().required("You must specify a Module Name"),
                    moduleDescription: Yup.string(),
                    providerProductID: Yup.string().matches(
                      /^[0-9]+\d$/,
                      "Please provide valid provider"
                    ),
                    moduleCode: Yup.string(),


                    productModuleScore: Yup.number().integer().typeError('Module score should be a number'),
                    productModuleMinPassingScore: Yup.number().typeError('Module passing score should be a number')
                      .lessThan(Yup.ref('productModuleScore'),
                        "Minimum Passing Score should be less than Product Module Score"),

                  })}

                  onSubmit={values => {

                    this.setState({ loading: true });
                    let data = {

                      productID: this.state.selectedproductID,
                      productName: this.state.selectedproductname,
                      moduleName: values.moduleName.trim(),
                      moduleDescription: values.moduleDescription,

                      providerProductID: values.providerProductID,
                      isParameterized: values.isParameterized ? 1 : 0,
                      moduleCode: values.moduleCode,
                      productModuleMinPassingScore: values.productModuleMinPassingScore,
                      productModuleScore: values.productModuleScore,
                      moduleScore: values.moduleScore,

                      lastModifiedBy: localStorage.getItem('userid-Token'),

                    }
                    ProductService.updateModules(
                      data,
                      cb => {
                        if (cb.status === "success") {
                          this.setState({ loading: false });
                          success(

                            "Module updated successfully!",
                            successNotification
                          );
                          window.$("#modal-edit-edit").modal('hide');

                          this.props.fetchModulesData();
                          //this.props.addProduct();
                        } else {

                          warning(cb.reasonText, {
                            ...warningNotification,
                            container: "top-center"
                          })
                        }
                      },
                      err => console.log("Error", err)
                    );
                  }

                  }
                  render={({ errors, touched, handleSubmit, handleChange, values, ...formikProps }) => (
                    <React.Fragment>
                      {this.state.loading && (
                        <PopUpPortal
                          HidePopup={this.state.loading}
                          IsVisible={this.state.loading}
                        />
                      )}
                      <Form onSubmit={handleSubmit}
                        onKeyDown={onKeyDown => {
                          if (
                            (onKeyDown.charCode || onKeyDown.keyCode) ===
                            13 &&

                            onKeyDown.target.name !== "submitForm"
                            &&
                            onKeyDown.target.name !== "cancelForm"
                          ) {
                            onKeyDown.preventDefault();
                          }
                        }}
                      >
                        <section className="updateGDPI">
                          <div className="modal-body">
                            <button
                              type="reset"
                              className="close"
                              onClick={this.handleCancel}
                            > &times; </button>
                            <div className="row">
                              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                <div className="product-add">

                                  <div className="form-group">
                                    <div className="row">
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Course Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="productName"
                                          id="productName"
                                          onChange={handleChange}
                                          placeholder="Product Name"
                                          className="form-control"
                                          disabled={true}
                                        />
                                        <ErrorMessage
                                          name="productName"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Name{" "}
                                          <span className="validation"> *</span>
                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleName"
                                          id="moduleName"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleName &&
                                              touched.moduleName
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Name"
                                        />
                                        <ErrorMessage
                                          name="moduleName"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Description

                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleDescription"
                                          id="moduleDescription"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleDescription &&
                                              touched.moduleDescription
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Description"
                                        />
                                        <ErrorMessage
                                          name="moduleDescription"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Provider

                                        </label>
                                        <Field
                                          type="text"
                                          name="providerProductID"
                                          id="providerProductID"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.providerProductID &&
                                              touched.providerProductID
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="provider"
                                          disabled={true}
                                        />
                                        <ErrorMessage
                                          name="providerProductID"
                                          className="validation "
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label className="form-field" htmlFor="hasModules">
                                          <span>Is Parameterized?</span>

                                          <Field
                                            type="checkbox"
                                            className="form-control1"
                                            name="isParameterized"
                                            onChange={handleChange}
                                          />
                                        </label>
                                        <div className="form-field-error">{errors.isParameterized}</div>
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Code

                                        </label>
                                        <Field
                                          type="text"
                                          name="moduleCode"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.moduleCode &&
                                              touched.moduleCode
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Module Code"
                                        />
                                        <ErrorMessage
                                          name="moduleCode"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-12 col-sm-12 col-xs-12"></div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Score

                                        </label>
                                        <Field
                                          type="text"
                                          name="productModuleScore"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.productModuleScore &&
                                              touched.productModuleScore
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Minimum Passing Score"
                                        />
                                        <ErrorMessage
                                          name="productModuleScore"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                      <div className="col-md-6 col-sm-6 col-xs-12">
                                        <label>
                                          Module Passing Score

                                        </label>
                                        <Field
                                          type="text"
                                          name="productModuleMinPassingScore"
                                          onChange={handleChange}
                                          className={
                                            "form-control" +
                                            (errors.productModuleMinPassingScore &&
                                              touched.productModuleMinPassingScore
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder="Minimum Passing Score"
                                        />
                                        <ErrorMessage
                                          name="productModuleMinPassingScore"
                                          className="validation"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">

                                    <button
                                      type="submit"
                                      name="submitForm"
                                      className="btn-4 pull-right"
                                      onClick={async () => {
                                        const curErrors = await formikProps.validateForm();
                                        const curErrorsKeys = Object.keys(
                                          curErrors
                                        );

                                        if (curErrorsKeys.length) {

                                          const el = document.getElementById(
                                            curErrorsKeys[0]
                                          );

                                          if (el) el.focus();
                                        }
                                      }}
                                    >
                                      Submit
                                    </button>


                                    <button

                                      type="reset"
                                      name="cancelForm"
                                      className="btn-4 mr-20 pull-right"
                                      onClick={this.handleCancel}

                                    >
                                      Cancel
                                    </button>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </Form>
                    </React.Fragment>
                  )}
                />

              </div>
            </div>
          </div>


        </section>
      </React.Fragment >
    );
  }
}

export default ModulesListLandingPage;
