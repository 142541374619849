import {
    MAINTENANCE_FETCH_PENDING,
    MAINTENANCE_FETCH_SUCCESS,
    MAINTENANCE_FETCH_ERROR
} from '../action/maintenanceAction';

const initialState = {
    isMaintenance: 0,
    timeRemaining: "00:00:00",
    isNotified:0,
    timeNotiRemaining:"00:00:00",
    isClicked: true,
    pending: false,
    error: null
}
const maintenanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAINTENANCE_FETCH_PENDING:
            return {
                ...state,
                pending: true
            }
        case MAINTENANCE_FETCH_SUCCESS:
            return {
                ...state,
                pending: false,
                isMaintenance: action.payload.isMaintenance,
                timeRemaining: action.payload.timeRemaining,
                isNotified:action.payload.isNotified,
                timeNotiRemaining:action.payload.timeNotiRemaining,
                isClicked: action.payload.isClicked
            }
        case MAINTENANCE_FETCH_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        default:
            return state;
    }
}

export default maintenanceReducer;

export const getIsMaintenance = state => state.isMaintenance;
export const getTimeRemaining = state => state.timeRemaining;
export const getIsNotified= state => state.isNotified;
export const getTimeNotiRemaining= state => state.timeNotiRemaining;
export const getIsClicked = state => state.isClicked;
export const getPending = state => state.pending;
export const getError = state => state.error;
