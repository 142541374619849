// Library Imports
import React from 'react';
import Typography from '@material-ui/core/Typography';
import crypto from "crypto-browserify";
// Asset Imports
import '../../assets/css/style.css';
const privacyPolicy = require('../../assets/pdf/FinX Privacy Policy.pdf');
const cookiePolicy = require('../../assets/pdf/Cookie Policy.pdf');


function Copyright() {
    return (
        <Typography variant="inherit" color="inherit" align="center">
            {' © '}
            {' '}
            {new Date().getFullYear()}
            {' AV Financial Experts Network Pvt Ltd.'}
            {'.'}
        </Typography>
    );
}
function encryptData() {
    try {
        const SECRET_KEY = "ThisIsASecretKey1234567890123456";
        const IV = "ThisIsAnIV123456";
        const emailID = localStorage.getItem("eMail") || "";
        console.log("Original Email:", emailID);

        let params = `emailId=${emailID}&unsubscribeFlag=N&unsubscribeGrpId=301`;
        const cipher = crypto.createCipheriv(
            "aes-256-cbc",
            Buffer.from(SECRET_KEY),
            Buffer.from(IV, "utf8")
        );

        let encryptedData = cipher.update(params, "utf8", "base64");
        encryptedData += cipher.final("base64");
        console.log("Encrypted Data:", encryptedData, params);
        return encryptedData;
    } catch (error) {
        console.error("Exception during encryption: ", error.message);
        return null;
    }
}

function finxfooter() {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                    {([5, "5"].includes(localStorage.getItem("roleId"))&&(!["",null,undefined].includes(localStorage.getItem("eMail"))))?(
                        <>
                               <div className="col-md-2 col-sm-2 col-xs-12">
                               <p className="text-center"><a href="https://www.finxpert.org" rel="noopener noreferrer" target="_blank"> www.finxpert.org</a></p>
                           </div>
                           <div className="col-md-4 col-sm-3 col-xs-12">
                               <p className="text-center"><a href> <Copyright /> </a> </p>
                           </div>
                           <div className="col-md-4 col-sm-4 col-xs-12">
                               <p className="text-center">
                                   <a href={privacyPolicy} target="_blank" rel="noopener noreferrer"> Privacy Policy |</a>
                                   <a href={cookiePolicy} target="_blank" rel="noopener noreferrer">Cookies Policy </a>
                               </p>
                           </div>
                        <div className='col-md-2 col-sm-3 col-xs-12'>
                            <p className="text-center">
                                <a href  onClick={() => {
                                const encryptData1 =encryptData()
                                console.log("encrypted",encryptData1);
                              if(!["",null,undefined].includes(encryptData1)){
                              setTimeout(()=>{
                                window.open(`/unsubscribe-page?${encryptData1}`, "_blank");
                              },500)
                            }
                          }} target="_blank" rel="noopener noreferrer">Unsubscribe Mails</a>
                          </p>
                        </div>
                        </>
                        ):(
                            <>
                            <div className="col-md-2 col-sm-3 col-xs-12">
                            <p className="text-center"><a href="https://www.finxpert.org" rel="noopener noreferrer" target="_blank"> www.finxpert.org</a></p>
                        </div>
                        <div className="col-md-5 col-sm-4 col-xs-12">
                            <p className="text-center"><a href> <Copyright /> </a> </p>
                        </div>
                        <div className="col-md-5 col-sm-5 col-xs-12">
                            <p className="text-center">
                                <a href={privacyPolicy} target="_blank" rel="noopener noreferrer"> Privacy Policy |</a>
                                <a href={cookiePolicy} target="_blank" rel="noopener noreferrer">Cookies Policy </a>
                                {/* <a href="finx-privacy-policy"> Privacy Policy |</a>
                                <a href="finx-cookies-policy">Cookies Policy | </a>
                                <a href="finx-disclaimer">Disclaimer | </a>
                                <a href="faq">FAQs  </a> */}
                                {/* { (localStorage.getItem('userid-Token') === null) ? <a href="\my-tour">Tour</a>  : localStorage.getItem('roleId') == 5 ? <a href="\my-tour">Tour</a> : "" } */}
                            </p>
                        </div>
                        </>
                        )}
                    </div>
                </div>
            </footer>
            <noscript>Not seeing a <a href="https://www.scrolltotop.com/">Scroll to Top Button</a>? Go to our FAQ page for more info.</noscript>
        </div>
    );
}

export default finxfooter;
