import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
// import DashboardHeader from "../dashboardheader/adminheader";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import ReactTable from "react-table";
import "react-table/react-table.css";
// import Toggle from "react-toggle";
import "react-toggle/style.css";
import RestDataSource from "../../services/restdatasource";
import { LoginService } from "../../services/login.service";
// import { RegisterUserService } from "../../services/registration.service";
import { Global_var } from "../../global/global_var";
import $ from "jquery";
import { inActiveAdminUser } from "../../services/admin.service";
import { ProductService } from "../../services/product.service";

import {
    success,
    warning,
    successNotification,
    // error,
    // errorNotification,
    warningNotification
} from "../notification/notifications";

// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import { ExcelService } from "../Excel/excel";
// import { de } from "date-fns/locale";

class LearningPathLandingPage extends Component {
    constructor(props) {
        //
        super(props);
        //console.log("in my learningPath", this.props);
        //console.log("in my learningPathProductData ", this.props);


        this.state = {


            //   parentProductID: null,
            //   partnerID: "",
            //   partner: "",
            //   isParentProduct: "",
            //   buid: 2,
            //   productDescription: " ",
            //   productType: "",


            //"productPreRequisiteID": 3,
            "parentProductID": "",
            "sequenceCount": "",
            "seqProductID": "",
            "isActive": 1,
            "levelcount": '',
            productName: "",
            //"createdBy": 2,
            //"createdDate": "15/08/2014",
            //"lastModifiedBy": 1,
            //"lastModifiedDate": "10/08/2019"

            name: "",
            shareholders: [{ name: "" }],
            currentproductlearning: '',
            productlistdata: '',
            pojoArrayData: [],
            ExistingProduct: '',
            selectedaddproduct: [],
            listofproducts: [this.props.learningPathProductData],
            editlearningpathid: '',
        };


        this.addLearning = this.addLearning.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        // this.state = {
        //     name: "",
        //     shareholders: [{ name: "" }]
        // };
        //console.log("State data : ", this.state);

    }


    componentDidMount() {
        // var self = this;
        let selectedoptions = [];
        let basicselectedoptions = [];

        $("#root").on("change", ".selectLPboxadd", function () {
            basicselectedoptions = [];
            basicselectedoptions.push($(this).val())
            selectedoptions.push($(this).val())
        });


        $("#root").on("change", ".selectLPboxedit", function () {
            selectedoptions = [];
            basicselectedoptions = [];
            basicselectedoptions.push($(this).val())
            selectedoptions.push($(this).val())
        });



        $("#root").on("change", ".selectLPbox", function () {
            if (basicselectedoptions.includes($(this).val())) {

                $(".add_addselectbox").addClass("disabled")
                $(".add_addselectbox").hide()
                $(".selectLPbox").attr("disabled", true);
            }
            else {
                $(".add_addselectbox").removeClass("disabled")
                $(".add_addselectbox").show()


                //$(".selectLPbox option:selected").attr('disabled', 'disabled')
                //console.log("selectedoptions" , selectedoptions) ;

            }
            selectedoptions.push($(this).val())
            selectedoptions.map((item, index) => {
                // $('.selectLPbox').children('option[value=' + item + ']').attr('disabled', true)
                $('.selectLPbox').children('option[value=' + item + ']').css('display', 'none')
                $('.selectLPEditbox').children('option[value=' + item + ']').css('display', 'none')
            });
        });



        $("#root").on("focus", ".selectLPbox", function () {
            selectedoptions.map((item, index) => {
                $('.selectLPbox').children('option[value=' + item + ']').attr('disabled', true).hide()
                //$('.selectLPboxadd').children('option[value=' + item + ']').hide()
            });
        });
    }

    handleNameChange = evt => {
        this.setState({ name: evt.target.value });
    };

    handleShareholderNameChange = idx => evt => {
        const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            return { ...shareholder, name: evt.target.value };
        });

        this.setState({ shareholders: newShareholders });
    };

    // handleSubmit = evt => {
    // const { name, shareholders } = this.state;

    // };

    removeByAttr = (arr, attr, value) => {

        var i = arr.length;
        while (i--) {
            //console.log(arr[i]['productID']+ " === " + value)
            if (arr[i]['productID'] === value) {
                //console.log('remove')
                arr.splice(i, 1);

                this.setState({
                    listofproducts: this.state.listofproducts.push(arr),
                });
            }
        }

        return this.state.listofproducts;
    }

    handleAddShareholder = idx => (e) => {

        this.setState({
            shareholders: this.state.shareholders.concat([{ name: "" }]),
            levelcount: idx + 1
        });
        let newselectbox = ".level" + this.state.levelcount;
        $("select[name='" + newselectbox + "']").val('');
    };

    handleRemoveShareholder = idx => () => {
        // $(".add_addselectbox").removeClass("disabled")
        // $(".add_addselectbox").show()

        if (idx !== 1) {
            this.setState({
                shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx + 1),
                //levelcount: idx
            });
        }
        console.log(this.state.shareholders + " " + this.state.levelcount)
        this.setState({
            levelcount: this.state.levelcount - 1
        });
        console.log("levelcount", this.state.levelcount)
        let newselectbox = ".level" + this.state.levelcount;
        $("select[name='" + newselectbox + "']").val('');
    };


    handleCancel = () => {
        window.$("#modal-add-edit").modal('hide');
        window.$("#modal-edit-edit").modal('hide');
        // window.location.reload();
        this.props.fetchLearningPath()
    };

    filterCaseInsensitive = ({ id, value }, row) =>
        row[id] ? row[id].toLowerCase().includes(value.toLowerCase()) : true;

    handleRole = event => {
        this.setState({ roleType: event.target.value });
    };

    handleModulesClick = value => {
        this.props.history.push("/modules-list");
    };


    addLearning() {
        // var self = this
        //console.log("Modal Values:" + JSON.stringify())
        this.setState({
            listofproducts: this.props.learningPathProductData,
        })

        // setTimeout(function(){
        //     self.setState({
        //    moduleproducttype : modalvalue.productType,
        //     moduleproductname : modalvalue.productName,
        //     moduleprovider : modalvalue.providerProductID,
        //   moduleproductId : modalvalue.productID
        // })

        // //console.log("Product Type :"+self.state.moduleproducttype )
        // //console.log("Product Name :"+self.state.moduleproductname )
        // //console.log("Product provider ID :"+self.state.providerProductID )
        // //console.log("product ID :"+self.state.moduleproductId )
        // }, 1000)

    }



    editLearning = value => {


        let parentProductID = "productID=" + value.parentProductID
        ProductService.fetchLearningPathParentProductIDAssignUser(parentProductID,
            cb => {

                if (cb.responseListObject.length !== 0) {

                    warning("Learning path could not be modified since the product is assigned to the student(s).", {
                        ...warningNotification,
                        container: "top-center"
                    })
                } else {

                    let paraentIdParameter = "parentProductID=" + value.parentProductID;
                    console.log("paraentIdParameter", paraentIdParameter)
                    this.setState({
                        editlearningpathid: value.parentProductID
                    })

                    ProductService.fetchLearningPathParentProductID(paraentIdParameter,
                        cb => {


                            //console.log("CB", JSON.stringify(cb));
                            if (cb.status === "success") {
                                //console.log("CB", cb.responseListObject);

                                this.props.learningPathProductData.map(item => (
                                    item.productID === value.parentProductID ?
                                        this.setState({
                                            productName: item.productID,
                                        }) : ''

                                ));
                                this.setState({
                                    name: cb.responseListObject[0].productPreRequisiteID,
                                    currentproductlearning: cb.responseListObject,
                                    productlistdata: this.props.learningPathProductData,
                                });

                                let pojoArray = [];
                                let currentData = this.state.currentproductlearning ? this.state.currentproductlearning : this.state.currentproductlearning;
                                let productData = this.state.productlistdata;


                                let variablevalue = '';

                                currentData.forEach(elementCurrentData => {
                                    productData.forEach(elementProductData => {
                                        if (elementProductData.productID === elementCurrentData.seqProductID) {
                                            let pojo = {
                                                productName: elementProductData.productName,
                                                productID: elementProductData.productID
                                            }
                                            variablevalue += " " + elementProductData.productName + " >> ";

                                            pojoArray.push(pojo);
                                        }
                                    });
                                });

                                console.log("variablevalue", variablevalue);

                                this.setState({ ExistingProduct: variablevalue.substr(0, variablevalue.length - 2) });
                                console.log("ExistingProduct", this.state.ExistingProduct)
                                //console.log("ExistingProduct", this.state.ExistingProduct);

                                this.state.pojoArrayData.push(pojoArray);

                                this.setState({
                                    listofproducts: this.props.learningPathProductData,
                                })
                                window.$("#modal-edit-edit").modal('show');

                            } else {
                                // error(cb.reasonText, {
                                //     ...errorNotification,
                                //     container: "top-center"
                                // });
                                warning(cb.reasonText, {
                                    ...warningNotification,
                                    container: "top-center"
                                })
                            }
                        },
                        err => console.log("Error", err)
                    );
                }
            });

        //if(value.parentProductID )

        //console.log("Values : ", value)

    }

    exportToCSV = () => {
        //
        let modifiedData = [];
        let data = this.state.respnoseData;

        data.forEach(element => {
            var data1 = {
                // Name : element.name,
                // UserLoginName: element.userLoginName,
                // MobileNumber: element.mobileNumber,
                // EmailId: element.emailId,
                // ActivateDate: element.activateDate,
                // ExpiryDate: element.expiryDate,
                // CreatedBy: element.createdBy,
                // CreatedDate: element.createdDate,
                // LastModifiedBy: element.lastModifiedBy,
                // LastModifiedDateTime: element.lastModifiedDateTime,
                // IsActive: element.isActive,
                // LastLoginDateTime: element.lastLoginDateTime,

            }
            modifiedData.push(data1);
        });

        ExcelService.ExcelData(modifiedData, 'FC-LearningList-');
    };
    handleStatus = (event, values) => {
        //

        this.dataSource = new RestDataSource(
            Global_var.BASEURL +
            Global_var.URL_FETCH_ADMIN_USER_BY_ROLE_PARTY_ID +
            "?partyID=" +
            values.partyID +
            "&roleID=" +
            values?.roleId
        );

        LoginService.TokenUser(res => {
            //
            if (res.status === "success") {
                //
                localStorage.setItem("jwt-token", res.responseObject);
                this.dataSource.GetData(res => {
                    let data = res.responseListObject;

                    inActiveAdminUser(data, cb => {
                        if (cb.status === "success") {
                            success(

                                "Status updated successfully!",
                                successNotification
                            );
                            LoginService.TokenUser(res => {
                                if (res.status === "success") {
                                    localStorage.setItem("jwt-token", res.responseObject);
                                    this.props.fetchLearningPath();
                                }
                            });
                        }
                    });
                });
            }
        });
    };

    render() {
        //console.log("Learning Path data", this.props.learningPath);
        console.log("this.props.learningPath ", this.props.learningPath)

        // var path = []
        return (

            <React.Fragment>
                <section className="dashboard-tab user-tab pt-50 mb-50">
                    <div className="padding-rl-50">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="table-header">
                                        <h3>
                                            <div className="row">
                                                <div className="col-md-8 col-sm-6 col-xs-8">
                                                    <span className="left-icon"><i className="fa fa-list" aria-hidden="true"></i></span> Learning Path List
                                                </div>
                                                <div className="col-md-4 col-sm-6 col-xs-4">
                                                    {/* <span className="right-icon">
                                                        <a href=""><i className="fa fa-file-excel-o" aria-hidden="true"></i></a>
                                                    </span> */}
                                                    <span className="right-icon">
                                                        <a title="Add Learning Path" onClick={this.addLearning} href data-toggle="modal" data-backdrop="static" data-keyboard="true" data-target="#modal-add-edit">
                                                            <i className="fa fa-plus" aria-hidden="true"></i></a>
                                                    </span>
                                                </div>
                                            </div>
                                        </h3>
                                    </div>
                                    <div className="bg-box">

                                        <div className="table-responsive">
                                            <ReactTable
                                                minRows={2}
                                                data={this.props.learningPath || []}

                                                className="-striped -highlight"
                                                filterable

                                                columns={[
                                                    {
                                                        columns: [
                                                            {
                                                                Header: "Product Name",
                                                                id: "productName",
                                                                accessor: d => d.parentProductID,

                                                                //accessor: d => d.productType,

                                                                Cell: props => {

                                                                    let name = this.props.learningPathProductData ? this.props.learningPathProductData.map(item => (
                                                                        item.productID === props.original.parentProductID ? item.productName : ''
                                                                    )) : "";
                                                                    return (
                                                                        <div>
                                                                            {name}
                                                                        </div>
                                                                    );
                                                                }
                                                            },
                                                            {
                                                                Header: "Levels",
                                                                id: "level",
                                                                accessor: d => d.count
                                                            },

                                                            // {
                                                            //     Header: "Is Active",
                                                            //     id: "isActive",
                                                            //     accessor: d =>
                                                            //         d.isActive
                                                            // },

                                                            {
                                                                Header: "Action",
                                                                id: "action",
                                                                filterable: false,
                                                                Cell: props => {
                                                                    return (
                                                                        <a className="mar-left" href>
                                                                            <i
                                                                                className="fa fa-edit fa-2x"
                                                                                aria-hidden="true"

                                                                                onClick={() =>
                                                                                    this.editLearning(props.original)
                                                                                }
                                                                                // onClick={() =>
                                                                                //     this.editLearningFirst(props.original)
                                                                                // }
                                                                                href="" data-toggle="modal" data-backdrop="static" data-keyboard="true"
                                                                            // data-target="#modal-edit-edit"
                                                                            ></i>
                                                                        </a>
                                                                    );
                                                                }
                                                            },


                                                        ]
                                                    }
                                                ]}
                                                defaultPageSize={10}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "500px"
                                                }}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    {/* Open add modules popup   */}

                    <div className="modal fade" id="modal-add-edit" tabIndex="-1" data-backdrop="static" data-keyboard="true" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-md" role="document">
                            <div className="modal-content">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={this.handleCancel}>&times;</span>
                                </button>

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        productName: '',
                                        status: 1,

                                    }}
                                    validationSchema={Yup.object().shape({
                                        //static validations
                                        productName: Yup.string().required("Product Name is required"),
                                    })}

                                    onSubmit={values => {

                                        console.log("This is values :" + JSON.stringify(values));
                                        var lcount = "level" + this.state.levelcount;
                                        console.log(lcount)
                                        console.log(values.productName + "--" + values[lcount]);
                                        console.log(lcount)
                                        let dataarray = [];

                                        if (values.level1 === undefined) {

                                            console.log("JSON", dataarray)
                                            // let dataarrayNew = [];
                                            var count = this.state.levelcount
                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count + 1,
                                                "seqProductID": values.productName,
                                                "isActive": values.status,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()
                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else if (lcount === "level") {

                                            for (let count = 1; count <= 1; count++) {
                                                //
                                                // let levlevalue = values.level + [count];
                                                let data1 = {
                                                    "parentProductID": values.productName,
                                                    "sequenceCount": count,
                                                    "seqProductID": values["level" + count],
                                                    "isActive": values.status,
                                                    "createdBy": localStorage.getItem('userid-Token'),
                                                }
                                                dataarray.push(data1);
                                            }
                                            console.log("JSON", dataarray)
                                            // let dataarrayNew = [];
                                            let count = this.state.levelcount
                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count + 1,
                                                "seqProductID": values.productName,
                                                "isActive": values.status,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()
                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else if (lcount !== "level") {

                                            let count1 = 1;
                                            let count2 = this.state.levelcount;
                                            for (let count = 1; count <= this.state.levelcount; count++) {
                                                //
                                                // let levlevalue = values.level + [count];
                                                let data1 = {
                                                    "parentProductID": values.productName,
                                                    "sequenceCount": count1,
                                                    "seqProductID": values["level" + count],
                                                    "isActive": values.status,
                                                    "createdBy": localStorage.getItem('userid-Token'),
                                                }
                                                if (data1.seqProductID === undefined) {
                                                    count1 = count1 - 1
                                                    count2 = count2 - 1
                                                }
                                                count1 = count1 + 1
                                                dataarray.push(data1);
                                                console.log("count2", count2)
                                            }
                                            console.log("JSON", dataarray)
                                            dataarray = dataarray.filter(function (element) {

                                                return element.seqProductID !== undefined;
                                            });

                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count2 + 1,
                                                "seqProductID": values.productName,
                                                "isActive": values.status,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()

                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else {
                                            warning("Please provide a valid parameter ", {
                                                ...warningNotification,
                                                container: "top-center"
                                            })
                                        }
                                    }

                                    }
                                    render={({ errors, touched, handleSubmit, handleChange, values, ...formikProps }) => (
                                        <React.Fragment>
                                            <Form onSubmit={handleSubmit}>
                                                <section className="AddLearningPath">
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                <div className="product-add">
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col-md-10 col-sm-10 col-xs-8">
                                                                                <label>
                                                                                    Product Name{" "}
                                                                                    <span className="validation"> *</span>
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="productName"
                                                                                    id="productName"
                                                                                    onChange={handleChange}
                                                                                    className={
                                                                                        "form-control selectLPboxadd  " +
                                                                                        (errors.productType && touched.productType
                                                                                            ? " is-invalid"
                                                                                            : "")
                                                                                    }
                                                                                >
                                                                                    <option value="">--Select--</option>
                                                                                    {this.props.learningPathProductData.map(team => (
                                                                                        <option
                                                                                            key={team.productID}
                                                                                            value={team.productID}
                                                                                        >
                                                                                            {team.productName}
                                                                                        </option>
                                                                                    ))}

                                                                                </Field>

                                                                                <ErrorMessage
                                                                                    name="productName"
                                                                                    className="validation "
                                                                                    component="div"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                                                                <h4 className="learningTitle">Select Level</h4>
                                                                            </div>
                                                                            {this.state.shareholders.map((shareholder, idx) => (
                                                                                <div className="shareholder">
                                                                                    <div className="col-md-10 col-sm-10 col-xs-8">
                                                                                        <label>
                                                                                            Level {++idx}{" "}
                                                                                            <span className="validation"> *</span>
                                                                                        </label>

                                                                                        <Field
                                                                                            as="select"
                                                                                            name={"level" + idx}
                                                                                            id={"level" + idx}
                                                                                            onChange={handleChange}
                                                                                            className="form-control selectLPbox"
                                                                                        >
                                                                                            <option value="">--Select--</option>
                                                                                            {this.state.listofproducts != null ? this.state.listofproducts.map(team => (

                                                                                                <option
                                                                                                    key={team.productID}
                                                                                                    value={team.productID}
                                                                                                >
                                                                                                    {team.productName}
                                                                                                </option>
                                                                                            )) : ""}

                                                                                        </Field>


                                                                                    </div>
                                                                                    <div className="col-md-2 col-sm-2 col-xs-4">
                                                                                        <button type="button" className="btn-8 ml-5 mr-5 mt-10 add_addselectbox" onClick={this.handleAddShareholder(idx)}><i className="fa fa-plus" aria-hidden="true"></i></button>

                                                                                        <button type="button" className="btn-8 ml-5 mr-5 mt-50" onClick={this.handleRemoveShareholder(idx)}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">

                                                                        <button
                                                                            type="submit"
                                                                            className="btn-4 pull-right"
                                                                            onClick={async () => {
                                                                                const curErrors = await formikProps.validateForm();
                                                                                const curErrorsKeys = Object.keys(
                                                                                    curErrors
                                                                                );

                                                                                if (curErrorsKeys.length) {

                                                                                    const el = document.getElementById(
                                                                                        curErrorsKeys[0]
                                                                                    );

                                                                                    if (el) el.focus();
                                                                                }
                                                                            }}
                                                                        >
                                                                            Submit
                                                                        </button>


                                                                        <button
                                                                            type="button"
                                                                            className="btn-4 mr-20 pull-right"
                                                                            onClick={this.handleCancel}
                                                                        >
                                                                            Cancel
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </section>
                                            </Form>
                                        </React.Fragment>
                                    )}
                                />

                            </div>
                        </div>
                    </div>



                    {/* Open edit modules popup   */}
                    <div className="modal fade" id="modal-edit-edit" tabIndex="-1" data-backdrop="static" data-keyboard="true" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-md" role="document">
                            <div className="modal-content">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={this.handleCancel}>&times;</span>
                                </button>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        productName: this.state.productName,
                                        name: this.state.name,
                                        //status: 1,

                                    }}
                                    validationSchema={Yup.object().shape({
                                        //static validations
                                        productName: Yup.string().required("Product Name is required"),
                                    })}

                                    onSubmit={values => {
                                        // console.log("This is values :" + JSON.stringify(values));
                                        // var lcount = "level" + this.state.levelcount;
                                        // console.log(lcount)
                                        // console.log(values.productName + "--" + values[lcount]);

                                        // if (lcount == "level" + "") {
                                        //     //lcount = values.level1
                                        //     lcount = values.level1;

                                        // }
                                        // console.log(lcount)
                                        // let dataarray = [];
                                        // if (values.productName == values.level1 && this.state.levelcount == "") {
                                        //     let data1 = {
                                        //         "parentProductID": values.productName,
                                        //         "sequenceCount": 1,
                                        //         "seqProductID": values.level1,
                                        //         "isActive": 1,

                                        //     }
                                        //     dataarray.push(data1);

                                        //     console.log("JSON EDIT", JSON.stringify(dataarray))
                                        //     ProductService.updateLearningPath(
                                        //         dataarray,
                                        //         cb => {

                                        //             //console.log("CB", cb.status);
                                        //             if (cb.status === "success") {
                                        //                 //console.log("CB.STATUS", cb.status);
                                        //                 success(
                                        //                     "Learning path updated successfully!",
                                        //                     successNotification
                                        //                 );
                                        //                 window.$("#modal-edit-edit").modal('hide');

                                        //                this.props.fetchLearningPath()
                                        //                 //this.props.history.push("/modules-list", {product : this.props});

                                        //                 //this.props.addProduct();
                                        //             } else {
                                        //                 error(cb.reasonText, {
                                        //                     ...errorNotification,
                                        //                     container: "top-center"
                                        //                 });
                                        //             }
                                        //         },
                                        //         err => console.log("Error", err)
                                        //     );
                                        //     console.log("JSON", dataarray)
                                        // }
                                        // else if (values.productName == values[lcount]) {
                                        //     //console.log("This is level count :" + this.state.levelcount);

                                        //     for (var count = 1; count <= this.state.levelcount; count++) {
                                        //         //
                                        //         let levlevalue = values.level + [count];
                                        //         let data1 = {
                                        //             "parentProductID": values.productName,
                                        //             "sequenceCount": count,
                                        //             "seqProductID": values["level" + count],
                                        //             "isActive": 1,

                                        //         }
                                        //         dataarray.push(data1);
                                        //         //
                                        //     }
                                        //     console.log("JSON", dataarray)
                                        //     console.log("JSON EDIT", JSON.stringify(dataarray))
                                        //     ProductService.updateLearningPath(
                                        //         dataarray,
                                        //         cb => {

                                        //             //console.log("CB", cb.status);
                                        //             if (cb.status === "success") {
                                        //                 //console.log("CB.STATUS", cb.status);
                                        //                 success(
                                        //                     "Learning path updated successfully!",
                                        //                     successNotification
                                        //                 );
                                        //                 window.$("#modal-edit-edit").modal('hide');
                                        //                 window.location.reload();
                                        //                 //this.props.history.push("/modules-list", {product : this.props});

                                        //                 //this.props.addProduct();
                                        //             } else {
                                        //                 error(cb.reasonText, {
                                        //                     ...errorNotification,
                                        //                     container: "top-center"
                                        //                 });
                                        //             }
                                        //         },
                                        //         err => console.log("Error", err)
                                        //     );
                                        // }
                                        // else {
                                        //     warning("Select product name as last level .", {
                                        //         ...warningNotification,
                                        //         container: "top-center"
                                        //     })
                                        // }


                                        console.log("This is values :" + JSON.stringify(values));
                                        var lcount = "level" + this.state.levelcount;
                                        console.log(lcount)
                                        console.log(values.productName + "--" + values[lcount]);
                                        console.log(lcount)
                                        let dataarray = [];

                                        if (values.level1 === undefined) {

                                            console.log("JSON", dataarray)
                                            // let dataarrayNew = [];
                                            var count = this.state.levelcount
                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count + 1,
                                                "seqProductID": values.productName,
                                                "isActive": 1,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()

                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else if (lcount === "level") {

                                            for (let count = 1; count <= 1; count++) {
                                                //
                                                // let levlevalue = values.level + [count];
                                                let data1 = {
                                                    "parentProductID": values.productName,
                                                    "sequenceCount": count,
                                                    "seqProductID": values["level" + count],
                                                    "isActive": 1,
                                                    "createdBy": localStorage.getItem('userid-Token'),
                                                }
                                                dataarray.push(data1);
                                            }
                                            console.log("JSON", dataarray)
                                            // let dataarrayNew = [];
                                            let count = this.state.levelcount
                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count + 1,
                                                "seqProductID": values.productName,
                                                "isActive": 1,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()

                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else if (lcount !== "level") {


                                            let count1 = 1;
                                            let count2 = this.state.levelcount;

                                            for (let count = 1; count <= this.state.levelcount; count++) {
                                                //
                                                // let levlevalue = values.level + [count];

                                                let data1 = {
                                                    "parentProductID": values.productName,
                                                    "sequenceCount": count1,
                                                    "seqProductID": values["level" + count],
                                                    "isActive": 1,
                                                    "createdBy": localStorage.getItem('userid-Token'),
                                                }

                                                if (data1.seqProductID === undefined) {
                                                    count1 = count1 - 1
                                                    count2 = count2 - 1
                                                }
                                                count1 = count1 + 1
                                                dataarray.push(data1);
                                                console.log("count2", count2)


                                            }

                                            console.log("levelcount", this.state.levelcount)
                                            dataarray = dataarray.filter(function (element) {

                                                return element.seqProductID !== undefined;
                                            });
                                            console.log("levelcount", this.state.levelcount)
                                            console.log("JSON", dataarray)

                                            console.log("dataarray", dataarray)


                                            let dataNew = {
                                                "parentProductID": values.productName,
                                                "sequenceCount": count2 + 1,
                                                "seqProductID": values.productName,
                                                "isActive": 1,
                                                "createdBy": localStorage.getItem('userid-Token'),
                                            }
                                            dataarray.push(dataNew);
                                            console.log(dataNew)


                                            ProductService.addLearningPath(
                                                dataarray,
                                                cb => {

                                                    if (cb.status === "success") {
                                                        success(

                                                            "Learning path added successfully!",
                                                            successNotification
                                                        );
                                                        window.$("#modal-add-edit").modal('hide');
                                                        window.$("#modal-edit-edit").modal('hide');
                                                        // window.location.reload();
                                                        this.props.fetchLearningPath()
                                                    } else {
                                                        warning(cb.reasonText, {
                                                            ...warningNotification,
                                                            container: "top-center"
                                                        })
                                                    }
                                                },
                                                err => console.log("Error", err)
                                            );
                                        }
                                        else {
                                            warning("Please provide a valid parameter ", {
                                                ...warningNotification,
                                                container: "top-center"
                                            })
                                        }
                                    }

                                    }
                                    render={({ errors, touched, handleSubmit, handleChange, values, ...formikProps }) => (
                                        <React.Fragment>
                                            <Form onSubmit={handleSubmit}>
                                                <section className="AddLearningPath">
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                                <div className="product-add">
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                                                                <h4 className="learningTitle">Current Levels
                                                                                    <a href className="info-bar"> <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                                                        <span>
                                                                                            {this.state.ExistingProduct}
                                                                                        </span>
                                                                                    </a>
                                                                                </h4>

                                                                            </div>
                                                                            <div className="col-md-10 col-sm-10 col-xs-8">
                                                                                <label>
                                                                                    Product Name{" "}
                                                                                    <span className="validation"> *</span>
                                                                                </label>
                                                                                <Field
                                                                                    as="select"
                                                                                    name="productName"
                                                                                    id="productName"
                                                                                    disabled={true}
                                                                                    onChange={handleChange}
                                                                                    className={
                                                                                        "form-control selectLPboxedit" +
                                                                                        (errors.productType && touched.productType
                                                                                            ? " is-invalid"
                                                                                            : "")
                                                                                    }
                                                                                >
                                                                                    <option value="">--Select--</option>
                                                                                    {this.props.learningPathProductData.map(team => (
                                                                                        <option
                                                                                            key={team.productID}
                                                                                            value={team.productID}
                                                                                        >
                                                                                            {team.productName}
                                                                                        </option>
                                                                                    ))}

                                                                                </Field>

                                                                                <ErrorMessage
                                                                                    name="productName"
                                                                                    className="validation "
                                                                                    component="div"
                                                                                />
                                                                            </div>


                                                                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-10">
                                                                                <h4 className="learningTitle">Update Levels</h4>
                                                                            </div>
                                                                            {this.state.shareholders.map((shareholder, idx) => (
                                                                                <div className="shareholder">
                                                                                    <div className="col-md-10 col-sm-10 col-xs-8">
                                                                                        <label>
                                                                                            Level {++idx}{" "}
                                                                                            <span className="validation"> *</span>
                                                                                        </label>

                                                                                        <Field
                                                                                            as="select"
                                                                                            name={"level" + idx}
                                                                                            id={"level" + idx}
                                                                                            onChange={handleChange}
                                                                                            className="form-control selectLPbox"
                                                                                        >
                                                                                            <option value="">--Select--</option>
                                                                                            {this.props.learningPathProductData.map(team => (
                                                                                                team.productID !== this.state.editlearningpathid &&
                                                                                                <option
                                                                                                    key={team.productID}
                                                                                                    value={team.productID}
                                                                                                >
                                                                                                    {team.productName}
                                                                                                </option>
                                                                                            ))}
                                                                                        </Field>


                                                                                    </div>
                                                                                    <div className="col-md-2 col-sm-2 col-xs-4">
                                                                                        <button type="button" className="btn-8 ml-5 mr-5 mt-10 edit_addselectbox" onClick={this.handleAddShareholder(idx)}><i className="fa fa-plus" aria-hidden="true"></i></button>

                                                                                        <button type="button" className="btn-8 ml-5 mr-5 mt-50" onClick={this.handleRemoveShareholder(idx)}><i className="fa fa-minus" aria-hidden="true"></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            ))}


                                                                        </div>
                                                                    </div>


                                                                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-20">

                                                                        <button
                                                                            type="submit"
                                                                            className="btn-4 pull-right"
                                                                            onClick={async () => {
                                                                                const curErrors = await formikProps.validateForm();
                                                                                const curErrorsKeys = Object.keys(
                                                                                    curErrors
                                                                                );

                                                                                if (curErrorsKeys.length) {

                                                                                    const el = document.getElementById(
                                                                                        curErrorsKeys[0]
                                                                                    );

                                                                                    if (el) el.focus();
                                                                                }
                                                                            }}
                                                                        >
                                                                            Submit
                                                                        </button>


                                                                        <button
                                                                            type="button"
                                                                            className="btn-4 mr-20 pull-right"
                                                                            onClick={this.handleCancel}
                                                                        >
                                                                            Cancel
                                                                        </button>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </section>
                                            </Form>
                                        </React.Fragment>
                                    )}
                                />

                            </div>
                        </div>
                    </div>


                </section>
                <Finxfooter />
            </React.Fragment >
        );
    }
}

export default LearningPathLandingPage;
