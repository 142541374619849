import React, { Component } from "react";
import ReactExport from "react-data-export";
import XLSX from "xlsx";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import ReactTable from "react-table";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import {
  success,
  successNotification,
  errorNotification,
  error,
  warning,
  warningNotification,
} from "../notification/notifications";
import bulktemp from "../../assets/pdf/Bulk Job Upload Template.xlsx";
import corp from "../../assets/pdf/corporate-master.xls";
import role from "../../assets/pdf/Role-id-master.xls";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import AdminHeader from "../dashboardheader/adminheader";
import { FileDrop } from "react-file-drop";
import { ExcelService } from "../Excel/excel";
import * as moment from "moment";
import MD from "../../assets/pdf/cityStateMaster.xlsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const dataSet1 = [
  {
    jobTitle: "Watchman",
    jobDesc: "Watchman",
    corporate: "AMC",
    stateId: "2",
    cityId: "25",
    suburbId: "10",
    pincode: "963852",
    experience: 5.2,
    ctc: 12.5,
    benefit: "lunch",
    expiryDate: "12/5/2025",
    entryDate: "30/10/2022",
    roleid: 5,
    corpId: 3,
    jobType: "Watchman",
    isActive: 1,
  },
];
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;

  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

class adminBulkJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: {},
      data: [],
      cols: [],
      isFileUploaded: false,
      checkFileSize: false,
      totalRecords: "",
      successfullyUploaded: 0,
      faildRecords: 0,
      invalidData: [],
      checkDocumentType: false,
      checkDocumentSize: false,
      hideUI: false,
      showUploadButton: false,
    };

    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = () => {
    window.location.replace("/admin-dashboard");
  };
  handleWarning = (value) => {
    warning(value, warningNotification);
  };
  exportToCSV = () => {
    let modifiedData = [];
    let data = this.state.invalidData;
    if (data.length > 0) {
      data.forEach((element, index) => {
        var data1 = {
          "Job Id": element["jobId"],
          "Job Title": element["jobTitle"],
          "Job Desc": element["jobDesc"],
          "Job Type": element["jobType"],
          "Failure Reason": element.error !== null ? element.error[0] : "",
          // "Failure Reason": element.error[0]
        };
        modifiedData.push(data1);
      });
      let date = new Date();
      let time = new Date().toLocaleString().split(",")[1];

      let time1 = time.split(" ")[2];
      let time2 = time.split(":")[0] + "-" + time.split(":")[1] + "-" + time1;
      time2 = time2.split(" ")[1];

      let date1 = moment(date).format("DD-MM-YYYY");

      let title1 = "BulkJobReport" + time2 + "-";

      ExcelService.ExcelData(modifiedData, title1);
    } else {
      warning("No record found", warningNotification);
    }
  };

  // handleChange(e) {
  //   const files = e.target.files;
  //   if (files.length > 0) {
  //     const checkType = files[0].name.split(".");
  //     if (["xls", "xlsx", "csv"].indexOf(checkType[1]) < 0) {
  //       this.setState({
  //         checkDocumentType: true,
  //         file: files[0],
  //         isFileUploaded: false,
  //         showUploadButton: false,
  //       });
  //     } else if (files && files[0]) {
  //       this.setState({
  //         checkDocumentType: false,
  //         file: files[0],
  //         isFileUploaded: true,
  //         showUploadButton: true,
  //       });
  //     }
  //   }
  //   e.target.value = null;
  // }
  handleChange(e) {
    const files = e.target.files;
    console.log('files',files[0]);
    
    if (files.length > 0) {
      const checkType = files[0].name.split(".");
      if (["xls", "xlsx", "csv"].indexOf(checkType[1]) < 0) {
        this.setState({
          checkDocumentType: true,
          checkDocumentSize: false,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false,
        });
      } else if (files && files[0] && (files[0].size <= 2000000||files[0].size <= 2097152)) {
        this.setState({
          checkDocumentType: false,
          checkDocumentSize: false,
          file: files[0],
          isFileUploaded: true,
          showUploadButton: true,
        });
      } else {
        this.setState({
          checkDocumentType: false,
          checkDocumentSize: true,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false,
        });
      }
    }
    e.target.value = null;
  }

   handleFile=() =>{
    const reader = new FileReader(this.state.file);
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      e = "";

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
    
    

let data =  XLSX.utils.sheet_to_json(ws, { raw: false, dateNF: 'dd-mm-yyyy' })

const invalidDates = [];
console.log("data",data);




  data.map((e) => {
    if (e["entryDate"]&& !["", null, undefined].includes(e["entryDate"])) {
      let entryDt = e["entryDate"]?.split("/");
  
      if (entryDt && entryDt[2].length === 2) {
        e["entryDate"] = `${entryDt[0]}/${entryDt[1]}/20${entryDt[2]} 00:00:00`;
      } else if (entryDt && entryDt[2].length === 4) {
        e["entryDate"] = `${entryDt[0]}/${entryDt[1]}/${entryDt[2]} 00:00:00`;
      }
  
      // Validate and modify entryDate month
      let entryMonth = parseInt(entryDt[1]);
      if (entryMonth > 12) {
        invalidDates.push("Invalid");
      }
  
      // Validate and modify entryDate day
      let entryDay = parseInt(entryDt[0]);
      let entryYear = parseInt(entryDt[2]);
      let maxDaysInMonth = new Date(entryYear, entryMonth, 0).getDate();
      if (entryDay > maxDaysInMonth) {
        invalidDates.push("Invalid");
      }
    }
  
    if (e["expiryDate"]&&!["", null, undefined].includes(e["expiryDate"])) {
      let expiryDt = e["expiryDate"]?.split("/");
      if (expiryDt && expiryDt[2].length === 2) {
        e["expiryDate"] = `${expiryDt[0]}/${expiryDt[1]}/20${expiryDt[2]} 00:00:00`;
      } else if (expiryDt && expiryDt[2].length === 4) {
        e["expiryDate"] = `${expiryDt[0]}/${expiryDt[1]}/${expiryDt[2]} 00:00:00`;
      }
  
      // Validate and modify expiryDate month
      let expiryMonth = parseInt(expiryDt[1]);
      if (expiryMonth > 12) {
        
        invalidDates.push("Invalid");
      }
  
      // Validate and modify expiryDate day
      let expiryDay = parseInt(expiryDt[0]);
      let expiryYear = parseInt(expiryDt[2]);
      let maxDaysInMonth = new Date(expiryYear, expiryMonth, 0).getDate();
      if (expiryDay > maxDaysInMonth) {
        
        invalidDates.push("Invalid");
      }
    }
  });
  

console.log("Invalid dates:", invalidDates);

if(invalidDates&&invalidDates.length>0){
  warning("There are Invalid Dates in Uploaded File",warningNotification)
  return
}





      console.log(new Date(Math.round((43902 - 25569) * 86400 * 1000)));
      /* Update state */
      // this.setState({ loading: true });
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        LoginService.TokenUser((res) => {
          if (res.status === "success") {
            let flag = true;
            localStorage.setItem("jwt-token", res.responseObject);
            let head = [
              "jobId",
              "jobTitle",
              "jobDesc",
              "corporate",
              "stateId",
              "cityId",
              "suburbId",
              "pincode",
              // "experience",
              "experienceID",
              "ctc",
              "toCtc",
              "benefit",
              "expiryDate",
              "entryDate",
              "roleid",
              "corpId",
              "jobType",
              "isActive",
              "primeryskills",
              "seconderyskills",
              "jobCategoryID"
            ];

            console.log("head:: ", head);

            if (data.length > 0) {
              var keys = Object.keys(data[0]);
              console.log("keys:: ", keys);
              keys.some((item) => {
                if (head.includes(item)) {
                  console.log("false");
                } else {
                  console.log("true");
                  flag = false;
                }
              });
              console.log("flag", flag);

              if (flag === true) {
                let dataList = [];
                let primary = [];
                let secondary = [];
                let jobLocationMap = [];
                let jobMap = {};
                let tempObj = [];
                let primaryList = [];
                let jobTitles = [];
                let locationObj = {};
                for (let i = 0; i < data.length; i++) {
                  let temp = {};
                  let currentIndex;
                  if (jobTitles.indexOf(data[i].jobTitle) !== -1) {
                    currentIndex = jobTitles.indexOf(data[i].jobTitle);
                    const checkPrimarySkills = tempObj[
                      currentIndex
                    ].primeryskills.includes(data[i].primeryskills);
                    const checkSecondarySkills = tempObj[
                      currentIndex
                    ].seconderyskills.includes(data[i].seconderyskills);
                    if (!checkPrimarySkills) {
                      jobMap = {
                        primeryskills: tempObj[currentIndex].primeryskills.push(
                          data[i].primeryskills
                        ),
                      };
                    }
                    if (!checkSecondarySkills) {
                      jobMap = {
                        seconderyskills: tempObj[
                          currentIndex
                        ].seconderyskills.push(data[i].seconderyskills),
                      };
                    }
                    locationObj = {
                      stateId:
                        data[i].stateId !== undefined ? data[i].stateId : 0,
                      cityId: data[i].cityId !== undefined ? data[i].cityId : 0,
                      suburbId:
                        data[i].suburbId !== undefined ? data[i].suburbId : 0,
                      locMapid: 0,
                    };
                    let jobLocationExists = tempObj[currentIndex].jobLocationMap.some(obj => obj.stateId === locationObj.stateId 
                      && obj.cityId === locationObj.cityId
                      && obj.suburbId === locationObj.suburbId
                      && obj.locMapid === locationObj.locMapid
                      );
                      if(!jobLocationExists) {
                        jobMap = {
                          jobLocationMap: tempObj[currentIndex].jobLocationMap.push(
                            locationObj
                          ),
                        };
                      }
                  
                    console.log('JobLocation IF:',locationObj);
                  } else {
                    // let expiryDate1 = moment(data[i].expiryDate).format("DD/MM/YYYY");
                    // let entryDate1 = moment(data[i].entryDate).format("DD/MM/YYYY");
                    // console.log('expiryDate1',expiryDate1);
                    // console.log('entryDate1',entryDate1);
                    // moment(todaydate).format("YYYY-MM-DD"),
                    let dateExp = data[i].expiryDate
                      ? data[i].expiryDate.slice(0, 10).split("/")
                      : "";
                    // console.log('dateExp',dateExp);
                    let dateExp1 =
                      dateExp[1] + "/" + dateExp[0] + "/" + dateExp[2];
                    // console.log('dateExp1',dateExp1);
                    let expiryDate1 = moment(dateExp1).format("DD/MM/YYYY");
                    // console.log('expiryDate1',expiryDate1);
                    let dateEnt = data[i].entryDate
                      ? data[i].entryDate.slice(0, 10).split("/")
                      : "";
                    // console.log('dateEnt',dateEnt);
                    let dateEnt1 =
                      dateEnt[1] + "/" + dateEnt[0] + "/" + dateEnt[2];
                    // console.log('dateEnt1',dateEnt1);
                    let entryDate1 = moment(dateEnt1).format("DD/MM/YYYY");
                    // console.log('entryDate1',entryDate1);

                    locationObj = {
                      stateId:
                        data[i].stateId !== undefined ? data[i].stateId : 0,
                      cityId: data[i].cityId !== undefined ? data[i].cityId : 0,
                      suburbId:
                        data[i].suburbId !== undefined ? data[i].suburbId : 0,
                      locMapid: 0,
                    };
                    jobMap = {
                      jobId: data[i].jobId !== undefined ? data[i].jobId : "",
                      jobTitle:
                        data[i].jobTitle !== undefined ? data[i].jobTitle : "",
                      jobDesc:
                        data[i].jobDesc !== undefined ? data[i].jobDesc : "",
                      primeryskills:
                        data[i].primeryskills !== undefined
                          ? [data[i].primeryskills]
                          : [],
                      seconderyskills:
                        data[i].seconderyskills !== undefined
                          ? [data[i].seconderyskills]
                          : [],
                      jobLocationMap:
                        locationObj !== undefined ? [locationObj] : [],
                      experienceID:
                        data[i].experienceID !== undefined
                          ? data[i].experienceID
                          : 0.0,
                      ctc: data[i].ctc !== undefined ? data[i].ctc : 0.0,
                      toCtc: data[i].toCtc !== undefined ? data[i].toCtc : 0.0,
                      pincode:
                        data[i].pincode !== undefined ? data[i].pincode : "",
                      // eligibleScore:data[i].eligibleScore || "",
                      benefit:
                        data[i].benefit !== undefined ? data[i].benefit : "",
                      // expiryDate:expiryDate1,
                      // entryDate:entryDate1,
                      expiryDate:
                        data[i].expiryDate !== undefined &&
                        data[i].expiryDate !== "Invalid date"
                          ? data[i].expiryDate
                          // ? data[i].expiryDate.slice(0,10)
                          : "",
                      entryDate:
                        data[i].entryDate !== undefined &&
                        data[i].entryDate !== "Invalid date"
                          ? data[i].entryDate
                          // ? data[i].entryDate.slice(0,10)
                          : "",
                      roleid: data[i].roleid !== undefined ? data[i].roleid : 0,
                      corpId: data[i].corpId !== undefined ? data[i].corpId : 0,
                      jobType:
                        data[i].jobType !== undefined ? data[i].jobType : "",
                      isActive:
                        data[i].isActive !== undefined ? data[i].isActive : 0,
                      jobCategoryID: data[i].jobCategoryID !== undefined ? data[i].jobCategoryID : 0,
                    };
                    //console.log('Final Values ',jobMap.jobLocationMap);
                    //jobMap.jobLocationMap = [...new Set(jobMap.jobLocationMap.map(obj => JSON.stringify(obj)))].map(str => JSON.parse(str));
                    //console.log('JobMap',jobMap);
                    tempObj.push(jobMap);
                    jobTitles.push(data[i].jobTitle);
                  }

                  // console.log("data[i]", data[i]);
                  // console.log("data[i].jobTitle", data[i].jobTitle);
                  // console.log('data[i].jobTitle',data[i+1].jobTitle === data[i].jobTitle);
                  // console.log('data[i].jobTitle', [data[i+1].jobTitle].includes(data[i].jobTitle));
                  // console.log(
                  //   "data[i].jobTitle",
                  //   data[i + 1].jobTitle.toString().toUpperCase() ===
                  //     data[i].jobTitle.toString().toUpperCase()
                  // );

                  // if (
                  //   data[i + 1].jobTitle.toString().toUpperCase() ===
                  //   data[i].jobTitle.toString().toUpperCase()
                  // ) {
                  //   primary.push(
                  //     data[i].primeryskills,
                  //     data[i + 1].primeryskills
                  //   );

                  //   secondary.push(
                  //     data[i].seconderyskills,
                  //     data[i + 1].seconderyskills
                  //   );

                  //   console.log("primary", primary);
                  //   console.log("secondary", secondary);
                  //   // let primary1 = Array.from(new Set(primary));
                  //   // let secondary1 = Array.from(new Set(secondary));
                  //   // console.log("primary1", primary1);
                  //   // console.log("secondary1", secondary1);
                  //   var primary1 = [];
                  //   var secondary1 = [];
                  //   primary1 = primary.filter(function (elem, pos) {
                  //     return primary.indexOf(elem) == pos;
                  //   });
                  //   secondary1 = secondary.filter(function (elem, pos) {
                  //     return secondary.indexOf(elem) == pos;
                  //   });
                  //   console.log("primary1", primary1);
                  //   console.log("secondary1", secondary1);

                  //   const p1 = [...new Set(primary)];
                  //   console.log("p1", p1);
                  //   const s1 = [...new Set(secondary)];
                  //   console.log("s1", s1);
                  //   jobMap = {
                  //     stateId: data[i].stateId,
                  //     cityId: data[i].cityId,
                  //     suburbId: data[i].suburbId,
                  //     locMapid: 0,
                  //   };
                  //   console.log("if jobMap", jobMap);
                  //   jobLocationMap.push(jobMap);
                  //   console.log("if jobLocationMap", jobLocationMap);

                  //   temp = {
                  //     jobId: data[i].jobId !== undefined ? data[i].jobId : "",
                  //     jobTitle:
                  //       data[i].jobTitle !== undefined ? data[i].jobTitle : "",
                  //     jobDesc:
                  //       data[i].jobDesc !== undefined ? data[i].jobDesc : "",
                  //     corporate:
                  //       data[i].corporate !== undefined
                  //         ? data[i].corporate
                  //         : "",
                  //     stateId:
                  //       data[i].stateId !== undefined ? data[i].stateId : 0,
                  //     cityId: data[i].cityId !== undefined ? data[i].cityId : 0,
                  //     suburbId:
                  //       data[i].suburbId !== undefined ? data[i].suburbId : 0,
                  //     pincode:
                  //       data[i].pincode !== undefined ? data[i].pincode : "",
                  //     experience:
                  //       data[i].experience !== undefined
                  //         ? data[i].experience
                  //         : 0.0,
                  //     ctc: data[i].ctc !== undefined ? data[i].ctc : 0.0,
                  //     benefit:
                  //       data[i].benefit !== undefined ? data[i].benefit : "",
                  //     expiryDate:
                  //       data[i].expiryDate !== undefined &&
                  //       data[i].expiryDate !== "Invalid date"
                  //         ? data[i].expiryDate
                  //         : "",
                  //     entryDate:
                  //       data[i].entryDate !== undefined &&
                  //       data[i].entryDate !== "Invalid date"
                  //         ? data[i].entryDate
                  //         : "",
                  //     roleid: data[i].roleid !== undefined ? data[i].roleid : 0,
                  //     corpId: data[i].corpId !== undefined ? data[i].corpId : 0,
                  //     jobType:
                  //       data[i].jobType !== undefined ? data[i].jobType : "",
                  //     isActive:
                  //       data[i].isActive !== undefined
                  //         ? data[i + 1].isActive
                  //         : "",
                  //     primeryskills: primary1,
                  //     seconderyskills: secondary1,
                  //   };
                  //   console.log("if temp", temp);
                  // } else {
                  //   jobMap = {
                  //     stateId: data[i].stateId,
                  //     cityId: data[i].cityId,
                  //     suburbId: data[i].suburbId,
                  //     locMapid: 0,
                  //   };
                  //   console.log("else jobMap", jobMap);
                  //   jobLocationMap.push(jobMap);
                  //   console.log("else jobLocationMap", jobLocationMap);

                  //   temp = {
                  //     jobId:
                  //       data[i + 1].jobId !== undefined
                  //         ? data[i + 1].jobId
                  //         : "",
                  //     jobTitle:
                  //       data[i + 1].jobTitle !== undefined
                  //         ? data[i + 1].jobTitle
                  //         : "",
                  //     jobDesc:
                  //       data[i + 1].jobDesc !== undefined
                  //         ? data[i + 1].jobDesc
                  //         : "",
                  //     corporate:
                  //       data[i + 1].corporate !== undefined
                  //         ? data[i + 1].corporate
                  //         : "",
                  //     stateId:
                  //       data[i + 1].stateId !== undefined
                  //         ? data[i + 1].stateId
                  //         : 0,
                  //     cityId:
                  //       data[i + 1].cityId !== undefined
                  //         ? data[i + 1].cityId
                  //         : 0,
                  //     suburbId:
                  //       data[i + 1].suburbId !== undefined
                  //         ? data[i + 1].suburbId
                  //         : 0,
                  //     pincode:
                  //       data[i + 1].pincode !== undefined
                  //         ? data[i + 1].pincode
                  //         : "",
                  //     experience:
                  //       data[i + 1].experience !== undefined
                  //         ? data[i + 1].experience
                  //         : 0.0,
                  //     ctc:
                  //       data[i + 1].ctc !== undefined ? data[i + 1].ctc : 0.0,
                  //     benefit:
                  //       data[i + 1].benefit !== undefined
                  //         ? data[i + 1].benefit
                  //         : "",
                  //     expiryDate:
                  //       data[i + 1].expiryDate !== undefined &&
                  //       data[i + 1].expiryDate !== "Invalid date"
                  //         ? data[i + 1].expiryDate
                  //         : "",
                  //     entryDate:
                  //       data[i + 1].entryDate !== undefined &&
                  //       data[i + 1].entryDate !== "Invalid date"
                  //         ? data[i + 1].entryDate
                  //         : "",
                  //     roleid:
                  //       data[i + 1].roleid !== undefined
                  //         ? data[i + 1].roleid
                  //         : 0,
                  //     corpId:
                  //       data[i + 1].corpId !== undefined
                  //         ? data[i + 1].corpId
                  //         : 0,
                  //     jobType:
                  //       data[i + 1].jobType !== undefined
                  //         ? data[i + 1].jobType
                  //         : "",
                  //     isActive:
                  //       data[i + 1].isActive !== undefined
                  //         ? data[i + 1].isActive
                  //         : "",
                  //     primeryskills: [data[i + 1].primeryskills],
                  //     seconderyskills: [data[i + 1].seconderyskills],
                  //   };
                  //   console.log("else temp", temp);
                  // }
                  // dataList.push(temp);
                  // dataList.push(tempObj);
                }
                console.log("jobTitle", jobTitles);
                console.log("tempObj", tempObj);
                console.log("dataList", dataList);

                console.log(keys);
                RegisterUserService.bulkJobUpload(tempObj, (res1) => {
                  console.log("res1", res1.data);

                  if (res1.data.reasonCode === "200") {
                    //debugger;
                    if (res1.data.responseObject.successCount > 0) {
                      success(
                        "Data Uploaded successfully",
                        successNotification
                      );
                    } else {
                      error(
                        "There are some records that were not uploaded successfully",
                        errorNotification
                      );
                    }

                    this.setState({ loading: false });
                    this.setState({
                      hideUI: true,
                      file: {},
                      data: [],
                      cols: [],
                      faildRecords: res1.data.responseObject.failCount || 0,
                      successfullyUploaded: res1.data.responseObject
                        .successCount
                        ? res1.data.responseObject.successCount
                        : 0,
                      invalidData: res1.data.responseObject.failList || [],
                      isFileUploaded: false,
                      checkFileSize: false,
                      checkDocumentType: false,
                      checkDocumentSize: false,
                      showUploadButton: false,
                      totalRecords: res1.data.totalRecords
                    });
                  } else if (res1.data.reasonCode === null) {
                    this.setState({ loading: false });
                    error("Please provide valid data.", errorNotification);
                  } else {
                    this.setState({ loading: false });

                    if (res1.data.reasonText === "Invalid Token") {
                      error("Session Expired", errorNotification);
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 5000);
                    } else {
                      this.setState({
                        successfullyUploaded: res1.data.responseObject
                          .successfullRecords
                          ? res1.data.responseObject.successfullRecords
                          : " ",
                      });

                      warning(
                        "Due to errors in records, the uploading of files has failed. Kindly check the grid for further process.",
                        warningNotification
                      );
                      this.setState({ loading: false });
                      this.setState({
                        hideUI: true,
                        file: {},
                        data: [],
                        cols: [],
                        faildRecords: res1.data.responseObject.failCount || "",
                        successfullyUploaded:
                          res1.data.responseObject.successCount,
                        invalidData: res1.data.responseObject.failList || [],
                        isFileUploaded: false,
                        checkFileSize: false,
                        checkDocumentType: false,
                        checkDocumentSize: false,
                        showUploadButton: false,
                        totalRecords: res1.data.totalRecords
                      });
                    }
                  }
                });
              } else {
                this.setState({ loading: false });
                this.setState({
                  hideUI: true,
                  invalidData: [],
                });
                warning("Please upload a valid template", warningNotification);
              }
            } else {
              this.setState({ loading: false });
              this.setState({
                invalidData: [],
              });
              warning("Please upload a valid template", warningNotification);
            }
          } else {
            this.setState({ loading: false });
            this.setState({
              invalidData: [],
            });
            warning("Please upload a valid template", warningNotification);
          }
        });
      });
      // this.setState({ totalRecords: this.state.data.length });
    };

    console.log("rabs", rABS);

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  render() {
    var alertdata = JSON.parse(localStorage.getItem("alertd"));
    var notealertdata = JSON.parse(localStorage.getItem("notealert"));
    var alertdat = JSON.parse(localStorage.getItem("alertheader"));
    return (
      <React.Fragment>
        <AdminHeader {...this.props} />
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section className="dashboard-tab bulk-summary pt-50">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <section className="create-user" id="bulkUploadNISM">
                  <div className="bg-box bulk-box">
                    <div className="row">
                      <div className="col-md-5 col-sm-5 col-xs-12">
                        <div className="scr-board scr_bulk">
                          <h4 className="bulk_head">
                            <a
                              href={MD}
                              target="_blank"
                              style={{ color: "#111", fontSize: "18px" }}
                            >
                              {" "}
                              Download Master City State Data{" "}
                              <i
                                className="fa fa-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </h4>

                          <h4
                            className="bulk_head"
                            style={{ cursor: "pointer" }}
                          >
                            Download Bulk Job Template{" "}
                            <a
                              download="Bulk Job Template.xlsx"
                              href={bulktemp}
                              // fileName = "class"
                              className="fa fa-download"
                              aria-hidden="true"
                            ></a>
                          </h4>
                          <h4
                            className="bulk_head"
                            style={{ cursor: "pointer" }}
                          >
                            Download Corporate Id Master{" "}
                            <a
                              download="Corporate Id Master.xls"
                              href={corp}
                              // fileName = "class"
                              className="fa fa-download"
                              aria-hidden="true"
                            ></a>
                          </h4>
                          <h4
                            className="bulk_head"
                            style={{ cursor: "pointer" }}
                          >
                            Download Role Id Master{" "}
                            <a
                              download="Role Id Master.xls"
                              href={role}
                              // fileName = "class"
                              className="fa fa-download"
                              aria-hidden="true"
                            ></a>
                          </h4>

                          <ExcelSheet data={dataSet1} name="Employees">
                            <ExcelColumn label="jobId" value="jobId" />
                            <ExcelColumn label="jobTitle" value="jobTitle" />
                            <ExcelColumn label="jobDesc" value="jobDesc" />
                            <ExcelColumn label="corporate" value="corporate" />
                            <ExcelColumn label="stateId" value="stateId" />
                            <ExcelColumn label="cityId" value="cityId" />
                            <ExcelColumn label="suburbId" value="suburbId" />
                            <ExcelColumn label="pincode" value="pincode" />
                            <ExcelColumn
                              label="experience"
                              value="experience"
                            />
                            <ExcelColumn label="ctc" value="ctc" />
                            <ExcelColumn label="benefit" value="benefit" />
                            <ExcelColumn
                              label="expiryDate"
                              value="expiryDate"
                            />
                            <ExcelColumn label="entryDate" value="entryDate" />
                            <ExcelColumn label="roleid" value="roleid" />
                            <ExcelColumn label="corpId" value="corpId" />
                            <ExcelColumn label="jobType" value="jobType" />
                            <ExcelColumn label="isActive" value="isActive" />
                          </ExcelSheet>

                          <div className="lock-img">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                          </div>
                          <div className="bottom-line"></div>
                          <div>
                            <h2 className="color_magento">Bulk Job Upload</h2>
                          </div>
                          <div className="scr-form">
                            {this.state.isFileUploaded ? (
                              <div>
                                <p
                                  className="text-center color_gray text-wrap-bulk-upload"
                                  id="uploadedFileName"
                                >
                                  {this.state.file.name}
                                  <br />
                                  <br />
                                </p>
                                <span className="icon btn-file btn-file3">
                                  <i
                                    className="fa fa-paperclip"
                                    style={{
                                      color: "#3a3a3a",
                                      fontSize: "18px",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                                {/* <div className="icon btn-file btn-file1">
                                  <p className="color_red">
                                    <button className="browse-text">
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                      Select File To Upload
                                    </button>
                                    <input
                                      type="file"
                                      name="file"
                                      accept={SheetJSFT}
                                      onChange={this.handleChange}
                                    />
                                  </p>
                                </div> */}
                              </div>
                            ) : (
                              <div>
                                <br />
                                <span className="icon btn-file btn-file1">
                                  <p className="color_red">
                                    <button className="browse-text">
                                      Select File To Upload
                                    </button>
                                    <input
                                      type="file"
                                      name="file"
                                      accept={SheetJSFT}
                                      onChange={(e) => this.handleChange(e)}
                                    />
                                  </p>
                                </span>
                                <span className="icon btn-file btn-file2">
                                  <i
                                    className="fa fa-paperclip"
                                    style={{
                                      color: "#3a3a3a",
                                      fontSize: "18px",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                              </div>
                              //     <div className="icon btn-file btn-file1">
                              //     <p className="color_red">
                              //     <button className="browse-text"><i
                              //         className="fa fa-paperclip"
                              //         aria-hidden="true"
                              //     ></i>
                              //  Select File To Upload</button>
                              //         <input
                              //             type="file"
                              //             name="file"
                              //             accept={SheetJSFT}
                              //             onChange={this.handleChange}
                              //         />
                              //     </p>
                              // </div>
                            )}
                            {/* {this.state.checkDocumentType ? (
                              <p className="text-center validation1">
                                Invalid File Format
                              </p>
                            ) : (
                              ""
                            )} */}
                            <br />
                            {this.state.checkDocumentType
                              ? this.handleWarning(
                                  "Invalid File Format:Supported Only .xls .xslx .csv"
                                )
                              : ""}
                            {this.state.checkDocumentSize
                              ? this.handleWarning(
                                  "File Size should not be Greater Than 2 MB"
                                )
                              : ""}
                          </div>
                          <div className="">
                            <span className="file-size-limit-bulkupload">
                              Supported Formats: .xls, .xlsx ,.csv only. <br />
                            </span>
                          </div>
                          {this.state.showUploadButton ? (
                            <button
                              type="submit"
                              onClick={this.handleFile}
                              className="btn-1"
                            >
                              Upload
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-md-7 col-sm-7 col-xs-12">
                        <div className="table-header">
                          <h3>
                            <div className="row">
                              <div className="col-md-9 col-sm-9 col-xs-10">
                                <span className="left-icon">
                                  <i className="fa fa-list" aria-hidden="true"></i>
                                </span>{" "}
                                Bulk Job Upload Summary
                              </div>
                              <div className="col-md-3 col-sm-3 col-xs-2">
                                <span className="right-icon">
                                  <a title="">
                                    <i
                                      className="fa fa-info"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </h3>
                        </div>
                        <div className="mr-20 ml-20">
                          <div className="table-responsive">
                            <table className="user-table bulkupload-tab">
                              <tr>
                                <td>
                                  <p>Total Records</p>
                                </td>
                                <td>
                                  {" "}
                                  <span className="records">
                                    {this.state.totalRecords || 0}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Successfully Uploaded </p>
                                </td>
                                <td>
                                  <span className="success">
                                    {this.state.successfullyUploaded === null
                                      ? 0
                                      : this.state.successfullyUploaded || 0}
                                  </span>{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Fail to Upload </p>
                                </td>
                                <td>
                                  <span className="fail">
                                    {this.state.faildRecords || 0}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <button
                            className="btn-5 mr-20 ml-20 mt-10 pull-right"
                            onClick={this.handleCancel}
                          >
                            <span aria-hidden="true">Back</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {this.state.invalidData.length > 0 ? (
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="table-header">
                    <h3>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <span className="left-icon">
                            <i
                              className="fa fa-question-circle-o fa-lg"
                              aria-hidden="true"
                            ></i>
                          </span>{" "}
                          Bulk Job Upload Report
                          <span className="right-icon">
                            <a title="Export to Excel">
                              <i
                                onClick={() => this.exportToCSV()}
                                className="fa fa-file-excel-o"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </h3>
                  </div>
                  {this.state.invalidData.length > 0 ? (
                    <div className="bg-box">
                      <div className="table-responsive">
                        <ReactTable
                          data={this.state.invalidData}
                          className="-striped -highlight"
                          filterable
                          defaultFilterMethod={this.filterCaseInsensitive}
                          columns={[
                            {
                              columns: [
                                // {
                                //   Header: "Job Id",
                                //   id: "jobId",
                                //   accessor: (d) => d["jobId"],
                                // },
                                {
                                  Header: "Job Title",
                                  id: "jobTitle",
                                  accessor: (d) => d["jobTitle"],
                                },
                                {
                                  Header: "Job Desc",
                                  id: "jobDesc",
                                  accessor: (d) => d["jobDesc"],
                                },
                                {
                                  Header: "Job Type",
                                  id: "jobType",
                                  accessor: (d) => d["jobType"],
                                },
                                {
                                  Header: "Expiry Date",
                                  id: "expiryDate",
                                  accessor: (d) => d["expiryDate"]?.slice(0, 10),
                                },
                                {
                                  Header: "Entry Date",
                                  id: "entryDate",
                                  accessor: (d) => d["entryDate"]?.slice(0, 10),
                                },
                                {
                                  Header: "Failure Reason",
                                  id: "reason",
                                  //accessor: d => d.error[0],
                                  accessor: (d) =>
                                    d.error !== null ? d.error : "",
                                  style: { "white-space": "unset" },
                                  Cell: (row) => (
                                    <span title={row.value}>
                                      {`${row.value}`},
                                    </span>
                                  ),
                                },
                              ],
                            },
                          ]}
                          defaultPageSize={5}
                          style={{
                            width: "100%",
                            height: "500px",
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <Finxfooter />
      </React.Fragment>
    );
  }
}

export default adminBulkJob;
