import React, { Component } from "react";
import ReactExport from "react-data-export";
import XLSX from "xlsx";
import Finxfooter from "../../containers/finxfooter/finxfooter";
import AdminHeader from "../../components/dashboardheader/adminheader";
import ReactTable from "react-table";
import { RegisterUserService } from "../../services/registration.service";
import { LoginService } from "../../services/login.service";
import {
  success,
  successNotification,
  errorNotification,
  error,
  warning,
  warningNotification
} from "../notification/notifications";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const dataSet1 = [
  {
    firstName: "Amol",
    middleName: "A",
    lastName: "Mehtre",
    emailID: "nowhere@gmail.com",
    mobileNo: "9211420540",
    gender: "M",
    panNumber: "ABCPU1234P",
    aadharNumber: "111111111111",
    dateofbirth: "14/01/1999"
  },
  {
    firstName: "Pankaja",
    middleName: "B",
    lastName: "Patil",
    emailID: "everywhere@gmail.com",
    mobileNo: "9211420540",
    gender: "F",
    panNumber: "ABCPU1234P",
    aadharNumber: "222222222222",
    dateofbirth: "26/12/1999"
  }
];
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");
const make_cols = refstr => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;

  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};
class AdminBulkUploadUser extends Component {
  constructor(props) {
    super(props);

    //console.log("props", this.props);
    // if (localStorage.getItem('login') !== null) {
    this.state = {
      file: {},
      data: [],
      cols: [],
      isFileUploaded: false,
      checkFileSize: false,
      totalRecords: "",
      successfullyUploaded: "",
      faildRecords: "",
      invalidData: [],
      checkDocumentType: false,
      hideUI: false,
      showUploadButton: false
    };
    // }
    // else {
    //   window.location.href = '/';
    // }
    this.handleFile = this.handleFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = () => {
    this.props.history.goBack('-1');
  };

  handleChange(e) {
    const files = e.target.files;
    if (files.length > 0) {
      const checkType = files[0].name.split(".");
      if (["xls", "xlsx"].indexOf(checkType[1]) < 0) {
        this.setState({
          checkDocumentType: true,
          file: files[0],
          isFileUploaded: false,
          showUploadButton: false
        });
      } else if (files && files[0]) {
        //files[0].size < 500000000 &&
        this.setState({
          checkDocumentType: false,
          file: files[0],
          isFileUploaded: true,
          showUploadButton: true
        });
      }
    }
    e.target.value = null;
  }

  handleFile() {
    const reader = new FileReader(this.state.file);
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      //
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      e = "";

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);

      /* Update state */
      this.setState({ loading: true });
      this.setState({ data: data, cols: make_cols(ws["!ref"]) }, () => {
        LoginService.TokenUser(res => {
          if (res.status === "success") {
            localStorage.setItem("jwt-token", res.responseObject);
            RegisterUserService.bulkUpload(data, res1 => {
              if (res1.data.status === "success") {
                success(
                  "Student data Uploaded successfully",
                  successNotification
                );
                this.setState({ loading: false });
                this.setState({
                  hideUI: true,
                  file: {},
                  data: [],
                  cols: [],
                  faildRecords: res1.data.responseObject.invalidRecords || "",
                  successfullyUploaded: res1.data.responseObject
                    .successfullRecords
                    ? res1.data.responseObject.successfullRecords
                    : " ",
                  invalidData:
                    res1.data.responseObject.invalidStudentData || [],
                  isFileUploaded: false,
                  checkFileSize: false,
                  checkDocumentType: false,
                  showUploadButton: false
                });
              } else {
                if (res1.data.reasonText === "Invalid Token") {
                  error("Session Expired", errorNotification);
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 5000);
                } else {
                  this.setState({
                    successfullyUploaded: res1.data.responseObject
                      .successfullRecords
                      ? res1.data.responseObject.successfullRecords
                      : " "
                  });

                  warning(
                    "Due to errors in records, the uploading of files has failed. Kindly check the grid for further process",
                    warningNotification
                  );
                  this.setState({ loading: false });
                  this.setState({
                    hideUI: true,
                    file: {},
                    data: [],
                    cols: [],
                    faildRecords: res1.data.responseObject.invalidRecords || "",
                    successfullyUploaded:
                      res1.data.responseObject.successfullRecords,
                    invalidData:
                      res1.data.responseObject.invalidStudentData || [],
                    isFileUploaded: false,
                    checkFileSize: false,
                    checkDocumentType: false,
                    showUploadButton: false
                  });
                }
              }
            });
          }
        });
      });
      this.setState({ totalRecords: this.state.data.length });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    }
  }
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : true;
  };

  render() {
    // var alertdata = JSON.parse(localStorage.getItem("alertd"));
    // var notealertdata = JSON.parse(localStorage.getItem("notealert"));
    // var alertdat = JSON.parse(localStorage.getItem("alertheader"));

    return (
      <React.Fragment>
        <AdminHeader
          //alertNotificationEvents={this.props.location.state.alerts}
          //notificationEvents={this.props.location.state.notification}
          {...this.props}
        />
        {this.state.loading && (
          <PopUpPortal
            HidePopup={this.state.loading}
            IsVisible={this.state.loading}
          />
        )}
        <section className="dashboard-tab bulk-summary pt-50">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <section className="create-user">
                  <div className="bg-box bulk-box">
                    <div className="row">
                      <div className="col-md-5 col-sm-5 col-xs-12">
                        <div className="scr-board scr_bulk">
                          {/* <h4 className="bulk_head">
                                Download Excel Template{" "}
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </h4> */}
                          <ExcelFile
                            element={
                              <h4 className="bulk_head">
                                Download Excel Template{" "}
                                <i
                                  className="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </h4>
                            }
                            filename="Download Template"
                          >
                            <ExcelSheet data={dataSet1} name="Employees">
                              <ExcelColumn
                                label="firstName"
                                value="firstName"
                              />
                              <ExcelColumn
                                label="middleName"
                                value="middleName"
                              />
                              <ExcelColumn label="lastName" value="lastName" />
                              <ExcelColumn label="emailID" value="emailID" />
                              <ExcelColumn label="mobileNo" value="mobileNo" />
                              <ExcelColumn label="gender" value="gender" />
                              <ExcelColumn
                                label="panNumber"
                                value="panNumber"
                              />
                              <ExcelColumn
                                label="aadharNumber"
                                value="aadharNumber"
                              />
                              <ExcelColumn
                                label="dateofbirth"
                                value="dateofbirth"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                          <div className="lock-img">
                            <i className="fa fa-upload" aria-hidden="true"></i>
                          </div>
                          <div className="bottom-line"></div>
                          <h2 className="color_magento">BULK UPLOAD</h2>
                          <div className="scr-form">
                            {this.state.isFileUploaded ? (
                              <div>

                                <p className="text-center color_gray text-wrap-bulk-upload">
                                  File Selected.  {this.state.file.name}
                                  {/* File Name: {this.state.file.name} */}
                                  <br />
                                  {/* Please Upload. */}
                                </p>
                                <span className="icon btn-file btn-file3">
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                              </div>

                            ) : (
                              <div>
                                {/* <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6"> */}
                                <span className="icon btn-file btn-file1">
                                  <p className="color_red">
                                    <button className="browse-text">Select File To Upload</button>
                                    <input
                                      type="file"
                                      name="file"
                                      accept={SheetJSFT}
                                      onChange={this.handleChange}
                                    />
                                  </p>
                                </span>
                                {/* </div>
                                  <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6"> */}
                                <span className="icon btn-file btn-file2">
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                  <input
                                    type="file"
                                    name="file"
                                    accept={SheetJSFT}
                                    onChange={this.handleChange}
                                  />
                                </span>
                              </div>
                              // </div>

                            )}


                            {

                              this.state.checkDocumentType ? (
                                <p className="text-center validation1">
                                  Invalid File Format
                                </p>
                              ) : (
                                ""
                              )}
                            {/* {this.state.checkFileSize ? (
                              <p className="text-center color_gray">
                                File Size is too large(Maximum File Size:5MB).
                              </p>
                            ) : (
                                ""
                              )} */}

                            {/* <span className="icon btn-file btn-file2">
                              <i
                                className="fa fa-paperclip"
                                aria-hidden="true"
                              ></i>
                              <input
                                type="file"
                                name="file"
                                accept={SheetJSFT}
                                onChange={this.handleChange}
                              />
                            </span> */}


                          </div>
                          <div className="">
                            <span className="file-size-limit-bulkupload">
                              Supported Formats: .xls, .xlsx only. <br />
                            </span>
                          </div>
                          {this.state.showUploadButton ? (
                            <button
                              type="submit"
                              onClick={this.handleFile}
                              className="btn-1"
                            // disabled={!this.state.isFileUploaded}
                            >
                              Upload
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-md-7 col-sm-7 col-xs-12">
                        <div className="table-header">
                          <h3>
                            <div className="row">
                              <div className="col-md-6 col-sm-6 col-xs-9">
                                <span className="left-icon">
                                  <i className="fa fa-list" aria-hidden="true"></i>
                                </span> Bulk Upload Summary
                              </div>
                              <div className="col-md-6 col-sm-6 col-xs-3">
                                <span className="right-icon">
                                  <a title="" href="#">
                                    <i
                                      className="fa fa-info"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </span>
                              </div>
                            </div>
                          </h3>
                          {/* <h3 className="text-center">
                            <span className="left-icon">
                              <i className="fa fa-list" aria-hidden="true"></i>
                            </span>
                            Bulk Upload Summary
                            </h3>
                          <span className="right-icon">
                            <a href="">
                              <i
                                className="fa fa-info"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </span> */}

                        </div>
                        <div className="mr-20 ml-20">
                          <div className="table-responsive">
                            <table className="user-table bulkupload-tab">
                              <tr>
                                <td>
                                  <p>Total Records</p>
                                </td>
                                <td>
                                  {" "}
                                  <span className="records">
                                    {this.state.totalRecords || 0}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Successfully Uploaded </p>
                                </td>
                                <td>
                                  <span className="success">
                                    {this.state.successfullyUploaded || 0}
                                  </span>{" "}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p>Fail to Upload </p>
                                </td>
                                <td>
                                  <span className="fail">
                                    {this.state.faildRecords || 0}
                                  </span>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div className="row">
                          <button className="btn-5 mr-20 ml-20 mt-10 pull-right" onClick={this.handleCancel}><span aria-hidden="true">Back</span></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {this.state.hideUI ? (
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="table-header">
                    <h3>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <span className="left-icon">
                            <i
                              className="fa fa-question-circle-o fa-lg"
                              aria-hidden="true"
                            ></i>
                          </span>{" "}
                          Failed Records Details
                        </div>
                      </div>
                    </h3>
                  </div>
                  {this.state.invalidData.length > 0 ? (
                    <div className="bg-box">
                      <div className="table-responsive">
                        <ReactTable
                          data={this.state.invalidData}
                          className="-striped -highlight"
                          filterable
                          defaultFilterMethod={this.filterCaseInsensitive}
                          columns={[
                            {
                              columns: [
                                {
                                  Header: "Email ID",
                                  id: "email",
                                  accessor: d => d.emailID
                                },
                                {
                                  Header: "Student Name",
                                  id: "name",
                                  accessor: d =>
                                    (d.firstName !== null ? d.firstName : "") +
                                    " " +
                                    (d.lastName !== null ? d.lastName : "")
                                },
                                {
                                  Header: "Mobile Number",
                                  id: "MobileNo",
                                  accessor: d => d.mobileNo
                                },
                                {
                                  Header: "Gender",
                                  id: "gender",
                                  accessor: d => d.gender
                                },
                                {
                                  Header: "Birth Date",
                                  id: "dateofbirth",
                                  accessor: d => d.dateofbirth
                                },
                                {
                                  Header: "Pan Number",
                                  id: "panNumber",
                                  accessor: d => d.panNumber
                                },
                                {
                                  Header: "Aadhar Number",
                                  id: "aadharNumber",
                                  accessor: d => d.aadharNumber
                                },
                                {
                                  Header: "Failure Reason",
                                  id: "reason",
                                  accessor: d => d.errorCode,
                                  style: { "white-space": "unset" }
                                }
                              ]
                            }
                          ]}
                          defaultPageSize={5}
                          style={{
                            width: "100%",
                            height: "500px"
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
        <Finxfooter />
      </React.Fragment>
    );
  }
}

export default AdminBulkUploadUser;
