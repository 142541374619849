import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PathChangeDetector extends Component {
  
  listenerAdded = false;
  constructor(props) {
    super(props);
    console.log('PCD: Constructor - Props initialized');
  }

  componentDidMount() {
    console.log('PCD: Component Did Mount - Adding initial click listener');
    document.addEventListener('click', this.props.fetchDeploymentFlag());
  }

  componentDidUpdate(prevProps) {
    console.log('PCD: Component Did Update - Checking for pathname change');
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log(`PCD: Pathname changed from ${prevProps.location.pathname} to ${this.props.location.pathname}`);
      if (!this.listenerAdded) {
        console.log('PCD: Adding click listener as it was not added before');
        document.addEventListener('click', this.props.fetchDeploymentFlag());
        this.listenerAdded = true; 
      }
    }
  }

  componentWillUnmount() {
    console.log('PCD: Component Will Unmount - Removing click listener if added');
    if (this.listenerAdded) {
      document.removeEventListener('click', this.props.fetchDeploymentFlag());
      this.listenerAdded = false; 
    }
  }

  render() {
    console.log('PCD: Render - PCD rendering');
    return null;
  }
}

const PathChangeDetectorWithRouter = withRouter(PathChangeDetector);
export default PathChangeDetectorWithRouter;
