import { Global_var } from "../global/global_var";
import RestDataSource from "./restdatasource";
import { LoginService } from "./login.service";

export const StudentAssessmentService = {
  fetchStudentPrequisiteByProductID,
  getCandidateJobDetails,
  rejectApplication,
  getJobDetails,
  getJobDetailsById,
  updateJobRecomCourse,
  sendSmsEmailCandidate,
  updateJobDetails,
  createJob,
  getJobRole,
  referApplicationEmail,
  getCorporateType,
  getJobCategory,
  getSkill,
  getSuburbByCityId,
  nextStageApplication,
  getJobStatusCount,
  createQuestionnaire,
  updateQuestionnaire,
  getQuestionnaire,
  rsvpResponse,
  getCorporateDetails,
  getInterviewStage,
  updateStatus,
  getPlacementByList,
  updatePlacementBy,
  getJobHistory,
  getJobStatusHistory,
  saveStageUpdateForJob,
  saveStageUpdateForCourse,
  fetchStudentPrequisites,
  getRatingHistory,
  getExternalCommentsList,
  getFeedBackList,
  getJobLocationList,
  updateActualJoiningDate,
  saveRatingFeedBack,
  getVideoResumeRatingURLFromDocId,
  getApplicantCountHistory,
  getActiveEmployeesList
};

function fetchStudentPrequisiteByProductID(studentpartyProdID, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url =
        Global_var.BASEURL + Global_var.URL_STUDENT_ASSESSMENT_PREQUISITE;
      return new RestDataSource(url, fnError).GetOneByParam(
        studentpartyProdID,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  });
}
function fetchStudentPrequisites(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_STUDENT_ASSESSMENT_GET_PREQUISITE;
  return new RestDataSource(url, fnError).Store(data, (res) => {
      if (res != null) {
        if (res.headers["jtitoken"] != null) {
          localStorage.setItem("jti-token", res.headers.jtitoken);
        }
        fn(res.data);
      }
    }
  );
}
function getCandidateJobDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_CANDIDATE_JOB_DETAILS;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobDetailsById(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB_BY_ID;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function updateJobRecomCourse(params,data, fn, fnError) {  
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_JOB_RECOM_COURSE;
  return new RestDataSource(url, fnError).UpdateOneByParam(params,data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function rejectApplication(data, fn) {
  LoginService.TokenUser((res) => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_REJECT_APPLICATION;
    return new RestDataSource(url).Update(data, (res) => fn(res.data));
  });
}

function sendSmsEmailCandidate(user, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SMS_EMAIL_CANDIDATE;
  console.log(url);
  return new RestDataSource(url, fnError).Store(user, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateJobDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_JOB_DETAILS;
  return new RestDataSource(url, fnError).Update(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function createJob(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATE_JOB;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobRole(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB_ROLE;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function referApplicationEmail(data, fn) {
  LoginService.TokenUser((res) => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_REFER_APPLICATION;
    return new RestDataSource(url).Store(data, (res) => fn(res.data));
  });
}
function getCorporateType(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_CORPORATE_TYPE;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobCategory(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB_CATEGORY;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getActiveEmployeesList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_ACTIVE_EMPLOYEE_LIST;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
// function getSkill(data, fn, fnError) {
//   var url = Global_var.BASEURL + Global_var.URL_GET_SKILL;
//   return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
//     if (res != null) {
//       if (res.headers["jtitoken"] != null) {
//         localStorage.setItem("jti-token", res.headers.jtitoken);
//       }
//       fn(res.data);
//     }
//   });
// }
function getSkill(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SKILL;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getSuburbByCityId(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_SUBURB;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function nextStageApplication(data, fn) {
  LoginService.TokenUser((res) => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_NEXT_STAGE_APPLICATION;
    return new RestDataSource(url).Update(data, (res) => fn(res.data));
  });
}

function getJobStatusCount(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_STAGE_COUNT;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function createQuestionnaire(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_CREATE_QUESTIONNAIRE;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function updateQuestionnaire(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_QUESTIONNAIRE;
  return new RestDataSource(url, fnError).Update(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function getQuestionnaire(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_QUESTIONNAIRE;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

function rsvpResponse(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RSVP_RESPONSE;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getCorporateDetails(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_COROPRATE_DETAILS;
  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getInterviewStage(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_INTERVIEWSTAGES;
  return new RestDataSource(url, fnError).GetOneByParam(data, res => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function updateStatus(data, fn) {
  LoginService.TokenUser((res) => {
    localStorage.setItem("jwt-token", res.responseObject);
    var url = Global_var.BASEURL + Global_var.URL_UPDATE_STATUS;
    return new RestDataSource(url).Update(data, (res) => fn(res.data));
  });
}
function getPlacementByList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_PLACEMENT_BY_LIST;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function updatePlacementBy(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_PLACEMENT_BY;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobHistory(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB_HISTORY;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getApplicantCountHistory(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_APPLICANT_COUNT_HISTORY;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobStatusHistory(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_JOB_STATUS_HISTORY;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getRatingHistory(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_GET_RATING_HISTORY;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getExternalCommentsList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RATING_EXTERNAL_COMMENTS_LIST ;
  return new RestDataSource(url, fnError).GetOneByParam(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function saveRatingFeedBack(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RATING_SAVE_FEEDBACK;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getFeedBackList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_RATING_FEEDBACK_LIST;
  return new RestDataSource(url, fnError).GetData(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getJobLocationList(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_JOB_LOCATION_LIST;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function updateActualJoiningDate(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_UPDATE_ACTUAL_JOINING_DATE;
  return new RestDataSource(url, fnError).Update(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function getVideoResumeRatingURLFromDocId(docID, fn, fnError) {
  LoginService.TokenUser((res) => {
    if (res.status === "success") {
      localStorage.setItem("jwt-token", res.responseObject);
      var url = Global_var.BASEURL + Global_var.URL_VIDEO_RESUME_RATING_LINK_FROM_DOCID;
        // var url = "http://192.168.71.158:8065"+Global_var.URL_VIDEO_RESUME_RATING_LINK_FROM_DOCID
      return new RestDataSource(url, fnError).GetOneByParam(
        docID,
        (res) => {
          if (res != null) {
            if (res.headers["jtitoken"] != null) {
              localStorage.setItem("jti-token", res.headers.jtitoken);
            }
            fn(res.data);
          }
        }
      );
    }
  });
}
function saveStageUpdateForJob(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_STAGE_UPDATE_JOB;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}
function saveStageUpdateForCourse(data, fn, fnError) {
  var url = Global_var.BASEURL + Global_var.URL_SAVE_STAGE_UPDATE_COURSE;
  return new RestDataSource(url, fnError).Store(data, (res) => {
    if (res != null) {
      if (res.headers["jtitoken"] != null) {
        localStorage.setItem("jti-token", res.headers.jtitoken);
      }
      fn(res.data);
    }
  });
}

