import React from "react";
import Finxfooter from "../../../containers/finxfooter/finxfooter";
// import DashboardHeader from "../../dashboardheader/dashboardheader";
// import qs from "qs";
// import axios from "axios";
import { LoginService } from "../../../services/login.service";
// import { promises } from "dns";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PasswordStrengthMeter from "./passwordStrengthMeter";
import {
  success,
  successNotification,
  // error,
  // errorNotification,
  warning,
  warningNotification
} from "../../notification/notifications";
import { PopUpPortal } from "../../../containers/popups/PopUpPortal";
import Finxheader from "../../../containers/finxheader/finxheader";

class forgotpasswordenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        token: "",
        userPassword: ""
      }
    };
  }

  handleClick = () => {
    if (this.state.formData.userPassword === this.state.confirmPassword) {

      LoginService.TokenUser(res => {
        if (res.status === "success") {
          localStorage.setItem("jwt-token", res.responseObject);
          LoginService.updateForgotPassword(
            this.state.formData,
            response => {

              //console.log(response);
              if (response.status === 'success') {

                this.props.history.push("/");
              } else {
                warning(response.reasonText, warningNotification);
              }
            },
            error => console.log(error)
          );
        }
      });
    } else {
      //console.log("Password isn't matching");
      //console.log(this.state.formData);
    }
  };

  render() {
    return (
      <Formik
        initialValues={{
          userId: "",
          userLoginName: "",
          emailId: "",
          userPassword: "",
          confirmPassword: ""
        }}
        validationSchema={Yup.object().shape({
          userPassword: Yup.string()
            .required("You must specify a User Password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{5,})/,
              "Please enter password as per password policy"
            ),
          confirmPassword: Yup.string()
            .required("You must specify a Confirm Password")
            .oneOf([Yup.ref("userPassword")], "Password does not match")
        })}
        onSubmit={fields => {
          this.setState({ loading: true });
          var userPassword = fields["userPassword"];
          var data = {
            userPassword: userPassword,
            token: this.state.formData.token
          };

          localStorage.setItem("TokenName", "jti");
          LoginService.TokenUser(res => {
            if (res.status === "success") {
              localStorage.setItem("jti-token", res.responseObject);
              localStorage.setItem("jwt-token", res.responseObject);
              LoginService.updateForgotPassword(
                data,
                response => {
                  this.setState({ loading: false });
                  if (response.status === "success") {
                    success("Password has been reset successfully.", successNotification);
                    this.props.history.push("/");
                  } else {
                    warning(response.reasonText, warningNotification);
                  }
                },
                error => console.log(error)
              );
            }
          });
        }}
        render={({ errors, touched, setFieldValue, values, handleChange }) => (
          <React.Fragment>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}

            <Finxheader />
            <Form className="form_name">
              <section className="change-password mb-120">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 mt-30"></div>
                    <div className="col-md-5 col-lg-5 col-sm-6 col-xs-12 mt-30">
                      <div className="change-pass">
                        <div className="lock-img">
                          <i className="fa fa-lock" aria-hidden="true"></i>
                        </div>
                        <h2>Forgot Password</h2>
                        <div className="bottom-line"></div>
                        <div className="pass-form">
                          <div>
                            <label>NEW PASSWORD</label>
                            <Field
                              type="password"
                              name="userPassword"
                              onChange={handleChange}
                              placeholder="Enter New Password"
                              className="form-control"
                            />
                            <span className="icon">
                              {values.userPassword && !errors.userPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </span>
                            <ErrorMessage
                              name="userPassword"
                              component="div"
                              className="validation"
                            />
                          </div>
                          <PasswordStrengthMeter
                            password={values.userPassword}
                          />
                          <div className="">
                            <label>CONFIRM PASSWORD</label>
                            <Field
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder="Enter Confirm New Password"
                              className="form-control icon"
                            />
                            <span className="icon">
                              {values.confirmPassword &&
                                !errors.confirmPassword ? (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-check-circle green"
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                              ) : (
                                <span className="">
                                  {" "}
                                  <i
                                    className="fa fa-times-circle red"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              )}
                            </span>
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="validation"
                            />
                          </div>
                        </div>
                        <button type="Submit" className="btn-4 mt-40">
                          Submit
                        </button>
                      </div>

                      <div></div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 mt-30">
                      <div className="alert alert-info">
                        <b>Password Policy:</b>
                        <p>
                          {" "}
                          Atleast 1 Character should be Capital ['A-Z']
                          {values.userPassword.match(/^(?=.*[A-Z])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          Atleast 1 Character should be Small ['a-z']
                          {values.userPassword.match(/^(?=.*[a-z])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}{" "}
                        </p>

                        <p>
                          Atleast 1 Character should be Numeric ['0-9']
                          {values.userPassword.match(/(?=.*[0-9])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          Atleast 1 Character should be Special [@#$%&*]
                          {values.userPassword.match(/(?=.*[!@#\$%\^&\*])/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>

                        <p>
                          No Space is allowed [ ]
                          {values.userPassword.match(/(?=.{5,})/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>
                        <p>
                          No Space
                          {values.userPassword.match(/(^\S*$)/) ? (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-check-circle"
                                aria-hidden="true"
                              ></i>{" "}
                            </span>
                          ) : (
                            <span className="pull-right">
                              {" "}
                              <i
                                className="fa fa-times-circle red"
                                aria-hidden="true"
                              ></i>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
            <Finxfooter />
          </React.Fragment>
        )}
      />
    );
  }

  getQueryStringParams = query => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value ? decodeURI(value.replace(/\+/g, " ")) : "";
          return params;
        }, {})
      : {};
  };

  componentDidMount() {
    this.setState(
      state =>
      (state.formData["token"] = this.getQueryStringParams(
        this.props.location.search
      ).id)
    );
  }
}

export default forgotpasswordenter;
