import React from "react";
// import socailstreaming from "../../assets/images/social-stream.png";
import ReactTable from "react-table";
import ViewEnquiry from "./viewEnquiry";

class AdminSocialStreaming extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      enquiryMessage: "",
      CreatedDate: "",
      emailID: "",
      mobileNumber: "",
      ResolvedDate: "",
      isResolved: ""
    };
  }
  handleClick = value => {
    //console.log("VALUE: ", JSON.stringify(value[0]));
    this.setState({
      fullName: value.fullName,
      enquiryMessage: value.enquiryMessage,
      CreatedDate: value.CreatedDate,
      emailID: value.emailID,
      mobileNumber: value.mobileNumber,
      ResolvedDate: value.ResolvedDate,
      isResolved: value.isResolved
    });
  };
  render() {
    //console.log("PROPS: ", this.props.studentEnquiry);
    return (
      <React.Fragment>
        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
          <h3 className="student-title">Enquiries</h3>
          <div className="social-stream">
            <div className="enquiry-form">
              <ReactTable
                data={(this.props.studentEnquiry || []).filter(
                  data => data.fullName !== null && data.enquiryMessage !== null
                )}
                className="-striped -highlight"
                filterable={false}
                showPagination={false}
                columns={[
                  {
                    columns: [
                      {
                        Header: "Enquiry By",
                        id: "fullName",
                        accessor: d => d.fullName
                      },

                      {
                        Header: "Message",
                        id: "message",
                        accessor: d => d.enquiryMessage
                      },
                      {
                        Header: "View",
                        id: "action",
                        filterable: false,
                        Cell: props => {
                          return (
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#enquiry"
                              className="mar-left"
                            >
                              <i
                                className="fa fa-eye fa-2x"
                                data-backdrop="static" data-keyboard="true"
                                onClick={() => this.handleClick(props.original)}
                              ></i>
                            </a>
                          );
                        }
                      }

                      // {
                      //   Header: "Inquired On",
                      //   id: "activeForm",
                      //   accessor: d => d.CreatedDate
                      // },

                      // {
                      //   Header: "Status",
                      //   id: "activeTill",
                      //   accessor: d => d.isResolved
                      // },

                      // {
                      //   Header: "Resolved On",
                      //   id: "lastLogin",
                      //   accessor: d => d.ResolvedDate
                      // }
                    ]
                  }
                ]}
                style={{
                  width: "100%",
                  height: "450px"
                }}
              />
            </div>
          </div>
        </div>
        <ViewEnquiry enquiry={this.state} />
      </React.Fragment>
    );
  }
}

export default AdminSocialStreaming;
