import React, { Component } from "react";
import { PopUpPortal } from "../../containers/popups/PopUpPortal";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as moment from "moment";
import { warning, warningNotification, success, successNotification } from "../notification/notifications";
import { LoginService } from "../../services/login.service";
import { EventService } from "../../services/event.service";
import { withRouter } from "react-router-dom";
import { Global_var } from "../../global/global_var";
import { ExcelService } from "../Excel/excel";

export default class EventListLandingPage extends Component {
  constructor(props) {
    super(props);
    var todaydate = new Date();
    this.state = {
      data: [],
      fetchEventDetails: [],
      cityName: "",
      suburbName: "",
      eventLocation: "",
      eventMap: "",
      eventName: "",
      eventAccess: "",
      filtered: [],
      loading: true,
      today: moment(todaydate).format("YYYY-MM-DD"),
      listOfYear1: [],
      listOfMonth: [],
      listOfDay: [],
      fromday: "",
      frommonth: "",
      fromyear: "",
      todate: "",
      tomonth: "",
      toyear: "",
      meetingType: [],
      mailIconDisabled:[],
    };
    this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });

    if (localStorage.getItem("reload") === "true") {
      localStorage.setItem("reload", "false");
    }

    this.setState({ loading: false });
    this.initailizedDateDetails();

    var meetingType = [];

    meetingType = [
      { key: 1, value: "Interview" },
      { key: 2, value: "Meeting" },
      { key: 3, value: "Private Class" },
      { key: 4, value: "Group Discussion" },
      { key: 5, value: "Group Session" },
      { key: 6, value: "Webinar" },
      { key: 7, value: "Public Class" },
    ];

    this.setState({
      meetingType: meetingType,
    });
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: props.getEventList,
      fromday:
        parseInt(props.fromDate.split("/")[0]) < 10 &&
        props.fromDate.split("/")[0].includes("0")
          ? parseInt(`${props.fromDate.split("/")[0].replace("0", "")}`)
          : parseInt(`${props.fromDate.split("/")[0]}`),
      frommonth:
        parseInt(props.fromDate.split("/")[1]) < 10 &&
        props.fromDate.split("/")[1].includes("0")
          ? parseInt(`${props.fromDate.split("/")[1].replace("0", "")}`)
          : parseInt(`${props.fromDate.split("/")[1]}`),
      fromyear: props.fromDate.split("/")[2],
      todate:
        parseInt(props.toDate.split("/")[0]) < 10 &&
        props.toDate.split("/")[0].includes("0")
          ? parseInt(`${props.toDate.split("/")[0].replace("0", "")}`)
          : parseInt(`${props.toDate.split("/")[0]}`),
      tomonth:
        parseInt(props.toDate.split("/")[1]) < 10 &&
        props.toDate.split("/")[1].includes("0")
          ? parseInt(`${props.toDate.split("/")[1].replace("0", "")}`)
          : parseInt(`${props.toDate.split("/")[1]}`),
      toyear: props.toDate.split("/")[2],
    });
  }

  initailizedDateDetails() {
    var listOfYear = [];
    var listOfMonth = [];
    var listOfDay = [];

    var year = 2018;

    for (var i = 1; year + i < 2051; i++) {
      var current = year + i;
      listOfYear.push(current);
    }
    listOfYear.reverse();

    listOfMonth = [
      { key: 1, value: "Jan" },
      { key: 2, value: "Feb" },
      { key: 3, value: "Mar" },
      { key: 4, value: "Apr" },
      { key: 5, value: "May" },
      { key: 6, value: "Jun" },
      { key: 7, value: "Jul" },
      { key: 8, value: "Aug" },
      { key: 9, value: "Sep" },
      { key: 10, value: "Oct" },
      { key: 11, value: "Nov" },
      { key: 12, value: "Dec" },
    ];

    for (var ld = 1; ld <= 31; ld++) {
      listOfDay.push(ld);
    }
    this.setState({ listOfMonth: listOfMonth });
    this.setState({ listOfYear: listOfYear });
    this.setState({ listOfYear1: listOfYear });
    this.setState({ listOfDay: listOfDay });
  }
  handleGetEventDetails = (eventID, eventTitle) => {
    this.setState({
      fetchEventDetails: [],
    });
    let params = "eventID=" + eventID;
    this.setState({loading:true})
    EventService.getEventDetailsById(params, (res) => {
      if (res.status === "success") {
        this.setState({
          eventName: eventTitle,
          fetchEventDetails: res.responseObject.participantsList,
          cityName: res.responseObject.cityName,
          suburbName: res.responseObject.suburbName,
          eventLocation: res.responseObject.eventLocation,
          eventMap: res.responseObject.eventMap,
          eventAccess: res.responseObject.eventAccess,
          loading:false
        });
      } else {
        this.setState({
          eventName: "",
          fetchEventDetails: [],
          cityName: "",
          suburbName: "",
          eventLocation: "",
          eventMap: "",
          eventAccess: "",
          loading:false
        });
      }
    });
  };
  filterCaseInsensitive = ({ id, value }, row) => {
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(value.toLowerCase())
      : "";
  };
  // filterCaseInsensitive = ({ id, value }, row) => {
  //   return row[id] !== undefined
  //     ? id === "mvID" || id === "startTimeAndEndTime" || id === "eventEndDate"
  //       ? // ? parseInt(row[id]) === parseInt(value)
  //         String(row[id]).includes(String(value))
  //       : String(row[id].toLowerCase()).includes(value.toLowerCase())
  //     : true;
  // };
  handleEdit(event) {
    localStorage.setItem("HistoryState",JSON.stringify(event))
    this.props.history.push("/edit-event", event);
  }
  handleSendCandidateMail(values){
    // let Event=document.getElementById(`send-mail-${values.eventID}`)  
    // Event.disabled = true;
    // Event.style.cursor= "not-allowed";
    // Event.style.opacity = 0.5;
    // Event.style.color = "#337ab7";
    let mailIconDisabled = this.state.mailIconDisabled;
    if(mailIconDisabled.includes(values.eventID)){
      return
    }
    mailIconDisabled.push(values.eventID)
    this.setState({mailIconDisabled})
    let params = `eventID=${values.eventID}`;
    this.setState({loading:true})
    EventService.getEventDetailsById(params, (res) => {
      if (res.status === "success") {
        this.setState({loading:false});
        // let email = localStorage.getItem("eMail");
        let participantsList1 = res.responseObject.participantsList;
        // let participantsList = participantsList1.filter((item)=>item.inviteeEmailID !== email).map((item)=>{
        let participantsList = participantsList1.length > 0 && participantsList1.slice(1,participantsList1.length).map((item)=>{
            let participantsListObject = {
            inviteeName: item.inviteeName ? item.inviteeName : "",
            inviteeNature: item.inviteeNature ? item.inviteeNature : "",
            inviteePartyID: item.inviteePartyID ? item.inviteePartyID : null,
            inviteeEmailID: item.inviteeEmailID ? item.inviteeEmailID : "",
          };
          return participantsListObject
        });
        console.log('participantsList',participantsList);
        
        let data = {
          eventID: values.eventID,
          participantsList: participantsList ? participantsList : []
        }
        console.log('data',data);
        this.setState({loading:true})
        EventService.sendEventRemainderMail(data, (res) => {
          if (res.data.status === "success") {
            success("Reminder Mail sent successfully",successNotification);
            this.setState({loading:false});
          } else {
            warning(res.data.reasonCode,warningNotification)
            this.setState({loading:false});
          }
          // this.setState({loading:false});
          // Event.disabled = false;
          // Event.style.cursor= "pointer";
          // Event.style.opacity = 1;            
        });
      } else {
        this.setState({
          loading:false
        });
      }
    });
  }

  showAll() {
    this.props.history.push("/admin-dashboard");
  }

  handleAdd = async (e) => {
    e.preventDefault();
    // await window.gapi.load("client", async () => {
    //   await window.gapi.client.init({
    //     apiKey: Global_var.URL_API_KEY,
    //     discoveryDocs: [Global_var.URL_DISCOVERY_DOC],
    //   });
    //   this.setState({ gapiInited: true }, () =>
    //     console.log("Client Activated")
    //   );
    // });

    this.props.history.push("/add-event");
  };
  exportCandidateExcel = () => {
    this.setState({ loading: true });
    let modifiedData = [];
    let data = this.state.fetchEventDetails;
    if(data.length===0){
        var data1 = {
          Sr_No: "",
          Name:"",
          Nature:"",
          Candidate_Response: "",
        }
        modifiedData.push(data1);
    }
    data.forEach((element,index) => {
      var data1 = {
        Sr_No: index + 1,
        Name:`${element.inviteeName}-${element.inviteeEmailID}`,
        Nature:(element.inviteeNature === "candidate" || element.inviteeNature === "Candidate") ? "Candidate" : 
        (element.inviteeNature === "guest" || element.inviteeNature === "Guest") ? "Guest" : "---",
        Candidate_Response: (element.eventResponse === "yes" || element.eventResponse === "Yes") ? "Yes" 
        : (element.eventResponse === "no" || element.eventResponse === "No") ? "No" 
        : (element.eventResponse === "maybe" || element.eventResponse === "Maybe") ? "Maybe" 
        : "-",
      }
      modifiedData.push(data1);
    });
    ExcelService.ExcelData1(this.state.eventName,modifiedData, 'Participant_Details_Report-');
    this.setState({ loading: false });
  };
  isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime()); // If parsing the date is successful, it's a valid date
  };

  render() {
    console.log("props", this.props);
    console.log("state", this.state);
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          listOfMonth: this.state.listOfMonth,
          listOfDay: this.state.listOfDay,
          listOfMonth1: this.state.listOfMonth,
          listOfDay1: this.state.listOfDay,
          fromday: this.state.fromday,
          frommonth: this.state.frommonth,
          fromyear: this.state.fromyear,
          today: this.state.todate,
          tomonth: this.state.tomonth,
          toyear: this.state.toyear,
        }}
        validationSchema={Yup.object().shape({
          fromday: Yup.string().required("You must specify a Start Date"),
          frommonth: Yup.string().required("You must specify a Start Month"),
          fromyear: Yup.string().required("You must specify a Start Year"),
          today: Yup.string().required("You must specify a End Date"),
          tomonth: Yup.string().required("You must specify a End Month"),
          toyear: Yup.string().required("You must specify a End Year"),
        })}
        onSubmit={(fields, { resetForm }) => {
          let formattedFromDate = "";
          let formattedEndDate = "";

          if (
            (fields["fromyear"] !== undefined && fields["fromyear"] !== "") ||
            (fields["frommonth"] !== undefined && fields["frommonth"] !== "") ||
            (fields["fromday"] !== undefined && fields["fromday"] !== "")
          ) {
            let fromdy =
              fields["fromday"].length === 1
                ? "0" + fields["fromday"]
                : fields["fromday"];

            let frommont =
              fields["frommonth"].length === 1
                ? "0" + fields["frommonth"]
                : fields["frommonth"];

            formattedFromDate =
              fromdy + "/" + frommont + "/" + fields["fromyear"];
          } else {
            formattedFromDate = "";
          }

          if (
            (fields["toyear"] !== undefined && fields["toyear"] !== "") ||
            (fields["tomonth"] !== undefined && fields["tomonth"] !== "") ||
            (fields["today"] !== undefined && fields["today"] !== "")
          ) {
            let tody =
              fields["today"].length === 1
                ? "0" + fields["today"]
                : fields["today"];
            let tomont =
              fields["tomonth"].length === 1
                ? "0" + fields["tomonth"]
                : fields["tomonth"];

            formattedEndDate = tody + "/" + tomont + "/" + fields["toyear"];
          } else {
            formattedEndDate = "";
          }

          let fromDtConverted = moment(formattedFromDate, "DD.MM.YYYY");
          let toDtConverted = moment(formattedEndDate, "DD.MM.YYYY");
          if (!this.isValidDate(fromDtConverted)) {
            warning("Invalid From Date",warningNotification);
            return;
          }
          if (!this.isValidDate(toDtConverted)) {
            warning("Invalid To Date",warningNotification);
            return;
          }
          if (toDtConverted.diff(fromDtConverted, "days") < 0) {
            this.setState({ loading: false });
            warning(
              '“To Date” should be after the “From Date”',
              warningNotification
            );
          } else {
            this.setState({ loading: true });

            // LoginService.TokenUser((res) => {
              let data;
              // localStorage.setItem("jwt-token", res.responseObject);
              this.setState(
                {
                  fromday:
                    parseInt(formattedFromDate.split("/")[0]) < 10 &&
                    formattedFromDate.split("/")[0].includes("0")
                      ? parseInt(
                          `${formattedFromDate.split("/")[0].replace("0", "")}`
                        )
                      : parseInt(`${formattedFromDate.split("/")[0]}`),
                  frommonth:
                    parseInt(formattedFromDate.split("/")[1]) < 10 &&
                    formattedFromDate.split("/")[1].includes("0")
                      ? parseInt(
                          `${formattedFromDate.split("/")[1].replace("0", "")}`
                        )
                      : parseInt(`${formattedFromDate.split("/")[1]}`),
                  fromyear: formattedFromDate.split("/")[2],
                  todate:
                    parseInt(formattedEndDate.split("/")[0]) < 10 &&
                    formattedEndDate.split("/")[0].includes("0")
                      ? parseInt(
                          `${formattedEndDate.split("/")[0].replace("0", "")}`
                        )
                      : parseInt(`${formattedEndDate.split("/")[0]}`),
                  tomonth:
                    parseInt(formattedEndDate.split("/")[1]) < 10 &&
                    formattedEndDate.split("/")[1].includes("0")
                      ? parseInt(
                          `${formattedEndDate.split("/")[1].replace("0", "")}`
                        )
                      : parseInt(`${formattedEndDate.split("/")[1]}`),
                  toyear: formattedEndDate.split("/")[2],
                },
                () => {
                  data = {
                    startDate: `${
                      parseInt(formattedFromDate.split("/")[0]) < 10 &&
                      !formattedFromDate.split("/")[0].includes("0")
                        ? `0${formattedFromDate.split("/")[0]}`
                        : formattedFromDate.split("/")[0]
                    }/${
                      parseInt(formattedFromDate.split("/")[1]) < 10 &&
                      !formattedFromDate.split("/")[1].includes("0")
                        ? `0${formattedFromDate.split("/")[1]}`
                        : formattedFromDate.split("/")[1]
                    }/${this.state.fromyear}`,
                    endDate: `${
                      parseInt(formattedEndDate.split("/")[0]) < 10 &&
                      !formattedEndDate.split("/")[0].includes("0")
                        ? `0${formattedEndDate.split("/")[0]}`
                        : formattedEndDate.split("/")[0]
                    }/${
                      parseInt(formattedEndDate.split("/")[1]) < 10 &&
                      !formattedEndDate.split("/")[1].includes("0")
                        ? `0${formattedEndDate.split("/")[1]}`
                        : formattedEndDate.split("/")[1]
                    }/${this.state.toyear}`,
                  };
                }
              );
              resetForm();

              let params =
                "fromDate=" + data.startDate + "&toDate=" + data.endDate;
              EventService.eventReportList(params, (res) => {
                if (res.status === "success") {
                  this.setState({
                    loading: false,
                    data: res.responseListObject,
                  });
                } else {
                  this.setState({
                    loading: false,
                    data: [],
                  });
                }
              });
            // });
          }
        }}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          handleBlur,
          handleChange,
          handleReset,
          setFieldValue,
          resetForm,
          ...formikProps
        }) => (
          <>
            {this.state.loading && (
              <PopUpPortal
                HidePopup={this.state.loading}
                IsVisible={this.state.loading}
              />
            )}
            <section className="dashboard-tab user-tab pt-50 mb-50">
              <div className="padding-rl-50">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-12 col-md-offset-8 pull-right"></div>
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-header">
                        <h3>
                          <div className="row">
                            <div className="col-md-8 col-sm-6 col-xs-8">
                              <span className="left-icon">
                                <i
                                  className="fa fa-list"
                                  aria-hidden="true"
                                ></i>
                              </span>{" "}
                              Event List
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-4">
                              <span className="right-icon">
                                <a
                                  onClick={(e) => {
                                    this.handleAdd(e);
                                  }}
                                  title="Add Event"
                                  //  href="/add-event"
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </h3>
                      </div>

                      <div className="bg-box">
                        <Form onSubmit={handleSubmit} onReset={handleReset}>
                          <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                              <div className="filter">
                                <div className="form-group">
                                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                    <h5 className="filter-heading">
                                      Search Event
                                    </h5>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>From Date{" "}<span className="validation"> *</span></label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="fromday"
                                            id="fromday"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.fromday =
                                                  e.target.value)
                                              );
                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromday && touched.fromday
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay || []).map(
                                              (d) => (
                                                <option value={d}> {d} </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromday"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="frommonth"
                                            id="frommonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.frommonth =
                                                  e.target.value)
                                              );
                                              if (e.target.value == 2) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.month && touched.month
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="frommonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="fromyear"
                                            id="fromyear"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.fromyear =
                                                  e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.fromyear &&
                                              touched.fromyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear || []).map(
                                              (year) => (
                                                <option value={year}>
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="fromyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                      <label>To Date{" "}<span className="validation"> *</span></label>
                                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl pr">
                                        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 pl pr">
                                          <Field
                                            as="select"
                                            name="today"
                                            id="today"
                                            onChange={(e) => {
                                              var listOfMonth = [];
                                              setFieldValue(
                                                (values.today = e.target.value)
                                              );

                                              if (
                                                [31, "31"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 5, value: "May" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [30, "30"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else if (
                                                [28, "28", 29, "29"].includes(
                                                  e.target.value
                                                )
                                              ) {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              } else {
                                                listOfMonth = [
                                                  { key: 1, value: "Jan" },
                                                  { key: 2, value: "Feb" },
                                                  { key: 3, value: "Mar" },
                                                  { key: 4, value: "Apr" },
                                                  { key: 5, value: "May" },
                                                  { key: 6, value: "Jun" },
                                                  { key: 7, value: "Jul" },
                                                  { key: 8, value: "Aug" },
                                                  { key: 9, value: "Sep" },
                                                  { key: 10, value: "Oct" },
                                                  { key: 11, value: "Nov" },
                                                  { key: 12, value: "Dec" },
                                                ];
                                              }
                                              setFieldValue(
                                                (values.listOfMonth1 = listOfMonth)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.today && touched.today
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">DD</option>
                                            {(values.listOfDay1 || []).map(
                                              (d) => (
                                                <option value={d}> {d} </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="today"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 pl pr">
                                          <Field
                                            as="select"
                                            name="tomonth"
                                            id="tomonth"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.tomonth =
                                                  e.target.value)
                                              );
                                              if (e.target.value == 2) {
                                                var listOfDay = [];
                                                for (var i = 1; i <= 29; i++) {
                                                  listOfDay.push(i);
                                                }
                                              } else if (
                                                [
                                                  1,
                                                  "1",
                                                  3,
                                                  "3",
                                                  5,
                                                  "5",
                                                  7,
                                                  "7",
                                                  8,
                                                  "8",
                                                  10,
                                                  "10",
                                                  12,
                                                  "12",
                                                ].includes(e.target.value)
                                              ) {
                                                listOfDay = [];
                                                for (
                                                  var ldd = 1;
                                                  ldd <= 31;
                                                  ldd++
                                                ) {
                                                  listOfDay.push(ldd);
                                                }
                                              } else {
                                                listOfDay = [];
                                                for (
                                                  var lod = 1;
                                                  lod <= 30;
                                                  lod++
                                                ) {
                                                  listOfDay.push(lod);
                                                }
                                              }
                                              setFieldValue(
                                                (values.listOfDay1 = listOfDay)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.tomonth && touched.tomonth
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">MM</option>
                                            {(values.listOfMonth1 || []).map(
                                              (m) => (
                                                <option value={m.key}>
                                                  {m.value}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="tomonth"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5 pl pr">
                                          <Field
                                            as="select"
                                            name="toyear"
                                            id="toyear"
                                            onChange={(e) => {
                                              setFieldValue(
                                                (values.toyear = e.target.value)
                                              );
                                            }}
                                            className={
                                              "form-control " +
                                              (errors.toyear && touched.toyear
                                                ? "is-invalid"
                                                : "")
                                            }
                                          >
                                            <option value="">YYYY</option>
                                            {(this.state.listOfYear1 || []).map(
                                              (year) => (
                                                <option value={year}>
                                                  {year}
                                                </option>
                                              )
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="toyear"
                                            className="validation "
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 col-sm-6 col-xs-12 mt-40">
                                      <div className="row">
                                        <button
                                          className="btn-5 mr-20 ml-20"
                                          type="submit"
                                        >
                                          Apply
                                        </button>
                                        <button
                                          className="btn-5"
                                          type="reset"
                                          onClick={this.showAll}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        <div className="table-responsive">
                          <ReactTable
                            minRows={2}
                            data={this.state.data || []}
                            filtered={this.state.filtered}
                            onFilteredChange={(filtered) => {
                              this.setState({
                                filtered: filtered,
                              });
                            }}
                            className="-striped -highlight"
                            filterable
                            defaultFilterMethod={this.filterCaseInsensitive}
                            ref={(refReactTable) => {
                              this.refReactTable = refReactTable;
                            }}
                            columns={[
                              {
                                columns: [
                                  {
                                    Header: "Event Name",
                                    id: "eventTitle",
                                    accessor: (d) =>
                                      d.eventTitle ? d.eventTitle : "---",
                                    Cell: (row) => (
                                      <span title={row.value}>{row.value}</span>
                                    ),
                                  },

                                  {
                                    Header: "Description",
                                    id: "eventDescription",
                                    accessor: (d) =>
                                      d.eventDescription
                                        ? d.eventDescription
                                        : "----",
                                    Cell: (row) => (
                                      <div title={row.value}>{row.value}</div>
                                    ),
                                  },
                                  {
                                    Header: "Event Type",
                                    id: "eventType",
                                    accessor: (d) => {
                                      var index = this.state.meetingType.find(
                                        (data) => data.key === d.eventTypeID
                                      );

                                      if (index !== -1) return index.value;

                                      // if (d.eventTypeID === 1)
                                      //   return "Interview";
                                      // else if (d.eventTypeID === 2)
                                      //   return "Meeting";
                                      // else if (d.eventTypeID === 3)
                                      //   return "Private Class";
                                      // else return "InActive";
                                    },
                                    // d.eventTypeID ? d.eventTypeID : "---",
                                    // Cell: (row) => (
                                    //   <div
                                    //     title={
                                    //       row.value === 1
                                    //         ? "Interview"
                                    //         : "Meeting"
                                    //     }
                                    //   >
                                    //     {row.value === 1
                                    //       ? "Interview"
                                    //       : "Meeting"}
                                    //   </div>
                                    // ),
                                    // filterable: false,
                                    // sortable: false,
                                  },
                                  {
                                    Header: "Event Start Date",
                                    id: "eventStartDate",
                                    accessor: (d) =>
                                      d.eventStartDate
                                        ? d.eventStartDate.slice(0, 10)
                                        : "---",
                                  },
                                  {
                                    Header: "Start Time and End Time",
                                    id: "eventStartAndEndTime",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.eventStartTime.slice(0, 5) +
                                      " - " +
                                      d.eventEndTime.slice(0, 5),
                                  },

                                  {
                                    Header: "Event End Date",
                                    id: "eventEndDate",
                                    accessor: (d) =>
                                      d.eventEndDate
                                        ? d.eventEndDate.slice(0, 10)
                                        : "---",
                                    Cell: (row) => (
                                      <div title={row.value}>{row.value}</div>
                                    ),
                                  },
                                  {
                                    Header: "Repeat Event",
                                    id: "isRepeat",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.isRepeat === "Y" ? "Repeat" : "Once",
                                    Cell: (row) => (
                                      <div title={row.value}>{row.value}</div>
                                    ),
                                  },
                                  {
                                    Header: "Access",
                                    id: "eventAccess",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.eventAccess === "online" ? (
                                        <a
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          href="#"
                                          onClick={() =>
                                            this.handleGetEventDetails(
                                              d.eventID,
                                              d.eventTitle
                                            )
                                          }
                                        >
                                          {" "}
                                          Online
                                        </a>
                                      ) : (
                                        <a
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          href="#"
                                          onClick={() =>
                                            this.handleGetEventDetails(
                                              d.eventID,
                                              d.eventTitle
                                            )
                                          }
                                        >
                                          {" "}
                                          Offline
                                        </a>
                                      ),
                                  },
                                  {
                                    Header: "Participants",
                                    id: "participants",
                                    filterable: false,
                                    sortable: false,
                                    accessor: (d) =>
                                      d.eventID ? (
                                        <a
                                          data-toggle="modal"
                                          data-target="#myModal1"
                                          href="#"
                                          onClick={() =>
                                            this.handleGetEventDetails(
                                              d.eventID,
                                              d.eventTitle
                                            )
                                          }
                                        >
                                          {" "}
                                          Participants Details
                                        </a>
                                      ) : (
                                        "---"
                                      ),
                                  },
                                  {
                                    Header: "Status",
                                    id: "isActive",
                                    accessor: (d) => {
                                      if (d.isActive === 1) return "Active";
                                      else if (d.isActive === 2) return "Draft";
                                      else return "InActive";
                                    },
                                    // d.isActive === 1 ? "Active" : "InActive",
                                  },
                                  {
                                    Header: "Created On",
                                    id: "createdDate",
                                    accessor: (d) =>
                                      d.createdDate ? d.createdDate : "---",
                                  },

                                  {
                                    Header: "Actions",
                                    id: "action",
                                    filterable: false,
                                    sortable: false,
                                    Cell: (props) => {
                                      let ss = false
                                        let eventStartDate1 = props.original.eventStartDate.split(" ");
                                        let eventStartDate = eventStartDate1[0];
                                        let fromDtConverted = moment(eventStartDate, "DD.MM.YYYY");
                                        let todayConverted = moment(new Date().toLocaleDateString(), "DD.MM.YYYY");                                        
                                        if (todayConverted.diff(fromDtConverted, 'days') < 0) {
                                          ss=true
                                        }
                                        if (todayConverted.diff(fromDtConverted, 'days') === 0) {
                                          let stageTime1 = props.original.eventStartTime.split(":")                                  
                                          let stageTimeHour = parseInt(stageTime1[0])
                                          let stageTimeMinute = parseInt(stageTime1[1])
                                          let todayDateHour = new Date().getHours()
                                          let todayDateMinute = new Date().getMinutes()   
                                                                                 
                                          if(stageTimeHour > todayDateHour){
                                            ss=true
                                          }
                                          else if(todayDateHour === stageTimeHour){
                                            if(stageTimeMinute > todayDateMinute){
                                              ss=true
                                              } 
                                          }
                                        }
                                      return (
                                        <div>
                                          <a className="mar-left">
                                            <i
                                              title="Edit Event"
                                              className="fa fa-edit fa-2x "
                                              aria-hidden="true"
                                              onClick={() =>
                                                this.handleEdit(props.original)
                                              }
                                            ></i>
                                          </a>
                                          {/* {ss && ( */}
                                          {props.original.isSendRemainderMail === "Y" && (
                                            <a className="mar-left">
                                            <i
                                              title="Send Email"
                                              className="fa fa-paper-plane fa-2x"
                                              id={`send-mail-${props.original.eventID}`}
                                              aria-hidden="true"
                                              style={{ 
                                                cursor: this.state.mailIconDisabled && this.state.mailIconDisabled.some((item)=>item === props.original.eventID) ? 'not-allowed' : 'pointer',
                                                opacity: this.state.mailIconDisabled && this.state.mailIconDisabled.some((item)=>item === props.original.eventID) ? 0.5 : 1 
                                               }}
                                              onClick={() =>
                                                this.handleSendCandidateMail(props.original)
                                              }
                                            ></i>
                                          </a>
                                          )}
                                        </div>
                                      );
                                    },
                                  },
                                ],
                              },
                            ]}
                            defaultPageSize={10}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- Event Access Details Modal --> */}

            <div
              className="modal fade"
              id="myModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      Event Details
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="mb-10">
                      <h2 className="sub-header">
                        Event Name :{" "}
                        <span style={{ color: "black",textTransform:"capitalize" }}>
                          {this.state.eventName}
                        </span>
                      </h2>
                    </div>
                    <table className="table table-bordered table-hover table-striped">
                      <thead>
                        {this.state.eventAccess === "offline" && (
                          <>
                            {" "}
                            <tr>
                              <th
                                className="discount-table-heading"
                                style={{ width: "30%" }}
                              >
                                Address
                              </th>
                              <td>{this.state.eventLocation ? this.state.eventLocation : "---"}</td>
                            </tr>
                            <tr>
                              <th className="discount-table-heading">City</th>
                              <td>{this.state.cityName ? this.state.cityName : "---"}</td>
                            </tr>
                            <tr>
                              <th className="discount-table-heading">SubUrb</th>
                              <td>{this.state.suburbName ? this.state.suburbName : "---"}</td>
                            </tr>
                            <tr>
                              <th className="discount-table-heading">
                                Address On Map
                              </th>
                              <td>
                                {this.state.eventMap ? (
                                  <a href={this.state.eventMap} style={{wordBreak:"break-word"}} target="_blank">
                                    {this.state.eventMap}
                                  </a>
                                ) : (
                                  "---"
                                )}
                              </td>
                            </tr>
                          </>
                        )}
                        {this.state.eventAccess === "online" && (
                          <tr>
                            <th className="discount-table-heading">
                              Link To Connect
                            </th>
                            <td>
                              <a
                                target="_blank"
                                href={this.state.eventLocation}
                                style={{wordBreak:"break-word"}}
                              >
                                {this.state.eventLocation}
                              </a>
                            </td>
                          </tr>
                        )}
                      </thead>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn discount-table-heading"
                      data-dismiss="modal"
                      style={{
                        float: "right",
                        marginTop: "-30px",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Fetch Event ParticipantsDetails Modal --> */}

            <div
              className="modal fade"
              id="myModal1"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      Participant Details
                      <span>
                      <i 
                        onClick={() => this.exportCandidateExcel()}
                          className="fa fa-file-excel-o"
                          aria-hidden="true"
                          title="Download Report"
                          style={{color:"#085dab",marginLeft:"30px"}}
                        ></i>
                        </span>
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="mb-10">
                      <h2 className="sub-header">
                        Event Name :{" "}
                        <span style={{ color: "black",textTransform:"capitalize" }}>
                          {this.state.eventName}
                        </span>
                      </h2>
                    </div>
                    <table className="table table-bordered table-hover table-striped">
                      <thead>
                        <tr className="discount-table-heading">
                          <th>Sr No.</th>
                          <th>Name</th>
                          <th>Nature</th>
                          <th>Candidate Response</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.fetchEventDetails.length > 0 ? 
                        (this.state.fetchEventDetails || []).map(
                          (data, index) => (
                            <tr key={data.eventID}>
                              <td>{index + 1}</td>
                              <td style={{wordBreak:"break-word"}}>
                                {data.inviteeName} - {data.inviteeEmailID}{" "}
                              </td>
                              <td>
                                {data.inviteeNature === "candidate" ||
                                data.inviteeNature === "Candidate"
                                  ? "Candidate"
                                  : data.inviteeNature === "guest" ||
                                    data.inviteeNature === "Guest"
                                  ? "Guest"
                                  : "---"}
                              </td>
                              <td>
                              {data.eventResponse === "yes" || data.eventResponse === "Yes" ? "Yes"
                            : data.eventResponse === "no" || data.eventResponse === "No" ? "No"
                            : data.eventResponse === "maybe" || data.eventResponse === "Maybe" ? "Maybe"
                            : "-"}
                               </td>
                            </tr>
                          )
                        )
                        : <td colSpan="4" style={{textAlign:"center",color:"#085dab",fontWeight:"bolder"}}>No Record Found</td>}
                      </tbody>
                    </table>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn discount-table-heading"
                      data-dismiss="modal"
                      style={{
                        float: "right",
                        marginTop: "-30px",
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      />
    );
  }
}
